//import styles from './ServiceType.module.less';
import {useTranslation} from "react-i18next";
import { useOrderAddContext } from 'contexts/OrderAddContext';
import Status from 'components/OrderAdd/SetStatus/Status';

const SetStatus = ({ serviceType, ...props }) => {

    const { t } = useTranslation();

    const statuses = [
        {
            value: 0,
            label: t('New order'),
        },
        {
            value: 1,
            label: t('Order confirmed'),
        },
        {
            value: 2,
            label: t('Order completed'),
        },
        {
            value: 3,
            label: t('Cancelled without pay'),
        },
        {
            value: 4,
            label: t('Cancelled with payment'),
        },
    ];

    return (
        <div className="row row-choose row-status">
            <div className="row-label">{t('Order status')}</div>
            <div className="row-content">
                {statuses.map((status, i) => (
                    <Status
                        status={status}
                        key={i}
                    />
                ))}
            </div>
        </div>
    );
};

export default SetStatus;