import styles from './SetPeriodicity.module.less';
import { useTranslation } from "react-i18next";
import Periodicity from 'components/OrderAdd/SetPeriodicity/Periodicity';

const SetPeriodicity = () => {
    const { t } = useTranslation();
    const values = [
        {
            value: 0,
            label: t('One-off order'),
            discount: 0,
        },
        {
            value: 1,
            label: t('Fortnightly'),
            discount: 10,
        },
        {
            value: 2,
            label: t('Once a month'),
            discount: 15,
        },
        {
            value: 3,
            label: t('Once a week'),
            discount: 20,
        }
    ];

    return (
        <div className={`row ${styles.rowPeriodicity}`} >
            <div className="row-label">
                <span>{t('Periodicity')}</span>
            </div>
            <div className="row-content">
                {values.map((periodicity, i) => (
                    <Periodicity
                        periodicity={periodicity}
                        key={i}
                    />
                ))}
            </div>
        </div>
    );
};

export default SetPeriodicity;