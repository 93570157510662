import styles from './ErrorText.module.less';
import SvgIcon from "components/SvgIcon";

const SetDate = ({ text, ...props }) => {
    return (
        <div className={styles.errorText + " rounded"}>
            <SvgIcon id="icon-error" className="svg-error" />
            <span>{text}</span>
        </div>
    );
};

export default SetDate;