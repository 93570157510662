import styles from './SetTime.module.less';
import { useTranslation } from "react-i18next";
import {forwardRef} from "react";
import { useOrderAddContext } from 'contexts/OrderAddContext';
import Time from 'components/OrderAdd/SetTime/Time';
import ErrorText from 'components/ErrorText';

const availableTime = [
    {
        hour: "00",
    },
    {
        hour: "01",
    },
    {
        hour: "02",
    },
    {
        hour: "03",
    },
    {
        hour: "04",
    },
    {
        hour: "05",
    },
    {
        hour: "06",
    },
    {
        hour: "07",
    },
    {
        hour: "08",
    },
    {
        hour: "09",
    },
    {
        hour: "10",
    },
    {
        hour: "11",
    },
    {
        hour: "12",
    },
    {
        hour: "13",
    },
    {
        hour: "14",
    },
    {
        hour: "15",
    },
    {
        hour: "16",
    },
    {
        hour: "17",
    },
    {
        hour: "18",
    },
    {
        hour: "19",
    },
    {
        hour: "20",
    },
    {
        hour: "21",
    },
    {
        hour: "22",
    },
    {
        hour: "23",
    },
]

const SetTime = forwardRef((props, ref) => {
    const {
        orderAddState,
        actions: {
            setTimeSpecify,
            setTimeAny,
            setError,
        },
    } = useOrderAddContext();

    const { t } = useTranslation();

    const handleSetSpecify = async() => {
        setTimeSpecify(true);
        setError({
            time: false,
        });
    };

    const handleSetAny = async() => {
        setTimeAny(true);
        setError({
            time: false,
        });
    };

    return (
        <div ref={ref}
             className={`${styles.chooseTime} row row-time ${orderAddState.errors.time ? 'showError' : ''} `}>
            <div className="row-label">
                <span>{t('Choose time')}</span>
                <span className="require">*{t('Required')}</span>
            </div>
            {orderAddState.errors.time && ( <ErrorText text={t('Choose a time to order')} /> )}
            <div className="row-content">
                <div className="minutes">
                    {availableTime.map((time, i) => (
                        <Time
                            time={time}
                            key={i}
                        />
                    ))}
                </div>
                <div className="any-specify">
                    <div onClick={handleSetAny}
                        className={`${orderAddState.timeAny ? 'active': ''} item rounded animate`}>
                        {t('You can start at any time')}
                    </div>
                    <div onClick={handleSetSpecify}
                        className={`${orderAddState.timeSpecify ? 'active': ''} item rounded animate`}>
                        {t('Clarify the day before the cleaning')}
                    </div>
                </div>
            </div>
        </div>
    );
});

export default SetTime;