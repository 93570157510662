import styles from './Time.module.less';
import { useTranslation } from "react-i18next";
import { useOrderAddContext } from 'contexts/OrderAddContext';
import Minute from 'components/OrderAdd/SetTime/Time/Minute';

var availableMinutes = [
    {
        value: '00',
    },
    {
        value: '15',
    },
    {
        value: '30',
    },
    {
        value: '45',
    },
]

const Time = ({ time, ...props }) => {
    const {
        orderAddState,
        actions: {
            setTimeMinute,
            setTimeHour,
        },
    } = useOrderAddContext();

    const handleTimeClick = (event) => {
        setTimeHour(time.hour);
    }

    return (
        <div onClick={handleTimeClick}
            className={`${styles.time} rounded animate ${orderAddState.time.hour === time.hour && !orderAddState.timeAny && !orderAddState.timeSpecify ? styles.timeActive : ''}`}>
            <div className="hours">{time.hour}</div>
            <div className="minutes">{orderAddState.time.minute && orderAddState.time.hour === time.hour ? ':' + orderAddState.time.minute : ':00'}</div>
            <div className="available-minutes">
                {availableMinutes.map((minute, i) => (
                    <Minute
                        hour={time.hour}
                        minute={minute}
                        key={i}
                    />
                ))}
            </div>
        </div>
    );
};

export default Time;