//import styles from './PaymentMethod.module.less';

const Status = ({ label, active, value, setPaymentStatus, className, ...props }) => {
    const handleSetPaymentStatus = () => {
        if(!active){
            setPaymentStatus(value);
        }
    }

    return (
        <div onClick={handleSetPaymentStatus}
             className={`status animate ${active ? `active ${className}` : ''}`}>
            {label}
        </div>
    );
}

export default Status;