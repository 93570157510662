import { actionTypes } from 'constants/actionTypes';

export const setOrderData = (dispatch, payload) =>
    dispatch({ type: actionTypes.SET_ORDER_DATA, payload });

export const setChannel = (dispatch, payload) =>
    dispatch({ type: actionTypes.SET_CHANNEL, payload });

export const setIsLoading = (dispatch, payload) =>
    dispatch({ type: actionTypes.SET_IS_LOADING, payload });

export const setIsFullPartners = (dispatch, payload) =>
    dispatch({ type: actionTypes.SET_IS_FULL_PARTNERS, payload });

export const setShowMainErrorPopup = (dispatch, payload) =>
    dispatch({ type: actionTypes.SET_SHOW_MAIN_ERROR_POPUP, payload });

export const setPartnersByServiceType = (dispatch, payload) =>
    dispatch({ type: actionTypes.SET_PARTNERS_BY_SERVICE_TYPE, payload });

export const setServiceTypes = (dispatch, payload) =>
    dispatch({ type: actionTypes.SET_SERVICE_TYPES, payload });

export const setIsUpdated = (dispatch, payload) =>
    dispatch({ type: actionTypes.SET_IS_UPDATED, payload });

export const setShowLabelsPopup = (dispatch, payload) =>
    dispatch({ type: actionTypes.SET_SHOW_LABELS_POPUP, payload });

export const setAvailableLabels = (dispatch, payload) =>
    dispatch({ type: actionTypes.SET_AVAILABLE_LABELS, payload });

export const setLabelsPartner = (dispatch, payload) =>
    dispatch({ type: actionTypes.SET_LABELS_PARTNER, payload });

export const setLabelsAdmin = (dispatch, payload) =>
    dispatch({ type: actionTypes.SET_LABELS_ADMIN, payload });

export const setMessages = (dispatch, payload) =>
    dispatch({ type: actionTypes.SET_MESSAGES, payload });

export const setLabels = (dispatch, payload) =>
    dispatch({ type: actionTypes.SET_LABELS, payload });

export const setPartnerCashName = (dispatch, payload) =>
    dispatch({ type: actionTypes.SET_PARTNER_CASH_NAME, payload });

export const setPartnersChooseByCustomer = (dispatch, payload) =>
    dispatch({ type: actionTypes.SET_PARTNERS_CHOOSE_BY_CUSTOMER, payload });

export const setCleaningTime = (dispatch, payload) =>
    dispatch({ type: actionTypes.SET_CLEANING_TIME, payload });

export const setNumberOfPartners = (dispatch, payload) =>
    dispatch({ type: actionTypes.SET_NUMBER_OF_PARTNERS, payload });