import styles from './Minute.module.less';
import { useTranslation } from "react-i18next";
import { useOrderAddContext } from 'contexts/OrderAddContext';

const Minute = ({ hour, minute, active, ...props }) => {
    const {
        orderAddState,
        actions: {
            setTimeMinute,
            setTimeHour,
            setError,
        },
    } = useOrderAddContext();

    const HandleSetTime = async() => {
        setTimeHour(hour);
        setTimeMinute(minute.value);

        setError({
            time: false,
        });
    };

    return (
        <div className={styles.minute + " animate rounded-mini"} onClick={HandleSetTime}>
            :{minute.value}
        </div>
    );
};

export default Minute;