import styles from './ChooseChannelItem.module.less';
import {useTranslation} from "react-i18next";
import useAjaxCall from "utils/useAjaxRequest";
import { useOrderContext } from 'contexts/OrderContext';
import { AjaxRoutes } from "constants/ajaxRoutes";

const ChooseChannelItem = ({ channel, ...props }) => {

    const {
        state,
        actions: {
            setChannel,
            setIsLoading,
            setShowMainErrorPopup,
        },
    } = useOrderContext();

    const {t} = useTranslation();
    const { makeAjaxCall } = useAjaxCall();

    const HandleChooseChannel = async() => {
        // show loading
        setIsLoading(true);

        var ajaxResponse = await makeAjaxCall(AjaxRoutes.ORDER_CHANNEL_SET,{
            id: state.id,
            channel: channel.value,
        }, true);

        if (ajaxResponse && ajaxResponse.result) {
            setChannel(channel.value);
        }else{
            setShowMainErrorPopup(true);
        }

        // set loading status
        setIsLoading(false);
    };

    return (
        <div className={styles.item + " animate"} onClick={HandleChooseChannel}>{t(channel.label)}</div>
    );
}

export default ChooseChannelItem;
