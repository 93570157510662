import styles from './Label.module.less';
import { useOrderContext } from 'contexts/OrderContext';
import SvgIcon from 'components/SvgIcon';
import useAjaxCall from "utils/useAjaxRequest";
import { AjaxRoutes } from 'constants/ajaxRoutes';

const Label = ({ label, ...props }) => {
    const { makeAjaxCall } = useAjaxCall();

    const {
        state,
        actions: {
            setLabelsAdmin,
            setLabelsPartner,
            setLabels,
            setIsLoading,
            setMessages,
            setShowMainErrorPopup,
        },
    } = useOrderContext();

    const handleLabelRemove = async() => {
        setIsLoading(true);

        var ajaxResponse = await makeAjaxCall(AjaxRoutes.ORDER_LABEL_REMOVE, {
            id: state.id,
            label: label.hash,
        }, true);

        if (ajaxResponse && ajaxResponse.result) {
            // show message to manager
            setMessages(ajaxResponse.data.messages);

            // update labels data
            setLabelsAdmin(ajaxResponse.data.labelsAdmin);
            setLabelsPartner(ajaxResponse.data.labelsPartner);

            // set labels data
            setLabels(ajaxResponse.data.labels);
        }else{
            // show error and reload order
            setShowMainErrorPopup(true);
        }

        setIsLoading(false);
    }

    return (
        <div className={styles.label + " rounded"} style={{backgroundColor: label.bgColor, color: label.fontColor}}>
            <span>{label.label}</span>
            <div className={styles.removeLabel + " animate"} onClick={handleLabelRemove}>
                <SvgIcon id="icon-remove-white" className="svg-logo"/>
            </div>
        </div>
    );
}

export default Label;
