import styles from './ActivePromocode.module.less';
import {useTranslation} from "react-i18next";
import { useOrderAddContext } from 'contexts/OrderAddContext';
import { useAuthContext } from 'contexts/AuthContext';
import SvgIcon from 'components/SvgIcon';
import ServiceType from 'components/OrderAdd/SetPromocode/ActivePromocode/ServiceType';

const ActivePromocode = ({ serviceType, ...props }) => {
    const {
        orderAddState,
        actions: {
            setPromocode,
        },
    } = useOrderAddContext();

    const {
        authState,
        actions: {
        },
    } = useAuthContext();

    const { t } = useTranslation();

    const handleRemovePromocode = () => {
        setPromocode(null);
    }

    return (
        <div className={styles.activePromocode}>
            <span className="label">{t('Used code')}</span>
            <div className="code-data rounded">
                <span className="code">{orderAddState.promocode.code}</span>
                <span className="value">-{orderAddState.promocode.value}{orderAddState.promocode.type === 'percent' && ('%')}</span>
                <div className="remove" onClick={handleRemovePromocode}>
                    <SvgIcon id="icon-remove-white" />
                </div>
            </div>
            {orderAddState.promocode.serviceTypes.length > 0 && (
                <div className="services-types">
                    <div className="label">{t('The promo code can be used for this type of orders')}</div>
                    <div className="items">
                        {authState.servicesByType.map((serviceType, i) => (
                            orderAddState.promocode.serviceTypes.length > 0 &&
                            orderAddState.promocode.serviceTypes.includes(serviceType.id) ? (
                                <ServiceType
                                    serviceType={serviceType}
                                    key={i}
                                />
                            ) : null
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ActivePromocode;