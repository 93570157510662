import styles from './Messages.module.less';
import stylesDashboard from '../DashboardBlock.module.less';
import { memo, forwardRef } from 'react';
import {useTranslation} from "react-i18next";
import { Link } from "react-router-dom";
import { Routes } from 'constants/routes';
import SvgIcon from 'components/SvgIcon';

const Messages = memo(
    forwardRef(({ ...props }, ref) => {
        const {t} = useTranslation();

        return (
            <div className={`${stylesDashboard.dashboardBlock} ${styles.dashboardMessages} rounded`}>
                <SvgIcon id="icon-warning" className="icon"/>
                <div className="messages-content">
                    <div className="block-title">Есть незавершенные заказы</div>
                    <div className="orders">
                        <Link to={Routes.REVIEWS} className="button rounded grey mini order">
                            <span className="title">114239</span>
                            <span className="date">
                                    <SvgIcon id="icon-date" className="svg"/>
                                    <span className="date-text">15.01.2024</span>
                                </span>
                        </Link>
                        <Link to={Routes.REVIEWS} className="button rounded grey mini order">
                            <span className="title">114239</span>
                            <span className="date">
                                    <SvgIcon id="icon-date" className="svg"/>
                                    <span className="date-text">15.01.2024</span>
                                </span>
                        </Link>
                        <Link to={Routes.REVIEWS} className="button rounded grey mini order">
                            <span className="title">114239</span>
                            <span className="date">
                                    <SvgIcon id="icon-date" className="svg"/>
                                    <span className="date-text">15.01.2024</span>
                                </span>
                        </Link>
                    </div>
                </div>
            </div>
        );
    })
)

export default Messages;