import styles from './LegalEntityInput.module.less';
import { memo, forwardRef } from 'react';
import ErrorText from 'components/ErrorText';

const LegalEntityInput = memo(
    forwardRef(({ label, className, error, errorText, ...props }, ref) => {
        return (
            <div className={`${styles.item} ${styles[className]} ${error ? styles.showError : ''}`}>
                <label htmlFor={props.id} className="label">
                    {label}
                </label>
                {error && ( <ErrorText text={errorText} /> )}
                <div className="input-wrapper">
                    <input ref={ref} className="input rounded" {...props} />
                </div>
            </div>
        );
    })
);

export default LegalEntityInput;