import './Partner.less';
import {useState, useId, useEffect} from 'react';
import { Link } from "react-router-dom";
import { useOrderContext } from 'contexts/OrderContext';
import Input from 'components/Input';
import SvgIcon from 'components/SvgIcon';
import useAjaxCall from "utils/useAjaxRequest";
import { AjaxRoutes } from 'constants/ajaxRoutes';
import { getLink } from 'utils/links';

const Partner = ({ partner, serviceTypeId, index, ...props }) => {

    const {
        state,
        actions: {
            setIsLoading,
            setShowMainErrorPopup,
            setServiceTypes,
            setIsFullPartners,
            setPartnerCashName,
            setCleaningTime,
            setPartnersChooseByCustomer,
        },
    } = useOrderContext();

    const { makeAjaxCall } = useAjaxCall();
    const percentageTextId = useId();
    const [inputPercentage, setInputPercentage] = useState(props?.value ?? partner.percent);
    const [labelAmount, setLabelAmount] = useState(props?.value ?? partner.amountLabel);

    useEffect(()=> {
        setInputPercentage(partner.percent);
        setLabelAmount(partner.amountLabel);
    }, [partner]);

    const handleInputPartnerPercentageChange = (event) => {
        setInputPercentage(event.target.value);
    }

    const handleInputPartnerPercentageBlur = async(event)  => {
        setIsLoading(true);

        var value = event.target.value;
        if(event.target.value === ''){
            value = 0;
            setInputPercentage(0);
        }

        var ajaxResponse = await makeAjaxCall(AjaxRoutes.ORDER_TYPE_PARTNER_PERCENT_SET, {
            id: state.id,
            idServiceType: serviceTypeId,
            idPartner: partner.id,
            percent: value,
            index: index,
        }, true);

        if (ajaxResponse && ajaxResponse.result) {
            // update percentage
            setInputPercentage(ajaxResponse.data.percent);

            // update amount
            setLabelAmount(ajaxResponse.data.amountLabel);
        }else{
            // show error and reload order
            setShowMainErrorPopup(true);
        }

        // set loading status
        setIsLoading(false);
    }

    const handlePartnerRemove = async(event)  => {
        setIsLoading(true);

        var ajaxResponse = await makeAjaxCall(AjaxRoutes.ORDER_PARTNER_REMOVE, {
            id: state.id,
            idPartner: partner.id,
            idServiceType: serviceTypeId,
        }, true);

        if (ajaxResponse && ajaxResponse.result) {
            // TODO update only partners for one type, not all types
            // update service types
            setServiceTypes(ajaxResponse.data.serviceTypes);

            // set full partners
            setIsFullPartners(ajaxResponse.data.isFullPartners);

            // set partner cash name
            setPartnerCashName(ajaxResponse.data.partnerCashName);

            // set cleaning time
            setCleaningTime(ajaxResponse.data.cleaningTime);

        }else{
            // show error and reload order
            setShowMainErrorPopup(true);
        }

        setIsLoading(false);
    }

    const handleSetByCustomer = async(event)  => {
        setIsLoading(true);

        var ajaxResponse = await makeAjaxCall(AjaxRoutes.SET_CHOOSE_BY_CUSTOMER, {
            id: state.id,
            idPartner: partner.id,
            idServiceType: serviceTypeId,
            index: index,
        }, true);

        if (ajaxResponse && ajaxResponse.result) {
            // update service types
            setServiceTypes(ajaxResponse.data.serviceTypes);

            // set partner choose by customer
            setPartnersChooseByCustomer(ajaxResponse.data.partnersChooseByCustomer);
        }else{
            // show error and reload order
            setShowMainErrorPopup(true);
        }

        setIsLoading(false);
    }

    return (
        <div className="partner animate shadow rounded">
            {partner.ownVacuum ? <div className="vacuum"><SvgIcon id="icon-vacuum" className="svg-logo"/></div> : ''}
            <Link to={getLink('partner.details', {id: partner.linkId,})} className="partner-name">
                {partner.name}
            </Link>
            {state.status === 'submitted' && (
                <>
                    {state.partnersChooseByCustomer && state.partnersChooseByCustomer.id !== partner.id && (
                        <div className="setByCustomer animate" onClick={handleSetByCustomer}>
                            <SvgIcon id="icon-click-left" className="svg-logo"/>
                        </div>
                    )}
                    <div className="removePartner animate" onClick={handlePartnerRemove}>
                        <SvgIcon id="icon-remove-white" className="svg-logo"/>
                    </div>
                </>
            )}
            <div className="percentage">
                <Input
                    id={percentageTextId}
                    type="number"
                    value={inputPercentage}
                    min="0"
                    max="100"
                    onBlur={handleInputPartnerPercentageBlur}
                    onChange={handleInputPartnerPercentageChange}
                    className="shadow"
                    disabled={state.status !== 'submitted'}
                />
                <div className="value">{labelAmount}</div>
            </div>
        </div>
    );
}

export default Partner;