import styles from './Minute.module.less';

const Minute = ({ hour, minute, active, setTime, state, ...props }) => {
    const handleSetTime = async() => {
        setTime(hour, minute.value);
    };

    return (
        <div className={styles.minute + " animate rounded-mini"} onClick={handleSetTime}>
            :{minute.value}
        </div>
    );
};

export default Minute;