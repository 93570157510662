import { actionServiceAddTypes } from 'constants/actionServiceAddTypes';

export const setShowMainError = (dispatch, payload) =>
    dispatch({ type: actionServiceAddTypes.SET_SHOW_MAIN_ERROR, payload });

export const setShowServiceTypeError = (dispatch, payload) =>
    dispatch({ type: actionServiceAddTypes.SET_SHOW_SERVICE_TYPE_ERROR, payload });

export const setStatus = (dispatch, payload) =>
    dispatch({ type: actionServiceAddTypes.SET_STATUS, payload });

export const setIsLoading = (dispatch, payload) =>
    dispatch({ type: actionServiceAddTypes.SET_IS_LOADING, payload });

export const setPrice = (dispatch, payload) =>
    dispatch({ type: actionServiceAddTypes.SET_PRICE, payload });

export const setTime = (dispatch, payload) =>
    dispatch({ type: actionServiceAddTypes.SET_TIME, payload });

export const setMax = (dispatch, payload) =>
    dispatch({ type: actionServiceAddTypes.SET_MAX, payload });

export const setUnit = (dispatch, payload) =>
    dispatch({ type: actionServiceAddTypes.SET_UNIT, payload });

export const setPeriodicity = (dispatch, payload) =>
    dispatch({ type: actionServiceAddTypes.SET_PERIODICITY, payload });

export const setFixPrice = (dispatch, payload) =>
    dispatch({ type: actionServiceAddTypes.SET_FIX_PRICE, payload });

export const setFullPriceToPartner = (dispatch, payload) =>
    dispatch({ type: actionServiceAddTypes.SET_FULL_PRICE_TO_PARTNER, payload });

export const setDiscount = (dispatch, payload) =>
    dispatch({ type: actionServiceAddTypes.SET_DISCOUNT, payload });

export const setCustomerOrder = (dispatch, payload) =>
    dispatch({ type: actionServiceAddTypes.SET_CUSTOMER_ORDER, payload });

export const setAdminOrder = (dispatch, payload) =>
    dispatch({ type: actionServiceAddTypes.SET_ADMIN_ORDER, payload });

export const setLabel = (dispatch, payload) =>
    dispatch({ type: actionServiceAddTypes.SET_LABEL, payload });

export const setSlug = (dispatch, payload) =>
    dispatch({ type: actionServiceAddTypes.SET_SLUG, payload });

export const setPageTitle = (dispatch, payload) =>
    dispatch({ type: actionServiceAddTypes.SET_PAGE_TITLE, payload });

export const setMetaDescription = (dispatch, payload) =>
    dispatch({ type: actionServiceAddTypes.SET_META_DESCRIPTION, payload });

export const setPageContent = (dispatch, payload) =>
    dispatch({ type: actionServiceAddTypes.SET_PAGE_CONTENT, payload });

export const setShowErrors = (dispatch, payload) =>
    dispatch({ type: actionServiceAddTypes.SET_SHOW_ERRORS, payload });

export const setServiceType = (dispatch, payload) =>
    dispatch({ type: actionServiceAddTypes.SET_SERVICE_TYPE, payload });

export const setServiceData = (dispatch, payload) =>
    dispatch({ type: actionServiceAddTypes.SET_SERVICE_DATA, payload });