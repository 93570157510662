//import styles from './ServiceType.module.less';
import {useTranslation} from "react-i18next";
import SvgIcon from 'components/SvgIcon';
import {useOrderAddContext} from 'contexts/OrderAddContext';
import {useState} from "react";
import Name from 'components/OrderAdd/CustomerData/AnotherPerson/Name';
import Phone from 'components/OrderAdd/CustomerData/AnotherPerson/Phone';

const AnotherPerson = ({ serviceType, ...props }) => {
    const { t } = useTranslation();

    const {
        orderAddState,
        actions: {  },
    } = useOrderAddContext();

    const [showAnotherPerson, setShowAnotherPerson] = useState(false);

    const HandleSetAnotherPerson = async() => {
        setShowAnotherPerson(!showAnotherPerson);
    };

    return (
        <div className="another-person">
            <div className={`row-check another-person ${showAnotherPerson ? 'active' : ''}`}
                 onClick={HandleSetAnotherPerson}>
                <div className="square animate rounded">
                    <SvgIcon id="icon-check-square-white" className="svg" />
                </div>
                <span>{t('The customer orders a service for someone other than himself ( leaves contact details another person to contact )')}</span>
            </div>
            {showAnotherPerson ?
                <div className="another-person-data">
                    <Name />
                    <Phone />
                </div>
            : ''}
        </div>
    );
};

export default AnotherPerson;