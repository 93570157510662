//import styles from './CustomerData.module.less';
import { useTranslation } from "react-i18next";
import { useOrderAddContext } from 'contexts/OrderAddContext';
import { useAuthContext } from 'contexts/AuthContext';
import {useEffect, useId, useState} from "react";
import IntlTelInput from "intl-tel-input/reactWithUtils";
import "intl-tel-input/styles";

const AdditionalPhone = ({ ...props }) => {
    const {
        orderAddState,
        actions: { setAdditionalPhone },
    } = useOrderAddContext();

    const {
        authState,
        actions: {},
    } = useAuthContext();

    const { t } = useTranslation();
    const inputPhoneId = useId();

    const [isValid, setIsValid] = useState(true);
    const [customerPhone, setCustomerPhone] = useState('');

    useEffect(() => {

    }, [isValid]);

    const HandlePhoneChange = (value) => {

    }

    const HandleValidChange = (value) => {

    }

    return (
        <div className={`item phone ${!isValid ? 'show-error' : ''}`}>
            <label htmlFor={inputPhoneId}>{t('Additional phone number')}</label>
            {authState.smsBaseCountryCustomer ? <IntlTelInput
                onChangeNumber={HandlePhoneChange}
                onChangeValidity={HandleValidChange}
                inputProps={{
                    className: "rounded",
                    id: inputPhoneId,
                }}
                initOptions={{
                    initialCountry: authState.smsBaseCountryCustomer,
                    onlyCountries: authState.smsCountriesCustomer,
                }}
            /> : ''}
        </div>
    );
};

export default AdditionalPhone;