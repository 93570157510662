import styles from './Time.module.less';
import { useTranslation } from "react-i18next";
import Minute from 'components/Order/SetTime/Time/Minute';

var availableMinutes = [
    {
        value: '00',
    },
    {
        value: '15',
    },
    {
        value: '30',
    },
    {
        value: '45',
    },
]

const Time = ({ state, setTime, time, ...props }) => {
    
    const handleTimeClick = (event) => {
        if (event.target.closest('.available-minutes')) {
            return;
        }

        //if(event.target.current)
        setTime(time.hour, '00');
    }
    
    return (
        <div onClick={handleTimeClick}
             className={`${styles.time} rounded animate ${state.time.hour === time.hour ? styles.timeActive : ''}`}>
            <div className="hours">{time.hour}</div>
            <div className="minutes">{state.time.minute && state.time.hour === time.hour ? ':' + state.time.minute : ':00'}</div>
            <div className="available-minutes">
                {availableMinutes.map((minute, i) => (
                    <Minute
                        hour={time.hour}
                        minute={minute}
                        key={i}
                        setTime={setTime}
                        state={state}
                    />
                ))}
            </div>
        </div>
    );
};

export default Time;