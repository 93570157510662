//import styles from './Button.module.less';
import { memo, forwardRef, useState } from 'react';
import SvgIcon from 'components/SvgIcon';
import {useTranslation} from "react-i18next";
import ServiceType from 'components/Dashboard/ComingDays/SetDiscount/ServiceType';

const SetDiscount = ({ type, value, active, setDiscount, ...props }) => {
    const handleButtonClick = () => {
        setDiscount(type, active ? 0 : value);
    }

    return (
        <div onClick={handleButtonClick}
            className={`button rounded animate ${active ? 'active' : ''}`}>
            {type === 'discount' && ('-')}{type === 'coefficient' && ('+')}{value}%
        </div>
    );
}

export default SetDiscount;