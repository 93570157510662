import styles from './Order.module.less';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from 'react';
import { getLink } from 'utils/links';
import useAjaxCall from "utils/useAjaxRequest";
import { useOrderContext } from 'contexts/OrderContext';
import {useAuthContext} from "contexts/AuthContext";
import SvgIcon from 'components/SvgIcon';
import ChooseChannel from 'components/Order/ChooseChannel';
import OrderLabelId from 'components/Order/OrderLabelId';
import OrderMap from 'components/Order/OrderMap';
import Loading from 'components/Loading';
import ChoosePartner from 'components/Order/ChoosePartner';
import ServiceType from 'components/Order/ServiceType';
import Labels from 'components/Order/Labels';
import SetLabelsPopup from 'components/Order/Popup/SetLabelsPopup';
import MainErrorPopup from "components/Order/Popup/MainErrorPopup";
import Messages from "components/Order/Messages";
import ChoosePartnerCash from 'components/Order/ChoosePartnerCash';
import Periodicity from 'components/Order/Periodicity';
import Discount from 'components/Order/Discount';
import PaymentMethods from 'components/Order/PaymentMethods';
import PaymentStatus from 'components/Order/PaymentStatus';
import Actions from 'components/Order/Actions';
import SetDate from 'components/Order/SetDate';
import SetTime from 'components/Order/SetTime';

const Order = ({ ...props }) => {

    const {
        state,
        actions: {
            setOrderData,
            setShowMainErrorPopup,
            setIsLoading,
        },
    } = useOrderContext();

    const { makeAjaxCall } = useAjaxCall();
    const [showContent, setShowContent] = useState(state.status !== 3);

    const {
        authState,
        actions: {
        },
    } = useAuthContext();

    const {t} = useTranslation();

    const handleHeaderCancelClick = (event) => {
        if (event.currentTarget === event.target
            || event.target.closest('.arrowDown')
            || event.target.classList.contains("arrowDown")) {
            setShowContent(!showContent);
        }
    }

    console.log(state.numberOfPartners);

    return (
        <div className={`${styles.order} rounded ${state.isFullPartners ? '' : styles.noFullPartners} ${state.status === 3 ? styles.orderCancelled : ''}`}>

            {state.showMainErrorPopup ? <MainErrorPopup /> : ''}
            {state.showSetLabelsPopup ? <SetLabelsPopup /> : ''}
            {state.isLoading ? <Loading /> : ''}

            {showContent}

            {state.status === 3 && (
                <div className={`orderCancelledHeader ${showContent ? 'showContent' : ''}`} onClick={handleHeaderCancelClick}>
                    <div className="orderCancelledHeaderContent">
                        <div className="cancelLabel">
                            <SvgIcon id="icon-cancel" className="icon"/>
                            <span>{t('Order cancelled')}</span>
                        </div>
                        <OrderLabelId id={state.labelId} className="cancelLabelId" />
                        <div className="customerData">
                            {state.customer.name && (<div className="item item-name">{state.customer.name}</div>)}
                            {state.customer.email && (<div className="item item-email">
                                <SvgIcon id="icon-email-white" className="icon"/>
                                <span>{state.customer.email}</span>
                            </div>)}
                            {state.customer.phone && (<div className="item item-phone">
                                <SvgIcon id="icon-phone-white" className="icon"/>
                                <span>+{state.customer.phone}</span>
                            </div>)}
                        </div>
                    </div>
                    <SvgIcon id="icon-arrow-down" className="arrowDown animate" />
                </div>
            )}

            {showContent && (<div className="orderData">
                <Messages/>
                <div className="order-header">
                    <div className="order-header-left">
                        <OrderLabelId id={state.labelId} />
                        <ChooseChannel/>
                        <Labels/>
                    </div>
                    <div className="order-header-right">
                        <div className="button mini settings shadow rounded animate">
                            <SvgIcon id="icon-setting" className="icon"/>
                            <span>{t('Order management')}</span>
                        </div>
                    </div>
                </div>
                <div className="order-date-time">
                    <SetDate
                        setIsLoading={setIsLoading}
                        state={state}
                        makeAjaxCall={makeAjaxCall}
                        state={state}
                        setShowMainErrorPopup={setShowMainErrorPopup}
                        setOrderData={setOrderData}
                    />
                    <SetTime
                        setIsLoading={setIsLoading}
                        state={state}
                        makeAjaxCall={makeAjaxCall}
                        state={state}
                        setShowMainErrorPopup={setShowMainErrorPopup}
                        setOrderData={setOrderData}
                    />
                    <div className="button middle no-button">
                        <SvgIcon id="icon-time" className="icon"/>
                        <span>{t('Execution time')}: <strong>{state.cleaningTime.hours} {t('h')} {state.cleaningTime.minutes} {t('m')}</strong></span>
                    </div>
                    <div className="button middle no-button">
                        <SvgIcon id="icon-partner" className="icon"/>
                        <span>{t('Partners')}: <strong>{state.numberOfPartners}</strong></span>
                    </div>
                </div>
                <div className="order-details">
                    <div className="order-details-text">
                        <div className="address">
                            <SvgIcon id="icon-address" className="icon"/>
                            <span>{state.address}</span>
                        </div>
                        <div className="description">
                            {state.serviceTypesLabels.map((element, i) => (
                                <span key={i} className="type rounded">{element}</span>
                            ))}
                            {state.description}
                        </div>
                    </div>
                    { state.latitude ? <OrderMap /> : ''}
                </div>
                <div className="order-service-types">
                    { state.partnersChooseByCustomer ? <ChoosePartner /> : '' }
                    <div className="types">
                        {state.serviceTypes.map((serviceType, i) => (
                            <ServiceType
                                serviceType={serviceType}
                                key={i}
                            />
                        ))}
                    </div>
                    {state.partnerCashName ? <ChoosePartnerCash /> : ''}
                </div>
                <div className="order-prices">
                    <div className="price-item">
                        <div className="label">{t('Price')}</div>
                        <div className="value">{state.price} {state.needInvoice && '(' + t('VAT') + ')'}</div>
                    </div>
                    <div className="price-item">
                        <div className="label">{t('Full price')}</div>
                        <div className="value">{state.priceFull}</div>
                    </div>
                    {state.addressPrice && (
                        <div className="price-item">
                            <div className="label">{t('Transport cost')}</div>
                            <div className="value">{state.addressPrice}</div>
                        </div>
                    )}
                    {state.discountType ? <Discount/> : ''}
                    {state.periodicity > 0 ? <Periodicity/> : ''}
                </div>
                <div className="order-payments">
                    <div className="payments-data">
                        <PaymentMethods
                            state={state}
                            setIsLoading={setIsLoading}
                            authState={authState}
                            setOrderData={setOrderData}
                            setShowMainErrorPopup={setShowMainErrorPopup}
                            makeAjaxCall={makeAjaxCall}
                        />
                        <PaymentStatus
                            state={state}
                            setIsLoading={setIsLoading}
                            setShowMainErrorPopup={setShowMainErrorPopup}
                            makeAjaxCall={makeAjaxCall}
                            setOrderData={setOrderData}
                        />
                    </div>
                    <div className="payment-links">
                        <div className="payment-link">
                            <label htmlFor="payment-link-1" className="label">Ссылку на оплату онлайн</label>
                            <input onChange={() => {}}
                                className="rounded" id="payment-link-1" type="text" maxLength="255"
                                   value="https://cleanwhale.pl/order/pay/5a0a55cb70849d1be2de535ec88e3aa1"/>
                        </div>
                        <div className="payment-link">
                            <label htmlFor="payment-link-2" className="label">Ссылка для редактирования заказа</label>
                            <input onChange={() => {}}
                                className="rounded" id="payment-link-2" type="text" maxLength="255"
                                   value="https://cleanwhale.pl/order/pay/5a0a55cb70849d1be2de535ec88e3aa1"/>
                        </div>
                    </div>
                </div>
                <Actions
                    makeAjaxCall={makeAjaxCall}
                    state={state}
                    setOrderData={setOrderData}
                    setShowMainErrorPopup={setShowMainErrorPopup}
                    setIsLoading={setIsLoading}
                    getLink={getLink}
                />
                <div className="order-customer">
                    <div className="top-block">
                        {state.customer.name ? <div className="item item-name">{state.customer.name}</div> : ''}
                        {state.customer.email ? <div className="item item-email">
                            <SvgIcon id="icon-email-white" className="icon"/>
                            <span>{state.customer.email}</span>
                        </div> : ''}
                        {state.customer.phone ? <Link to={"tel:+" + state.customer.phone} className="item item-phone">
                            <SvgIcon id="icon-phone-white" className="icon"/>
                            <span>+{state.customer.phone}</span>
                        </Link> : ''}
                    </div>
                    <div className="customer-data">
                        <div className="item">{t('Number of customer orders')}: <strong>{state.customer.numberOfOrders}</strong></div>
                        <Link to={getLink('customer.details', { id: state.customer.id, })} className="button mini item item-orders rounded">
                            <SvgIcon id="icon-list-white" className="icon"/>
                            <span>{t('View all customer orders')}</span>
                        </Link>
                        <div className="item item-date">Дата последнего заказа у клиента: <strong>02.11.2024</strong></div>
                    </div>
                </div>
            </div>)}
        </div>
    )
}

export default Order;