import styles from './CalendarData.module.less';
import { useTranslation } from "react-i18next";
import {useState} from 'react';
import { useOrderAddContext } from 'contexts/OrderAddContext';
import Date from 'components/OrderAdd/SetDate/Date';
import SvgIcon from 'components/SvgIcon';
import Month from 'components/OrderAdd/SetDate/CalendarData/Month';
import Loading from 'components/Loading';

const CalendarData = ({ ...props }) => {
    const {
        orderAddState,
        actions: {
            setDate,
            setDateToken,
            setError,
        },
    } = useOrderAddContext();

    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    return (
        <>
            {loading ? <Loading /> : ''}
            <div className='calendar-header'>
                <Month
                    navigateClass="prev"
                    label={orderAddState.calendarData.prev.label}
                    year={orderAddState.calendarData.prev.year}
                    month={orderAddState.calendarData.prev.month}
                    setLoading={(value) => setLoading(value)}
                />
                <div className="current-month">
                    <span>{orderAddState.calendarData.current.label} {orderAddState.calendarData.current.year}</span>
                </div>
                <Month
                    navigateClass="next"
                    label={orderAddState.calendarData.next.label}
                    year={orderAddState.calendarData.next.year}
                    month={orderAddState.calendarData.next.month}
                    setLoading={(value) => setLoading(value)}
                />
            </div>
            <div className="calendar-days">
                <div className="date">{t('Mon')}</div>
                <div className="date">{t('Tue')}</div>
                <div className="date">{t('Wed')}</div>
                <div className="date">{t('Thur')}</div>
                <div className="date">{t('Fri')}</div>
                <div className="date">{t('Sat')}</div>
                <div className="date">{t('Sun')}</div>
                {orderAddState.calendarData.days.map((date, i) => (
                    <Date
                        date={date}
                        activeDate={orderAddState.date}
                        setDate={setDate}
                        setDateToken={setDateToken}
                        setError={setError}
                        key={i}
                    />
                ))}
            </div>
            <div className="next-month-name">
                <div className="next-month-name-triangle"></div>
                <div className="next-month-name-text">{t("It's already")} {orderAddState.calendarData.next.label}</div>
            </div>
        </>
    );
};

export default CalendarData;