import styles from './Actions.module.less';
import {useTranslation} from "react-i18next";
import { Link } from 'react-router-dom';
import SvgIcon from "components/SvgIcon";

const Actions = ({ makeAjaxCall, state, setIsLoading, getLink, setOrderData, setShowMainErrorPopup, ...props }) => {
    const {t} = useTranslation();

    const handleSubmitClick = async() => {
        // set show loading
        setIsLoading(true);

        var ajaxResponse = await makeAjaxCall('/order/status', {
            id: state.id,
            status: 1,
        }, true);

        if (ajaxResponse && ajaxResponse.result) {
            console.log(ajaxResponse);

            // change status
            setOrderData(ajaxResponse.data);
        }else{
            // show main error
            setShowMainErrorPopup(true);
        }

        // set loading
        setIsLoading(false);
    }

    const handleCancelClick = async() => {
        // set show loading
        setIsLoading(true);

        var ajaxResponse = await makeAjaxCall('/order/status', {
            id: state.id,
            status: 3,
        }, true);

        if (ajaxResponse && ajaxResponse.result) {
            console.log(ajaxResponse);

            // change status
            setOrderData(ajaxResponse.data);
        }else{
            // show main error
            setShowMainErrorPopup(true);
        }

        // set loading
        setIsLoading(false);
    }

    const handleCompleteClick = async() => {
        // set show loading
        setIsLoading(true);

        var ajaxResponse = await makeAjaxCall('/order/status', {
            id: state.id,
            status: 2,
        }, true);

        if (ajaxResponse && ajaxResponse.result) {
            console.log(ajaxResponse);

            // change status
            setOrderData(ajaxResponse.data);
        }else{
            // show main error
            setShowMainErrorPopup(true);
        }

        // set loading
        setIsLoading(false);
    }

    return (
        <div className={styles.orderActions}>
            {state.status === 'completed' &&
                <div className="button noButton buttonFinished rounded-full">
                    <SvgIcon id="icon-finish" className="icon"/>
                    <span>{t('Order completed')}</span>
                </div>
            }
            {state.status !== 'completed' && (
                state.status === 'submitted' ? (
                    <div className="button noButton buttonSubmitted rounded-full">
                        <SvgIcon id="icon-check" className="icon" />
                        <span>{t('Order submitted')}</span>
                    </div>
                ) : (
                    <div className="button rounded-full green animate" onClick={handleSubmitClick}>
                        <SvgIcon id="icon-check" className="icon" />
                        <span>{t('Confirm order')}</span>
                    </div>
                )
            )}
            <Link to={getLink('order.edit', {id: state.id})} className="button rounded-full yellow rounded animate">
                <SvgIcon id="icon-edit" className="icon"/>
                <span>{t('Edit')}</span>
            </Link>
            {state.status === 'submitted' && <div onClick={handleCompleteClick}
                className="button rounded-full blue animate">
                <SvgIcon id="icon-click" className="icon"/>
                <span>{t('Complete the order')}</span>
            </div>}
            {state.status !== 'completed' && <div onClick={handleCancelClick}
                className="button rounded-full red rounded animate">
                <SvgIcon id="icon-close-white" className="icon"/>
                <span>{t('Cancel order')}</span>
            </div>}
            <Link to={getLink('order.copy', {id: state.id})} className="button rounded-full blue-dark rounded animate">
                <SvgIcon id="icon-copy-white" className="icon"/>
                <span>{t('Copy')}</span>
            </Link>
        </div>
    );
}

export default Actions;