import styles from './DashboardPage.module.less';
import {useEffect, useState} from 'react';
import { useTranslation } from "react-i18next";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import SvgIcon from 'components/SvgIcon';
import Orders from 'components/Dashboard/Orders';
import ComingDays from 'components/Dashboard/ComingDays';
import Messages from 'components/Dashboard/Messages';
import MainError from 'components/MainError';

const DashboardPage = (props) => {
    const {t} = useTranslation();

    const [showMainError, setShowMainError] = useState(false);
    
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>{t('Information about upcoming orders')}</title>
                    <meta name="description" content={t('CleanWhale CRM - best CRM for home services')} />
                </Helmet>
            </HelmetProvider>
            <div className={styles.dashboardMainPage}>
                {showMainError && <MainError /> }
                <ComingDays
                    setShowMainError={setShowMainError}
                />
                <Messages
                    setShowMainError={setShowMainError}
                />
                <Orders
                    setShowMainError={setShowMainError}
                />
            </div>
        </>
    );
}

export default DashboardPage;