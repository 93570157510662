import styles from './MainErrorPopup.module.less';
import { useTranslation } from "react-i18next";
import { useOrderContext } from 'contexts/OrderContext';
import SvgIcon from 'components/SvgIcon';
import useAjaxCall from "utils/useAjaxRequest";
import { AjaxRoutes } from 'constants/ajaxRoutes';

const MainErrorPopup = () => {
    const {
        state,
        actions: { setShowMainErrorPopup, setIsLoading, setOrderData },
    } = useOrderContext();

    const {t} = useTranslation();
    const { makeAjaxCall } = useAjaxCall();

    const HandleClosePopup = async() => {
        setShowMainErrorPopup(false);
    }

    const HandleUpdateData = async() => {
        // hide error
        setShowMainErrorPopup(false);

        // show loader
        setIsLoading(true);

        // make ajax call
        var ajaxResponse = await makeAjaxCall(AjaxRoutes.ORDER_GET_ADMIN,{
            id: state.id,
        }, true);

        if (ajaxResponse && ajaxResponse.result) {
            // update order data
            setOrderData(ajaxResponse.data);
        }else{
            // hide error
            setShowMainErrorPopup(true);
        }

        // show loader
        setIsLoading(false);
    }

    return (
        <div className={styles.orderPopup}>
            <div className="order-popup-close animate" onClick={HandleClosePopup}>
                <SvgIcon id="icon-close" className="icon"/>
            </div>
            <div className="order-popup-title">{t('Data update error')}</div>
            <div className="order-popup-content">
                <div className="technical-error rounded">
                    <SvgIcon id="icon-technical-error" className="icon"/>
                    <div className="technical-error-title">{t('An error occurred while loading data from the server')}</div>
                    <div className="technical-error-info">{t('Important: Changes to the order are not saved. Click the update order data button at the bottom and repeat the action again')}</div>
                    <div className="button middle grey rounded animate" onClick={HandleUpdateData}>
                        <SvgIcon id="icon-refresh" className="icon"/>
                        <span>{t('Update order data')}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MainErrorPopup;