export const Routes = {
    HOME: '/',
    LOGIN: '/login',
    ORDERS: '/orders',
    CUSTOMERS: '/customers',
    CUSTOMERS_DETAILS: '/customers/details',
    DASHBOARD: '/dashboard',
    PARTNERS: '/partners',
    PARTNER_DETAILS: '/partners/details',
    PARTNER_EDIT: '/partners/edit',
    ORDERS_ADD: '/orders/add',
    ORDER_DETAILS: '/orders/details',
    ORDER_EDIT: '/orders/edit',
    ORDER_COPY: '/orders/copy',
    REVIEWS: '/reviews',
    PROMOCODES: '/promocodes',
    PROMOCODES_ADD: '/promocodes/add',
    PROMOCODE_DETAILS: '/promocodes/details',
    PROMOCODE_EDIT: '/promocodes/edit',
    SERVICES: '/services',
    SERVICES_ADD: '/services/add',
    SERVICE_DETAILS: '/services/details',
    SERVICE_EDIT: '/services/edit',
    SERVICE_TYPES: '/services/types',
    SERVICE_TYPES_ADD: '/services/types/add',
    SERVICE_TYPE_DETAILS: '/services/types/details',
    SERVICE_TYPE_EDIT: '/services/types/edit',
    QUESTIONS: '/questions',
    QUESTIONS_ADD: '/questions/add',
    QUESTION_TYPES_ADD: '/questions/types/add',
    QUESTION_TYPES: '/questions/types',
    QUESTION_TYPE_DETAILS: '/questions/types/details',
    QUESTION_TYPE_EDIT: '/questions/types/edit',
    QUESTION_EDIT: '/questions/edit',
    WAREHOUSE: '/warehouse',
    MESSAGES: '/messages',
    CERTIFICATES: '/certificates',
    ANALYTICS: '/analytics',
    TUTORIALS: '/tutorials',
    DOCUMENTS: '/documents',
    SEO: '/seo',
    LOGOUT: '/logout',
    CALENDAR: '/calendar',
};