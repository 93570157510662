//import styles from './DateDiscount.module.less';
import { useTranslation } from "react-i18next";
import { useOrderAddContext } from 'contexts/OrderAddContext';

const ServiceType = ({ serviceType, ...props }) => {
    const {
        orderAddState,
        actions: {
            setDateDiscountType,
            updatePriceTime,
        },
    } = useOrderAddContext();

    const { t } = useTranslation();

    const handleSetDiscountType = () => {
        setDateDiscountType(serviceType.id);
        updatePriceTime(['price', 'time']);
    };

    return (
        <div onClick={handleSetDiscountType}
             className={`
                type animate rounded
                ${orderAddState.dateDiscountType === serviceType.id ? 'active': ''}
                `}>
            <img src={serviceType.icon} alt={serviceType.label ? serviceType.label : ''} />
            <span>{serviceType.label}</span>
        </div>
    );
};

export default ServiceType;