//import styles from './CustomerData.module.less';
import { useOrderAddContext } from 'contexts/OrderAddContext';

const AvailableCustomer = ({ customer, setAvailableCustomers, ...props }) => {

    const {
        orderAddState,
        actions: {
            setCustomerData,
            setError,
        },
    } = useOrderAddContext();

    const HandleCustomerChoose = () => {
        setCustomerData({
            id: customer.id,
            phone: customer.phone,
            name: customer.name,
            email: customer.email,
            cards: customer.cards,
            addresses: customer.addresses,
            legalEntities: customer.legalEntities,
        })
        setAvailableCustomers([]);

        setError({
            customer: false,
        });
    };

    return (
        <div className="available-customer" onClick={HandleCustomerChoose}>
            { customer.name ? <span className="data-item name">{customer.name}</span> : '' }
            { customer.email ? <span className="data-item email">{customer.email}</span> : '' }
            { customer.phone ? <span className="data-item phone">{customer.phone}</span> : '' }
        </div>
    );
};

export default AvailableCustomer;