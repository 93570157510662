//import styles from './ServiceType.module.less';
import { useTranslation } from "react-i18next";
import Language from 'components/OrderAdd/Languages/Language';

const Languages = ({ availableLanguages, ...props }) => {
    const { t } = useTranslation();

    return (
        <div className="row row-choose row-languages">
            <div className="row-label">{t('Order language')}</div>
            <div className="row-content">
                {availableLanguages.map((language, i) => (
                    <Language
                        language={language}
                        key={i}
                    />
                ))}
            </div>
        </div>
    );
};

export default Languages;