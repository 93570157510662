import { actionServiceTypeAddTypes } from 'constants/actionServiceTypeAddTypes';

export const setShowErrors = (dispatch, payload) =>
    dispatch({ type: actionServiceTypeAddTypes.SET_SHOW_ERRORS, payload });

export const setLabel = (dispatch, payload) =>
    dispatch({ type: actionServiceTypeAddTypes.SET_LABEL, payload });

export const setDescription = (dispatch, payload) =>
    dispatch({ type: actionServiceTypeAddTypes.SET_DESCRIPTION, payload });

export const setSlug = (dispatch, payload) =>
    dispatch({ type: actionServiceTypeAddTypes.SET_SLUG, payload });

export const setIsLoading = (dispatch, payload) =>
    dispatch({ type: actionServiceTypeAddTypes.SET_IS_LOADING, payload });

export const setShowMainError = (dispatch, payload) =>
    dispatch({ type: actionServiceTypeAddTypes.SET_SHOW_MAIN_ERROR, payload });

export const setStatus = (dispatch, payload) =>
    dispatch({ type: actionServiceTypeAddTypes.SET_STATUS, payload });

export const setMinPrice = (dispatch, payload) =>
    dispatch({ type: actionServiceTypeAddTypes.SET_MIN_PRICE, payload });

export const setBasePercent = (dispatch, payload) =>
    dispatch({ type: actionServiceTypeAddTypes.SET_BASE_PERCENT, payload });

export const setServiceTypeData = (dispatch, payload) =>
    dispatch({ type: actionServiceTypeAddTypes.SET_SERVICE_TYPE_DATA, payload });