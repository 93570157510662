import styles from './LegalEntity.module.less';

const LegalEntity = ({ setError, legalEntity, setInvoiceData, active, ...props }) => {

    const handleSetLegalEntity = async() => {
        setInvoiceData(legalEntity);
    }

    return (
        <div onClick={handleSetLegalEntity}
             className={`${styles.legalEntity} rounded animate ${active ? styles.legalEntityActive : ''}`}>
            <strong>{legalEntity.taxNumber}</strong>, {legalEntity.companyName}, {legalEntity.address}, {legalEntity.postcode}, {legalEntity.city}
        </div>
    );
}

export default LegalEntity;
