import {useTranslation} from "react-i18next";
import {useEffect, useState} from 'react';
import SvgIcon from 'components/SvgIcon';
import { useOrderContext } from 'contexts/OrderContext';

const Periodicity = () => {
    const {t} = useTranslation();
    const [text, setText] = useState('');

    const {
        state,
        actions: { },
    } = useOrderContext();

    useEffect(() => {
        switch (state.periodicity){
            case 1:
                setText(t('Once a month'));
                break;
            case 2:
                setText(t('Fortnightly'));
                break;
            case 3:
                setText(t('Once a week'));
                break;
        }
    }, []);

    return (
        <div className="price-item periodicity">
            <div className="label">{t('Periodicity')}</div>
            <div className="value">{text}</div>
        </div>
    );
}

export default Periodicity;