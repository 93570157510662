export const actionPromocodeAddTypes = {
    SET_SHOW_MAIN_ERROR: 'SET_SHOW_MAIN_ERROR',
    CLEAR_STATE: 'CLEAR_STATE',
    SET_STATUS: 'SET_STATUS',
    SET_TYPE: 'SET_TYPE',
    SET_SERVICE: 'SET_SERVICE',
    SET_IS_LOADING: 'SET_IS_LOADING',
    SET_CODE: 'SET_CODE',
    SET_VALUE: 'SET_VALUE',
    SET_NUMBER: 'SET_NUMBER',
    SET_INFINITIVE: 'SET_INFINITIVE',
    SET_CALENDAR_DATA_FROM: 'SET_CALENDAR_DATA_FROM',
    SET_CALENDAR_DATA_TO: 'SET_CALENDAR_DATA_TO',
    SET_DATE_FROM: 'SET_DATE_FROM',
    SET_DATE_TO: 'SET_DATE_TO',
    SET_SHOW_ERRORS: 'SET_SHOW_ERRORS',
    SET_PROMOCODE_DATA: 'SET_PROMOCODE_DATA',
};