import styles from './Orders.module.less';
import stylesDashboard from '../DashboardBlock.module.less';
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { OrderProvider } from 'contexts/OrderContext';
import Order from 'components/Order';
import useAjaxCall from "utils/useAjaxRequest";

const Orders = ({ setShowMainError, ...props }) => {
    const { makeAjaxCall } = useAjaxCall();
    const {t} = useTranslation();
    const [orders, setOrders] = useState([]);
    const [page, setPage] = useState(1);

    useEffect(() => {
        const getDataFromAjax = async () => {
            var ajaxResponse = await makeAjaxCall('/orders/list', {
                page: page,
            }, true);

            if (ajaxResponse && ajaxResponse.result) {
                setOrders(ajaxResponse.data.orders);
            }else{
                setShowMainError(true);
            }
        };

        // get data from ajax
        getDataFromAjax();
    }, []);

    return (
        <>
            <div className={`${stylesDashboard.dashboardBlock}`}>
                <div className="dashboard-block-title">{t('New orders')}</div>
                <div className="dashboard-block-content">
                    {orders.map((order, i) => (
                        <OrderProvider orderData={order} key={i}>
                            <Order
                                key={i}
                                orderData={order}
                            />
                        </OrderProvider>
                    ))}
                </div>
            </div>
        </>
    );
}

export default Orders;