//import styles from './CustomerData.module.less';
import { useTranslation } from "react-i18next";
import {useId, useState} from "react";
import useAjaxCall from "utils/useAjaxRequest";
import { useOrderAddContext } from 'contexts/OrderAddContext';
import AvailableCustomer from 'components/OrderAdd/CustomerData/AvailableCustomer';

const Email = ({ ...props }) => {
    const {
        orderAddState,
        actions: {
            setCustomerData,
            setShowMainError,
        },
    } = useOrderAddContext();

    const { t } = useTranslation();
    const { makeAjaxCall } = useAjaxCall();
    const inputEmailId = useId();
    const [availableCustomers, setAvailableCustomers] = useState([]);

    const HandleEmailChange = async(event) => {

        console.log(event.target.value);

        setCustomerData({
            email: event.target.value,
        });

        if(event.target.value.length >= 3){
            var ajaxResponse = await makeAjaxCall('/customers/search', {
                email: event.target.value,
            }, true);

            if (ajaxResponse && ajaxResponse.result) {
                setAvailableCustomers(ajaxResponse.data);
            }else{
                setShowMainError(true);
            }
        }else{
            setAvailableCustomers([]);
        }
    }

    return (
        <div className="item email">
            <label htmlFor={inputEmailId}>{t('Email')}</label>
            <input
                id={inputEmailId}
                className="rounded"
                onChange={HandleEmailChange}
                value={orderAddState.customer.email}
            />
            {availableCustomers.length > 0 ?
                <div className="available-customers shadow rounded">
                    {availableCustomers.map((customer, i) => (
                        <AvailableCustomer
                            customer={customer}
                            setAvailableCustomers={(value) => setAvailableCustomers(value)}
                            key={i}
                        />
                    ))}
                </div>
                : ''}
        </div>
    );
};

export default Email;