//import styles from './CustomerData.module.less';
import { useTranslation } from "react-i18next";
import {useEffect, useId, useState, useRef} from "react";
import IntlTelInput from "intl-tel-input/reactWithUtils";
import "intl-tel-input/styles";
import { useOrderAddContext } from 'contexts/OrderAddContext';
import { useAuthContext } from 'contexts/AuthContext';
import useAjaxCall from "utils/useAjaxRequest";
import AvailableCustomer from 'components/OrderAdd/CustomerData/AvailableCustomer';
import parsePhoneNumber from 'libphonenumber-js';

const Phone = ({ ...props }) => {
    const {
        orderAddState,
        actions: {
            setCustomerData,
            setShowMainError,
            setError,
        },
    } = useOrderAddContext();

    const {
        authState,
        actions: {},
    } = useAuthContext();

    const { makeAjaxCall } = useAjaxCall();

    const { t } = useTranslation();
    const inputPhoneId = useId();
    const [isValid, setIsValid] = useState(false);
    const [availableCustomers, setAvailableCustomers] = useState([]);
    const [customerPhone, setCustomerPhone] = useState(props?.value ?? '');
    const intlTelInputRef = useRef(null);

    useEffect(() => {
        if (intlTelInputRef && intlTelInputRef.current) {
            const instance = intlTelInputRef.current.getInstance();
            if (instance && orderAddState.customer.phone) {
                instance.setNumber(orderAddState.customer.phone);

                setError({
                    customer: false,
                });
            }
        }

        //isValid ? setPhone(customerPhone) : setPhone(null);
    }, [orderAddState.customer.phone]);

    const HandlePhoneChange = async(value) => {
        setCustomerPhone(value);

        const phoneNumber = parsePhoneNumber(orderAddState.customer.phone);
        var canSendAjax = true;
        if(phoneNumber && phoneNumber.isValid()){
            canSendAjax = false;
        }

        if(value.length >= 3 && canSendAjax){
            // remove all but numbers
            value = value.replace(/[^0-9.]/g, '');

            if(value.length >= 3){
                var ajaxResponse = await makeAjaxCall('/customers/search', {
                    phone: value,
                }, true);

                if (ajaxResponse && ajaxResponse.result) {
                    setAvailableCustomers((prev) =>
                        JSON.stringify(prev) !== JSON.stringify(ajaxResponse.data) ? ajaxResponse.data : prev
                    );
                }else{
                    setShowMainError(true);
                }
            }else{
                setAvailableCustomers([]);
            }
        }
    }

    const HandleValidChange = (value) => {
        customerPhone && customerPhone.length > 0 ? setIsValid(value) : setIsValid(true);
    }

    useEffect(() => {
        isValid ? setCustomerData({ phone: customerPhone, }) : setCustomerData({ phone: '', });
    }, [isValid]);

    return (
        <div className={`item phone`}>
            <label htmlFor={inputPhoneId}>{t('Phone number')}</label>
            {authState.smsBaseCountryCustomer ? <IntlTelInput
                ref={intlTelInputRef}
                onChangeNumber={HandlePhoneChange}
                onChangeValidity={HandleValidChange}
                inputProps={{
                    className: "rounded",
                    id: inputPhoneId,
                }}
                initialValue={orderAddState.customer.phone}
                initOptions={{
                    initialCountry: authState.smsBaseCountryCustomer,
                    onlyCountries: authState.smsCountriesCustomer,
                    formatOnDisplay: true,
                    nationalMode: true,
                }}
            /> : ''}
            {availableCustomers.length > 0 ?
                <div className="available-customers shadow rounded">
                    {availableCustomers.map((customer, i) => (
                        <AvailableCustomer
                            customer={customer}
                            setAvailableCustomers={(value) => setAvailableCustomers(value)}
                            key={i}
                        />
                    ))}
                </div>
                : ''}
        </div>
    );
};

export default Phone;