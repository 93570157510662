//import styles from './Percentage.module.less';
import { useTranslation } from "react-i18next";
import { useOrderAddContext } from 'contexts/OrderAddContext';

const Percent = ({ percent, ...props }) => {
    const {
        orderAddState,
        actions: {
            setDateDiscountValue,
            updatePriceTime,
        },
    } = useOrderAddContext();

    const { t } = useTranslation();

    const HandleSetDiscountValue = () => {
        setDateDiscountValue(percent);
        updatePriceTime(['price', 'time']);
    };

    return (
        <div onClick={HandleSetDiscountValue}
             className={`
                item rounded animate
                ${orderAddState.dateDiscountValue === percent ? 'active': ''}
                `}>
            <span>-{percent}%</span>
        </div>
    );
};

export default Percent;