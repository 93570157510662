import styles from './SetPaymentStatus.module.less';
import { useTranslation } from "react-i18next";
import { useOrderAddContext } from 'contexts/OrderAddContext';
import Status from 'components/OrderAdd/SetPaymentStatus/Status';

const SetPaymentStatus = () => {
    const {
        orderAddState,
        actions: {
            setPaymentStatus
        },
    } = useOrderAddContext();

    const { t } = useTranslation();

    const availableStatuses = [
        {
            value: 0,
            label: t('Unpaid'),
        },
        {
            value: 1,
            label: t('Paid'),
        },
    ];

    return (
        <div className={styles.paymentStatus}>
            <div className="row-label">{t('Payment status')}</div>
            <div className="row-content">
                {availableStatuses.map((status, i) => (
                    <Status
                        status={status}
                        key={i}
                        setPaymentStatus={setPaymentStatus}
                        active={orderAddState.paymentStatus === status.value}
                    />
                ))}
            </div>
        </div>
    );
};

export default SetPaymentStatus;