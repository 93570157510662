import { useTranslation } from "react-i18next";
import SvgIcon from 'components/SvgIcon';

const Month = ({ setIsLoading, type, setShowMainErrorPopup, navigateClass, makeAjaxCall, setCalendarData, month, year, label, ...props }) => {
    const { t } = useTranslation();

    const HandleChangeMonth = async() => {
        setIsLoading(true);

        // TODO change request
        var ajaxResponse = await makeAjaxCall('/order/calendar/month', {
            month: month,
            year: year,
            type: type,
        }, true);

        if (ajaxResponse && ajaxResponse.result) {
            setCalendarData(ajaxResponse.data);
        }else{
            setShowMainErrorPopup(false);
        }

        setIsLoading(false);
    };

    return (
        <div onClick={HandleChangeMonth}
             className={"change-month animate rounded-full " + navigateClass}>
            <SvgIcon id="icon-right-arrow" className="icon"/>
            <span>{label} {year}</span>
        </div>
    );
};

export default Month;