import styles from './CustomerData.module.less';
import { useTranslation } from "react-i18next";
import {forwardRef} from "react";
import { useOrderAddContext } from 'contexts/OrderAddContext';
import Name from 'components/OrderAdd/CustomerData/Name';
import Phone from 'components/OrderAdd/CustomerData/Phone';
import Email from 'components/OrderAdd/CustomerData/Email';
import AdditionalPhone from 'components/OrderAdd/CustomerData/AdditionalPhone';
import AnotherPerson from 'components/OrderAdd/CustomerData/AnotherPerson';
import ErrorText from 'components/ErrorText';

const CustomerData = forwardRef((props, ref) => {
    const { t } = useTranslation();

    const {
        orderAddState,
        actions: { },
    } = useOrderAddContext();

    return (
        <div ref={ref}
             className={`row ${styles.rowCustomerData} ${orderAddState.errors.date ? 'showError' : ''}`} >
            <div className="row-label">
                <span>{t('Customer data')}</span>
                <span className="require">*{t('Required')}</span>
            </div>
            {orderAddState.errors.customer && ( <ErrorText text={t('Enter customer data')} /> )}
            <div className="row-content">
                <div className="customer-data">
                    <Name />
                    <Phone />
                    <Email />
                    <AdditionalPhone />
                </div>
                <AnotherPerson />
            </div>
        </div>
    );
});

export default CustomerData;