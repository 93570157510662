import { actionQuestionTypeAddTypes } from 'constants/actionQuestionTypeAddTypes';

export const setShowMainError = (dispatch, payload) =>
    dispatch({ type: actionQuestionTypeAddTypes.SET_SHOW_MAIN_ERROR, payload });

export const setShowLoading = (dispatch, payload) =>
    dispatch({ type: actionQuestionTypeAddTypes.SET_SHOW_LOADING, payload });

export const setStatus = (dispatch, payload) =>
    dispatch({ type: actionQuestionTypeAddTypes.SET_STATUS, payload });

export const setLabel = (dispatch, payload) =>
    dispatch({ type: actionQuestionTypeAddTypes.SET_LABEL, payload });

export const setSlug = (dispatch, payload) =>
    dispatch({ type: actionQuestionTypeAddTypes.SET_SLUG, payload });

export const setShowErrors = (dispatch, payload) =>
    dispatch({ type: actionQuestionTypeAddTypes.SET_SHOW_ERRORS, payload });

export const setType = (dispatch, payload) =>
    dispatch({ type: actionQuestionTypeAddTypes.SET_TYPE, payload });

export const setQuestionTypeData = (dispatch, payload) =>
    dispatch({ type: actionQuestionTypeAddTypes.SET_QUESTION_TYPE_DATA, payload });