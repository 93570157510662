import { actionOrderAddTypes } from 'constants/actionOrderAddTypes';

export const setServices = (dispatch, payload) =>
    dispatch({ type: actionOrderAddTypes.SET_SERVICES, payload });

export const setAvailableServices = (dispatch, payload) =>
    dispatch({ type: actionOrderAddTypes.SET_AVAILABLE_SERVICES, payload });

export const setLanguage = (dispatch, payload) =>
    dispatch({ type: actionOrderAddTypes.SET_LANGUAGE, payload });

export const setStatus = (dispatch, payload) =>
    dispatch({ type: actionOrderAddTypes.SET_STATUS, payload });

export const setPrivateHouse = (dispatch, payload) =>
    dispatch({ type: actionOrderAddTypes.SET_PRIVATE_HOUSE, payload });

export const setTimeMinute = (dispatch, payload) =>
    dispatch({ type: actionOrderAddTypes.SET_TIME_MINUTE, payload });

export const setTimeHour = (dispatch, payload) =>
    dispatch({ type: actionOrderAddTypes.SET_TIME_HOUR, payload });

export const setTimeAny = (dispatch, payload) =>
    dispatch({ type: actionOrderAddTypes.SET_TIME_ANY, payload });

export const setTimeSpecify = (dispatch, payload) =>
    dispatch({ type: actionOrderAddTypes.SET_TIME_SPECIFY, payload });

export const setCalendarData = (dispatch, payload) =>
    dispatch({ type: actionOrderAddTypes.SET_CALENDAR_DATA, payload });

export const setDate = (dispatch, payload) =>
    dispatch({ type: actionOrderAddTypes.SET_DATE, payload });

export const setDateDiscountType = (dispatch, payload) =>
    dispatch({ type: actionOrderAddTypes.SET_DATE_DISCOUNT_TYPE, payload });

export const setDateDiscountValue = (dispatch, payload) =>
    dispatch({ type: actionOrderAddTypes.SET_DATE_DISCOUNT_VALUE, payload });

export const setDateToken = (dispatch, payload) =>
    dispatch({ type: actionOrderAddTypes.SET_DATE_TOKEN, payload });

export const setPromocodes = (dispatch, payload) =>
    dispatch({ type: actionOrderAddTypes.SET_PROMOCODES, payload });

export const setShowMainError = (dispatch, payload) =>
    dispatch({ type: actionOrderAddTypes.SET_SHOW_MAIN_ERROR, payload });

export const setPeriodicity = (dispatch, payload) =>
    dispatch({ type: actionOrderAddTypes.SET_PERIODICITY, payload });

export const setPaymentMethod = (dispatch, payload) =>
    dispatch({ type: actionOrderAddTypes.SET_PAYMENT_METHOD, payload });

export const setPaymentStatus = (dispatch, payload) =>
    dispatch({ type: actionOrderAddTypes.SET_PAYMENT_STATUS, payload });

export const setCustomerData = (dispatch, payload) =>
    dispatch({ type: actionOrderAddTypes.SET_CUSTOMER_DATA, payload });

export const setAvailableCustomers = (dispatch, payload) =>
    dispatch({ type: actionOrderAddTypes.SET_AVAILABLE_CUSTOMERS, payload });

export const setService = (dispatch, payload) =>
    dispatch({ type: actionOrderAddTypes.SET_SERVICE, payload });

export const setCalculatePrice = (dispatch, payload) =>
    dispatch({ type: actionOrderAddTypes.SET_CALCULATE_PRICE, payload });

export const setPromocode = (dispatch, payload) =>
    dispatch({ type: actionOrderAddTypes.SET_PROMOCODE, payload });

export const setPromocodeServiceType = (dispatch, payload) =>
    dispatch({ type: actionOrderAddTypes.SET_PROMOCODE_SERVICE_TYPE, payload });

export const setPrice = (dispatch, payload) =>
    dispatch({ type: actionOrderAddTypes.SET_PRICE, payload });

export const updatePriceTime = (dispatch, payload) =>
    dispatch({ type: actionOrderAddTypes.UPDATE_PRICE_TIME, payload });

export const setIsLoading = (dispatch, payload) =>
    dispatch({ type: actionOrderAddTypes.SET_IS_LOADING, payload });

export const setError = (dispatch, payload) =>
    dispatch({ type: actionOrderAddTypes.SET_ERROR, payload });

export const setAddressData = (dispatch, payload) =>
    dispatch({ type: actionOrderAddTypes.SET_ADDRESS_DATA, payload });

export const clearState = (dispatch, payload) =>
    dispatch({ type: actionOrderAddTypes.CLEAR_STATE, payload });

export const setAdditionalInfo = (dispatch, payload) =>
    dispatch({ type: actionOrderAddTypes.SET_ADDITIONAL_INFO, payload });

export const setUseCustomerDataForInvoice = (dispatch, payload) =>
    dispatch({ type: actionOrderAddTypes.SET_USE_CUSTOMER_DATA_FOR_INVOICE, payload });

export const setNeedVatInvoice = (dispatch, payload) =>
    dispatch({ type: actionOrderAddTypes.SET_NEED_VAT_INVOICE, payload });

export const setActiveCard = (dispatch, payload) =>
    dispatch({ type: actionOrderAddTypes.SET_ACTIVE_CARD, payload });

export const setOrderData = (dispatch, payload) =>
    dispatch({ type: actionOrderAddTypes.SET_ORDER_DATA, payload });

export const setFullPrice = (dispatch, payload) =>
    dispatch({ type: actionOrderAddTypes.SET_FULL_PRICE, payload });

export const setInvoiceData = (dispatch, payload) =>
    dispatch({ type: actionOrderAddTypes.SET_INVOICE_DATA, payload });

export const setCustomerDataForInvoice = (dispatch, payload) =>
    dispatch({ type: actionOrderAddTypes.SET_CUSTOMER_DATA_FOR_INVOICE, payload });
