import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { Routes } from 'constants/routes';
import { useAuthContext } from "contexts/AuthContext";

const PrivateRoute = () => {
    const {
        authState,
        actions: { },
    } = useAuthContext();

    if (!authState.token) return <Navigate to={Routes.LOGIN} />;
    return <Outlet />;
};

export default PrivateRoute;