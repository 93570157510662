import styles from './SetDiscount.module.less';
import { memo, forwardRef, useState } from 'react';
import SvgIcon from 'components/SvgIcon';
import {useTranslation} from "react-i18next";
import ServiceType from 'components/Dashboard/ComingDays/SetDiscount/ServiceType';
import Button from 'components/Dashboard/ComingDays/SetDiscount/Button';

const SetDiscount = memo(
    forwardRef(({ servicesByType, discountDate, availablePercentage, activeServiceTypeDiscounts, setActiveServiceTypeDiscounts, discountsData, setDiscount, setShowSetDiscount, ...props }, ref) => {
        const {t} = useTranslation();

        const handleCloseClick = () => {
            setShowSetDiscount(false);
        }

        return (
            <div className={styles.setDiscount}>
                <div className="setDiscountContent rounded">
                    <div onClick={handleCloseClick} 
                        className="close animate">
                        <SvgIcon id="icon-remove" />
                    </div>
                    <div className="date">{t('Selected date')}: {discountDate}</div>
                    <div className="setServiceType">
                        {servicesByType.map((serviceType, i) => (
                            <ServiceType
                                key={'serviceTypeSetDiscount-' + i}
                                serviceType={serviceType}
                                activeServiceType={activeServiceTypeDiscounts}
                                setActiveServiceType={setActiveServiceTypeDiscounts}
                                hasDiscount={discountsData[serviceType.id] && Object.keys(discountsData[serviceType.id]).length > 0}
                            />
                        ))}
                    </div>
                    <div className="setDiscount">
                        {availablePercentage.map((discountType, i) => (
                            <div className="setDiscountType" key={'setDiscountType-' + i}>
                                {discountType.type !== 'zero' && (
                                    <div className="title">{discountType.type === 'discount' ? t('Choose discount value') : t('Chose coefficient value')}</div>
                                )}
                                <div className="values">
                                    {discountType.items.map((value, i) => (
                                        <Button
                                            key={'button-' + i}
                                            type={discountType.type}
                                            value={value}
                                            setDiscount={setDiscount}
                                            active={discountsData[activeServiceTypeDiscounts]
                                                && discountsData[activeServiceTypeDiscounts].type === discountType.type
                                                && discountsData[activeServiceTypeDiscounts].value === value}
                                        />
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="setDiscountBg"></div>
            </div>
        );
    })
)

export default SetDiscount;