export const actionServiceTypeAddTypes = {
    SET_SHOW_ERRORS: 'SET_SHOW_ERRORS',
    SET_DESCRIPTION: 'SET_DESCRIPTION',
    SET_LABEL: 'SET_LABEL',
    SET_SLUG: 'SET_SLUG',
    SET_ID_LOADING: 'SET_ID_LOADING',
    SET_SHOW_MAIN_ERROR: 'SET_SHOW_MAIN_ERROR',
    SET_STATUS: 'SET_STATUS',
    SET_MIN_PRICE: 'SET_MIN_PRICE',
    SET_BASE_PERCENT: 'SET_BASE_PERCENT',
    SET_SERVICE_TYPE_DATA: 'SET_SERVICE_TYPE_DATA',
};