import styles from './OrderMap.module.less';
import {APIProvider, Map, MapCameraChangedEvent, AdvancedMarker, Pin} from '@vis.gl/react-google-maps';
import { useOrderContext } from 'contexts/OrderContext';

const OrderMap = ({...props }) => {

    const {
        state,
        actions: { setOrderData },
    } = useOrderContext();

    type Poi = { key: string, location: google.maps.LatLngLiteral };
    const location: Poi[] = {
        key: 'orderAddress',
        location: {
            lat: state.latitude,
            lng: state.longitude,
        }};

    return (
        <div className={styles.orderDetailsMap}>
            <div className={styles.orderDetailsMapContainer + ' rounded'}>
                <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API} onLoad={() => console.log('Maps API has loaded.')}>
                    <Map
                        defaultZoom={13}
                        mapId={"order-map-" + state.id}
                        defaultCenter={ { lat: state.latitude, lng: state.longitude } }>
                        <AdvancedMarker
                            key={location.key}
                            position={location.location}>
                            <Pin background={'#2457C6'} glyphColor={'#FFF'} borderColor={'#000'} />
                        </AdvancedMarker>
                    </Map>
                </APIProvider>
            </div>
        </div>
    );
}

export default OrderMap;