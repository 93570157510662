import { createContext, useContext, useMemo, useReducer, useEffect } from 'react';
import { initialState, orderAddReducer } from './reducers/OrderAddReducer';
import useOrderAddActions from 'hooks/useOrderAddActions';

const orderAddContext = createContext({
    state: initialState,
    actions: {},
});

const useOrderAddContext = () => {
    const context = useContext(orderAddContext);

    if (!context) {
        throw new Error(
            'useOrderContext must be used within a OrderProvider.'
        );
    }

    return context;
};

const OrderAddProvider = ({ children }) => {
    const [orderAddState, dispatch] = useReducer(orderAddReducer, initialState);
    const actions = useOrderAddActions(dispatch);
    const value = useMemo(() => ({ orderAddState, actions }), [orderAddState, actions]);
    console.log('🚀 ~ OrderAddProvider ~ value:', value);

    /*useEffect(() => {
        const priceTimeData = calculatePriceTime(orderAddState);

        // Проверяем, отличается ли новая цена от текущей
        if (orderAddState.price !== priceTimeData.price) {
            actions.setPrice(priceTimeData.price);
        }
    }, [orderAddState]);*/

    return (
        <orderAddContext.Provider value={value}>
            {children}
        </orderAddContext.Provider>
    );
};

export { useOrderAddContext, OrderAddProvider };