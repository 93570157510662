import styles from './CustomerAddress.module.less';
import { memo, forwardRef } from 'react';

const CustomerAddress = ({ address, setAddressData, setError, activeAddress, ...props }) => {

    const handleSetCustomerAddress = async() => {
        setAddressData(address);
        
        setError({
            address: false,
        });
    }

    return (
        <div onClick={handleSetCustomerAddress}
            className={`${styles.customerAddress} rounded animate ${activeAddress === address.id && (styles.customerAddressActive)}`}>
            {address.postcode}, {address.city}, {address.street} {address.house}{address.apartment ? '/' + address.apartment : ''}
        </div>
    );
}

export default CustomerAddress;
