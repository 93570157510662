import styles from './Card.module.less';
import SvgIcon from 'components/SvgIcon';

const CustomerPaymentData = ({ card, setActiveCard, activeCard,  ...props }) => {

    const handleSetActiveCard = () => {
        setActiveCard(card.id);
    }

    return (
        <div className={`${styles.card} rounded animate ${card.id === activeCard && ( styles.activeCard )}`}
             onClick={handleSetActiveCard}>
            <div className="cardData">
                <div className="number">**** {card.last4}</div>
                <div className={`brand ${card.brand}`}>
                    <SvgIcon id={"icon-" + card.brand} className="icon"/>
                </div>
            </div>
            <div className="cardExpTime">
                {card.expMonth}/{card.expYear}
            </div>
        </div>
    );
};

export default CustomerPaymentData;