import styles from './PaymentMethods.module.less';
import {useTranslation} from "react-i18next";
import {useEffect, useState} from 'react';
import PaymentMethod from "components/Order/PaymentMethods/PaymentMethod";

const PaymentMethods = ({ authState, setIsLoading, setShowMainErrorPopup, state, makeAjaxCall, setOrderData, ...props }) => {
    const setPaymentMethod = async(method) => {
        // set show loading
        setIsLoading(true);

        var ajaxResponse = await makeAjaxCall('/order/payment/method', {
            id: state.id,
            method: method,
        }, true);

        if (ajaxResponse && ajaxResponse.result) {
            // change payment method
            setOrderData(ajaxResponse.data);
        }else{
            // show main error
            setShowMainErrorPopup(true);
        }

        // set loading
        setIsLoading(false);
    }

    return (
        <div className={styles.paymentMethods}>
            {authState.paymentMethods.map((method, i) => (
                <PaymentMethod
                    method={method}
                    active={state.paymentMethodId === method.id}
                    setPaymentMethod={setPaymentMethod}
                    key={i} />
            ))}
        </div>
    );
}

export default PaymentMethods;