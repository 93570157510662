//import styles from './ServiceType.module.less';
import { useOrderAddContext } from 'contexts/OrderAddContext';

const Language = ({ language, ...props }) => {
    const {
        orderAddState,
        actions: { setLanguage },
    } = useOrderAddContext();

    const handleSetLanguage = async() => {
        setLanguage(language.code);
    };

    return (
        <div className={`item animate rounded ${orderAddState.language === language.code ? 'active' : ''}`}
             onClick={handleSetLanguage}>{language.label}</div>
    );
};

export default Language;