export const actionServiceAddTypes = {
    SET_SHOW_MAIN_ERROR: 'SET_SHOW_MAIN_ERROR',
    SET_SHOW_SERVICE_TYPE_ERROR: 'SET_SHOW_SERVICE_TYPE_ERROR',
    SET_STATUS: 'SET_STATUS',
    SET_IS_LOADING: 'SET_IS_LOADING',
    SET_PRICE: 'SET_PRICE',
    SET_MAX: 'SET_MAX',
    SET_TIME: 'SET_TIME',
    SET_UNIT: 'SET_UNIT',
    SET_PERIODICITY: 'SET_PERIODICITY',
    SET_FIX_PRICE: 'SET_FIX_PRICE',
    SET_FULL_PRICE_TO_PARTNER: 'SET_FULL_PRICE_TO_PARTNER',
    SET_DISCOUNT: 'SET_DISCOUNT',
    SET_CUSTOMER_ORDER: 'SET_CUSTOMER_ORDER',
    SET_ADMIN_ORDER: 'SET_ADMIN_ORDER',
    SET_LABEL: 'SET_LABEL',
    SET_SLUG: 'SET_SLUG',
    SET_PAGE_TITLE: 'SET_PAGE_TITLE',
    SET_META_DESCRIPTION: 'SET_META_DESCRIPTION',
    SET_PAGE_CONTENT: 'SET_PAGE_CONTENT',
    SET_SHOW_ERRORS: 'SET_SHOW_ERRORS',
    SET_SERVICE_TYPE: 'SET_SERVICE_TYPE',
    SET_SERVICE_DATA: 'SET_SERVICE_DATA',
};