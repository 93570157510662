import styles from './SetCloseTime.module.less';
import { memo, forwardRef, useState } from 'react';
import SvgIcon from 'components/SvgIcon';
import {useTranslation} from "react-i18next";
import ServiceType from 'components/Dashboard/ComingDays/SetCloseTime/ServiceType';
import Time from 'components/Dashboard/ComingDays/SetCloseTime/Time';

const SetCloseTime = memo(
    forwardRef(({ servicesByType, availableTime, setShowSetCloseTime, setActiveServiceTypeClosedTime, closedTimeData, setClosedTime, closeTimeDate, activeServiceTypeClosedTime, ...props }, ref) => {
        const {t} = useTranslation();

        const handleCloseClick = () => {
            setShowSetCloseTime(false);
        }

        return (
            <div className={styles.setCloseTime}>
                <div className="setCloseTimeContent rounded">
                    <div onClick={handleCloseClick}
                         className="close animate">
                        <SvgIcon id="icon-remove" />
                    </div>
                    <div className="date">{t('Selected date')}: {closeTimeDate}</div>
                    <div className="setServiceType">
                        {servicesByType.map((serviceType, i) => (
                            <ServiceType
                                key={i}
                                serviceType={serviceType}
                                activeServiceType={activeServiceTypeClosedTime}
                                setActiveServiceType={setActiveServiceTypeClosedTime}
                                hasClosedTime={closedTimeData[serviceType.id] && Object.keys(closedTimeData[serviceType.id]).length > 0}
                            />
                        ))}
                    </div>
                    <div className="closeTime">
                        <div className="title">{t('Select a time that will not be available on the website for ordering')}</div>
                        <div className="values">
                            {availableTime.map((time, i) => (
                                <Time
                                    key={i}
                                    time={time}
                                    active={closedTimeData[activeServiceTypeClosedTime]
                                        && closedTimeData[activeServiceTypeClosedTime].includes(time + ':00')
                                    }
                                    setClosedTime={setClosedTime}
                                />
                            ))}
                        </div>
                    </div>
                </div>
                <div className="setCloseTimeBg"></div>
            </div>
        );
    })
)

export default SetCloseTime;