export const convertMinutesToHoursAndMinutes = (minutes) => {
    minutes = Math.ceil(minutes);

    const hours = Math.floor(minutes / 60);  // Get the number of full hours
    const remainingMinutes = minutes % 60;  // Get the remaining minutes

    return {
        hours: hours,
        minutes: remainingMinutes,
    }
}

