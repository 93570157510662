import { useTranslation } from "react-i18next";
import { useOrderAddContext } from 'contexts/OrderAddContext';
import Date from 'components/OrderAdd/SetDate/Date';
import SvgIcon from 'components/SvgIcon';
import useAjaxCall from "utils/useAjaxRequest";

const Month = ({ setLoading, navigateClass, month, year, label, ...props }) => {
    const {
        orderAddState,
        actions: {
            setDate,
            setCalendarData,
            setMainError,
        },
    } = useOrderAddContext();

    const { t } = useTranslation();
    const { makeAjaxCall } = useAjaxCall();

    const HandleChangeMonth = async() => {
        setLoading(true);

        // TODO change request
        var ajaxResponse = await makeAjaxCall('/order/calendar/month', {
            month: month,
            year: year,
            type: 'cleaning',
        }, true);

        if (ajaxResponse && ajaxResponse.result) {
            setCalendarData(ajaxResponse.data);
        }else{
            setMainError(true);
        }

        setLoading(false);
    };

    return (
        <div onClick={HandleChangeMonth}
             className={"change-month animate rounded-full " + navigateClass}>
            <SvgIcon id="icon-right-arrow" className="icon"/>
            <span>{label} {year}</span>
        </div>
    );
};

export default Month;