import styles from './Actions.module.less';

const Actions = ({ handleButtonClick, label, icon, ...props }) => {
    return (
        <div className={`row ${styles.actions}`}>
            <div onClick={handleButtonClick}
                 className="button blue middle rounded animate">
                {icon}
                <span>{label}</span>
            </div>
        </div>
    );
};

export default Actions;