import { actionAuthTypes} from 'constants/actionAuthTypes';

export const setAuthData = (dispatch, payload) =>
    dispatch({ type: actionAuthTypes.SET_AUTH_DATA, payload });

export const clearAuthData = (dispatch, payload) =>
    dispatch({ type: actionAuthTypes.CLEAR_AUTH_DATA, payload });

export const setServicesByType = (dispatch, payload) =>
    dispatch({ type: actionAuthTypes.SET_SERVICES_BY_TYPE, payload });

export const setLocationData = (dispatch, payload) =>
    dispatch({ type: actionAuthTypes.SET_LOCATION_DATA, payload });