import { memo, forwardRef } from 'react';
import styles from './AddressDetails.module.less';

const AddressDetails = memo(
    forwardRef(({ label, ...props }, ref) => {
        return (
            <div className={styles.addressDetails}>
                <label htmlFor={props.id} className="label">
                    {label}
                </label>
                <div className="input-wrapper">
                    <input ref={ref} className="input rounded" {...props} />
                </div>
            </div>
        );
    })
);

export default AddressDetails;
