import styles from '../OrderAddPage/OrdersAddPage.module.less';
import { useEffect, useState, useId, useRef } from 'react';
import {useParams, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import moment from 'moment';
import useAjaxCall from "utils/useAjaxRequest";
import { getLink } from 'utils/links';
import { useOrderAddContext } from 'contexts/OrderAddContext';
import { useAuthContext } from 'contexts/AuthContext';
import Input from 'components/Input';
import SvgIcon from 'components/SvgIcon';
import ServiceType from 'components/OrderAdd/ServiceType';
import Languages from 'components/OrderAdd/Languages';
import SetStatus from 'components/OrderAdd/SetStatus';
import PrivateHouse from 'components/OrderAdd/PrivateHouse';
import SetDate from 'components/OrderAdd/SetDate';
import SetTime from 'components/OrderAdd/SetTime';
import DateDiscount from 'components/OrderAdd/DateDiscount';
import SetPromocode from 'components/OrderAdd/SetPromocode';
import SetPeriodicity from 'components/OrderAdd/SetPeriodicity';
import SetPaymentMethod from 'components/OrderAdd/SetPaymentMethod';
import SetPaymentStatus from 'components/OrderAdd/SetPaymentStatus';
import CustomerData from 'components/OrderAdd/CustomerData';
import AddressData from 'components/OrderAdd/AddressData';
import BillingData from 'components/OrderAdd/BillingData';
import AdditionalInfo from 'components/OrderAdd/AdditionalInfo';
import Actions from 'components/AddEditPage/Actions';
import PriceBasedOnServices from "components/OrderAdd/PriceBasedOnServices";
import Loading from "components/Loading";
import ErrorText from 'components/ErrorText';
import CustomerPaymentData from 'components/OrderAdd/CustomerPaymentData';
import MainError from 'components/MainError';

const OrderEditPage = (props) => {
    const {
        orderAddState,
        actions: {
            setOrderData,
            clearState,
            setCalendarData,
            setShowMainError,
            setPromocodes,
            setDateDiscountType,
            setPaymentMethod,
            setIsLoading,
            setErrorServices,
            setErrorDate,
            setErrorTime,
            setErrorCustomer,
            setErrorAddress,
            setCalculatePrice,
            setFullPrice,
            setPrice,
            updatePriceTime,
        },
    } = useOrderAddContext();

    const {
        authState,
        actions: {
        },
    } = useAuthContext();

    const {t} = useTranslation();
    const { makeAjaxCall } = useAjaxCall();
    const offsetDelta = window.innerHeight * 0.1;
    const navigate = useNavigate();

    const amountsRef = useRef(null);
    const dateRef = useRef(null);
    const timeRef = useRef(null);
    const servicesRef = useRef(null);
    const customerRef = useRef(null);
    const addressRef = useRef(null);
    const headerRef = useRef(null);
    const companyNameRef = useRef(null);
    const taxNumberRef = useRef(null);
    const companyAddressRef = useRef(null);
    const cityRef = useRef(null);
    const postCodeRef = useRef(null);
    const customerDataRef = useRef(null);

    const [month, setMonth] = useState(moment().format('MM'));
    const [year, setYear] = useState(moment().format('YYYY'));
    const idPrice = useId();
    const idFullPrice = useId();
    const idTime = useId();

    const [amountsOffsetTop, setAmountsOffsetTop] = useState(0);
    const [offset, setOffset] = useState(0);

    const { id } = useParams();

    const getDataFromAjax = async () => {
        var ajaxResponse = await makeAjaxCall('/order/edit/data', {
            id: id,
            month: month,
            year: year,
        }, true);
        
        if (ajaxResponse && ajaxResponse.result) {
            setOrderData(ajaxResponse.data.orderData);

            // load data
            setCalendarData(ajaxResponse.data.calendarData);
            setPromocodes(ajaxResponse.data.promocodes);
            
            // set discount type
            if(!ajaxResponse.data.orderData.dateDiscountType){
                setDateDiscountType(authState.servicesByType[0].id);
            }

            // set payment method
            setPaymentMethod(authState.paymentMethods[0].id);
        }else{
            setShowMainError(true);
        }

        setIsLoading(false);
    };

    useEffect(() => {
        window.scrollTo(0, 0);

        setIsLoading(true);

        // get data from ajax
        getDataFromAjax();

        // get top offset
        const rect = amountsRef.current.getBoundingClientRect();
        setAmountsOffsetTop(rect.top + window.pageYOffset);

        const onScroll = () => setOffset(window.scrollY);
        // clean up code
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);

    }, []);

    var classes = [];
    if(offset > amountsOffsetTop){
        classes = 'fixed';
    }

    const handlePriceChange = (event) => {
        let newValue = event.target.value;

        if (/^\d*\.?\d{0,2}$/.test(newValue)) {
            setPrice(newValue);
            setFullPrice(newValue);
        }
    };

    const handleFullPriceChange = (event) => {
        let newValue = event.target.value;

        if (/^\d*\.?\d{0,2}$/.test(newValue)) {
            setPrice(newValue);
            setFullPrice(newValue);
        }
    };

    const handleOrderEdit = async() => {
        var validate = true;

        // TODO validate min price
        //dateRef.current.scrollIntoView({ behavior: "smooth", block: "start" });

        // validate services
        if(orderAddState.services.length === 0){
            setErrorServices(true);

            const servicesRefOffset = servicesRef.current.getBoundingClientRect();
            window.scrollBy({
                top: servicesRefOffset.top - offsetDelta,
                behavior: "smooth",
            });

            validate = false;
        }

        // validate data
        if(!orderAddState.date){
            setErrorDate(true);

            if(validate){
                const dateRefOffset = dateRef.current.getBoundingClientRect();
                window.scrollBy({
                    top: dateRefOffset.top - offsetDelta,
                    behavior: "smooth",
                });

                validate = false;
            }
        }

        if(!orderAddState.time.hour
            && !orderAddState.time.any
            && !orderAddState.time.specify){

            setErrorTime(true);

            if(validate){
                const timeRefOffset = timeRef.current.getBoundingClientRect();
                window.scrollBy({
                    top: timeRefOffset.top - offsetDelta,
                    behavior: "smooth",
                });

                validate = false;
            }
        }

        if(orderAddState.customer.phone.length === 0){
            setErrorCustomer(true);

            if(validate){
                const customerRefOffset = customerRef.current.getBoundingClientRect();
                window.scrollBy({
                    top: customerRefOffset.top - offsetDelta,
                    behavior: "smooth",
                });

                validate = false;
            }
        }

        if(!orderAddState.address.id && !orderAddState.address.latitude){
            setErrorAddress(true);

            if(validate){
                const addressRefOffset = addressRef.current.getBoundingClientRect();
                window.scrollBy({
                    top: addressRefOffset.top - offsetDelta,
                    behavior: "smooth",
                });

                validate = false;
            }
        }

        if(validate){
            setIsLoading(true);

            // add new order
            var ajaxResponse = await makeAjaxCall('/order/edit/admin', {
                id: orderAddState.id,
                date: orderAddState.date,
                dateToken: orderAddState.dateToken,
                time: `${orderAddState.time.hour}:${orderAddState.time.minute || '00'}:00`,
                customer: orderAddState.customer,
                services: orderAddState.services,
                address: orderAddState.address,
                price: orderAddState.price.getAmount(),
                fullPrice: orderAddState.fullPrice.getAmount(),
                payment: {
                    method: orderAddState.paymentMethod,
                },
                type: orderAddState.dateDiscountType,
                promocode: orderAddState.promocode ? orderAddState.promocode.id : null,
                calculatePrice: orderAddState.calculatePrice,
                orderLanguage: orderAddState.language,
                status: orderAddState.status,
                privateHouse: orderAddState.privateHouse ? orderAddState.privateHouse: null,
            }, true);

            if (ajaxResponse && ajaxResponse.result) {
                navigate(getLink('order.details', {
                    id: ajaxResponse.data.id,
                }));
            }else{
                const headerRefOffset = headerRef.current.getBoundingClientRect();
                window.scrollBy({
                    top: headerRefOffset.top - offsetDelta,
                    behavior: "smooth",
                });

                setShowMainError(true);
            }

            // remove loading
            setIsLoading(false);
        }
    };

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>{`${t('Edit order')} ${orderAddState.labelId ? orderAddState.labelId : ''}`}</title>
                    <meta name="description" content={t('CleanWhale CRM - best CRM for home services')} />
                </Helmet>
            </HelmetProvider>
            <div className={`${styles.orderAddPage} ${styles.orderEditPage} `} ref={headerRef}>
                {orderAddState.showMainError && <MainError />}
                <div className="header">
                    <h1>{`${t('Edit order')} ${orderAddState.labelId ? orderAddState.labelId : ''}`}</h1>
                    <PriceBasedOnServices
                        value={orderAddState.calculatePrice}
                        setCalculatePrice={setCalculatePrice}
                        updatePriceTime={updatePriceTime}
                    />
                </div>
                <div className="order-amounts-container" ref={amountsRef}>
                    <div className={`order-amounts rounded ${classes}`}>
                        <div className="amount">
                            <Input
                                id={idPrice}
                                type="text"
                                label={t('Price')}
                                value={orderAddState.price.toFormat('0.00')}
                                onChange={handlePriceChange}
                                disabled={orderAddState.calculatePrice}
                            />
                        </div>
                        <div className="amount full-price">
                            <Input
                                id={idFullPrice}
                                type="text"
                                label={t('Full price')}
                                value={orderAddState.fullPrice.toFormat('0.00')}
                                onChange={handleFullPriceChange}
                                disabled={orderAddState.calculatePrice}
                            />
                        </div>
                        <div className="amount time">
                            <div className="label">{t('Execution time')}</div>
                            <div className="values">
                                <div className="hours">
                                    <span>{orderAddState.cleaningTime.hours}</span>
                                    <span className="unit">{t('h')}</span>
                                </div>
                                <div className="minutes">
                                    <span>{orderAddState.cleaningTime.minutes}</span>
                                    <span className="unit">{t('m')}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="editBg"></div>
                <div className="form">
                    {orderAddState.isLoading && (<Loading fixed="true"/>)}
                    <Languages
                        availableLanguages={authState.availableLanguages}
                    />
                    <SetStatus/>
                    <PrivateHouse/>
                    <div className={`row row-services ${orderAddState.errors.services ? 'showError' : ''}`}
                         ref={servicesRef}>
                        <div className="row-label">
                            <span>{t('Choose the services to order')}</span>
                            <span className="require">*{t('Required')}</span>
                        </div>
                        {orderAddState.errors.services && (<ErrorText text={t('Select services to order')}/>)}
                        <div className="row-content">
                            <div className="service-types-items">
                                {authState.servicesByType.map((serviceType, i) => (
                                    <ServiceType
                                        serviceType={serviceType}
                                        key={i}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="row row-date-time">
                        <SetDate ref={dateRef}/>
                        <SetTime ref={timeRef}/>
                    </div>
                    <SetPromocode/>
                    <DateDiscount/>
                    <SetPeriodicity/>
                    <div className="row row-payment-data">
                        <SetPaymentMethod/>
                        <SetPaymentStatus/>
                    </div>
                    <CustomerData ref={customerRef}/>
                    {orderAddState.customer.cards.length ?
                        <CustomerPaymentData
                            cards={orderAddState.customer.cards}
                        /> : null
                    }
                    <AddressData ref={addressRef}/>
                    <BillingData
                        authState={authState}
                        companyNameRef={companyNameRef}
                        taxNumberRef={taxNumberRef}
                        companyAddressRef={companyAddressRef}
                        cityRef={cityRef}
                        postCodeRef={postCodeRef}
                        customerDataRef={customerDataRef}
                    />
                    <AdditionalInfo/>
                    <Actions
                        handleButtonClick={handleOrderEdit}
                        icon={<SvgIcon id="icon-save-white"/>}
                        label={t('Save')}
                    />
                </div>
            </div>
        </>
    );
}

export default OrderEditPage;