import styles from './Periodicity.module.less';
import { useTranslation } from "react-i18next";
import { useOrderAddContext } from 'contexts/OrderAddContext';

const Periodicity = ({ periodicity, ...props }) => {
    const {
        orderAddState,
        actions: { setPeriodicity },
    } = useOrderAddContext();

    const HandleSetPeriodicity = () => {
        setPeriodicity(periodicity.value);
    };

    return (
        <div onClick={HandleSetPeriodicity}
            className={`
                ${styles.periodicityItem}
               rounded animate
                ${orderAddState.periodicity === periodicity.value ? styles.periodicityItemActive : ''}
            `}>
            <span>{periodicity.label}</span>
            <span className="value rounded">-{periodicity.discount}%</span>
        </div>
    );
};

export default Periodicity;