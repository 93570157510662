import styles from './ServiceType.module.less';
import { useTranslation } from "react-i18next";
import { useState, useEffect, useId } from 'react';
import { useOrderContext } from 'contexts/OrderContext';
import Input from 'components/Input';
import SvgIcon from 'components/SvgIcon';
import Partner from 'components/Order/ServiceType/Partner';
import ChoosePartnerPopup from 'components/Order/ServiceType/ChoosePartnerPopup';
import useAjaxCall from "utils/useAjaxRequest";
import { AjaxRoutes } from 'constants/ajaxRoutes';

const ServiceType = ({ serviceType, ...props }) => {

    const {
        state,
        actions: {
            setIsLoading,
            setIsFullPartners,
            setShowMainErrorPopup,
            setServiceTypes,
            setPartnerCashName,
            setCleaningTime,
            setNumberOfPartners,
        },
    } = useOrderContext();

    const {t} = useTranslation();
    const { makeAjaxCall } = useAjaxCall();
    const numberPartnersTextId = useId();

    const [showChoosePartnersPopup, setShowChoosePartnersPopup] = useState(false);
    const [partnersAvailable, setPartnersAvailable] = useState([]);
    const [numberPartners, setNumberPartners] = useState(serviceType.numberPartners);

    const handleSetNumberPartners = (event) => {
        setNumberPartners(event.target.value);
    }

    const typeInputNumberPartners = async(event) => {
        // show loading
        setIsLoading(true);

        var value = event.target.value;

        if(event.target.value === ''){
            value = 1;
            setNumberPartners(1);
        }

        var ajaxResponse = await makeAjaxCall(AjaxRoutes.ORDER_TYPE_PARTNERS_NUMBER_SET, {
            id: state.id,
            idServiceType: serviceType.id,
            number: value,
        }, true);

        if (ajaxResponse && ajaxResponse.result) {
            // TODO update only partners for one type, not all types
            // update service types
            setServiceTypes(ajaxResponse.data.serviceTypes);

            // set full partners
            setIsFullPartners(ajaxResponse.data.isFullPartners);

            // set partner cash name
            setPartnerCashName(ajaxResponse.data.partnerCashName);

            // set cleaning time
            setCleaningTime(ajaxResponse.data.cleaningTime);

            // set number of partners
            setNumberOfPartners(ajaxResponse.data.numberOfPartners);
        }else{
            // show error and reload order
            setShowMainErrorPopup(true);
        }

        // set loading status
        setIsLoading(false);
    };

    const showAvailablePartners = async() => {
        // show loading
        setIsLoading(true);

        var ajaxResponse = await makeAjaxCall(AjaxRoutes.ORDER_PARTNERS_AVAILABLE,{
            id: state.id,
            idServiceType: serviceType.id,
        }, true);

        if (ajaxResponse && ajaxResponse.result) {

            // set choose partner
            setPartnersAvailable(ajaxResponse.data);

            // show popup
            setShowChoosePartnersPopup(true);
        }else{
            // show error and reload order
            setShowMainErrorPopup(true);
        }

        // set loading status
        setIsLoading(false);
    }

    return (
        <div className={styles.orderServiceType + " rounded"}>

            {showChoosePartnersPopup ? <ChoosePartnerPopup
                setShowChoosePartnersPopup={(value) => setShowChoosePartnersPopup(value)}
                choosePartners={partnersAvailable}
                serviceTypeId={serviceType.id}
            />: ''}

            <div className="order-service-type-header">
                <div className="order-service-type-label rounded">{serviceType.label}</div>
                <div className="number-of-partners">
                    <div className="number-of-partners-label">{t('Partners are needed on this order')}</div>
                    <Input
                        id={numberPartnersTextId}
                        type="number"
                        inputClass="shadow rounded"
                        value={numberPartners}
                        min="1"
                        max="1000"
                        onBlur={typeInputNumberPartners}
                        onChange={handleSetNumberPartners}
                        disabled={state.status !== 'submitted'}
                    />
                </div>
            </div>
            <div className="order-service-type-content">
                <div className="partners-amount-percentage">
                    <div className="partners-percentage">
                        {t('Percentage of order for partners')}: <strong>{serviceType.partnerPercentage}%</strong>
                    </div>
                    <div className="partners-amount">{t('Amount')}: <strong>{serviceType.partnerRealAmount}</strong></div>
                </div>
                <div className="partners">
                    {serviceType.partners.map((partner, i) => (
                        <Partner
                            partner={partner}
                            serviceTypeId={serviceType.id}
                            index={i}
                            key={i}
                        />
                    ))}
                    {state.status === 'submitted' && (
                        <div onClick={showAvailablePartners} className="button mini animate blue add-partner shadow rounded">
                            <SvgIcon id="icon-add-white" className="svg-logo"/>
                            <span>{t('Add partner')}</span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ServiceType;