import styles from './SetTime.module.less';
import {useState, useEffect} from 'react';
import {useTranslation} from "react-i18next";
import SvgIcon from 'components/SvgIcon';
import Time from 'components/Order/SetTime/Time';

const SetTime = ({ state, setIsLoading, makeAjaxCall, setShowMainErrorPopup, setOrderData, ...props }) => {
    const {t} = useTranslation();
    const availableTime = [
        {
            hour: "00",
        },
        {
            hour: "01",
        },
        {
            hour: "02",
        },
        {
            hour: "03",
        },
        {
            hour: "04",
        },
        {
            hour: "05",
        },
        {
            hour: "06",
        },
        {
            hour: "07",
        },
        {
            hour: "08",
        },
        {
            hour: "09",
        },
        {
            hour: "10",
        },
        {
            hour: "11",
        },
        {
            hour: "12",
        },
        {
            hour: "13",
        },
        {
            hour: "14",
        },
        {
            hour: "15",
        },
        {
            hour: "16",
        },
        {
            hour: "17",
        },
        {
            hour: "18",
        },
        {
            hour: "19",
        },
        {
            hour: "20",
        },
        {
            hour: "21",
        },
        {
            hour: "22",
        },
        {
            hour: "23",
        },
    ]
    const [showContainer, setShowContainer] = useState(false);
    const [hour, setHour] = useState(state.timeFormated.hour);
    const [minute, setMinute] = useState(state.timeFormated.minute);

    const [timeLabel, setTimeLabel] = useState(state.time);

    useEffect(() => {

        console.log(state.timeAny);

        if(state.timeAny){
            setTimeLabel(t('Any time'));
        }

        if(state.timeSpecify){
            setTimeLabel(t('Clarify the day before'));
        }

        console.log('rerender time');

    }, [state.timeAny, state.timeSpecify, state.time]);

    const handleSetAny = async() => {
        // set show loading
        setIsLoading(true);

        var ajaxResponse = await makeAjaxCall('/order/time/set', {
            id: state.id,
            time: 'any',
        }, true);

        if (ajaxResponse && ajaxResponse.result) {
            // change payment method
            setOrderData(ajaxResponse.data);
        }else{
            // show main error
            setShowMainErrorPopup(true);
        }

        // set loading
        setIsLoading(false);
        setShowContainer(false);
    }

    const handleSetSpecify = async() => {
        // set show loading
        setIsLoading(true);

        var ajaxResponse = await makeAjaxCall('/order/time/set', {
            id: state.id,
            time: 'specify',
        }, true);

        if (ajaxResponse && ajaxResponse.result) {
            // change payment method
            setOrderData(ajaxResponse.data);
        }else{
            // show main error
            setShowMainErrorPopup(true);
        }

        // set loading
        setIsLoading(false);
        setShowContainer(false);
    }

    const setTime = async(hour, minute) => {
        // set show loading
        setIsLoading(true);

        var ajaxResponse = await makeAjaxCall('/order/time/set', {
            id: state.id,
            time: hour + ':' + minute + ':00',
        }, true);

        if (ajaxResponse && ajaxResponse.result) {
            // change payment method
            setOrderData(ajaxResponse.data);
        }else{
            // show main error
            setShowMainErrorPopup(true);
        }

        // set loading
        setIsLoading(false);
        setShowContainer(false);
    }

    const handleTimeClick = () => {
        setShowContainer(!showContainer);
    }

    return (
        <div className={styles.setTime}>
            <div onClick={handleTimeClick}
                className="button middle time shadow rounded animate">
                <SvgIcon id="icon-time" className="icon"/>
                <span>{timeLabel}</span>
            </div>
            {showContainer && (<div className={`${styles.setTimeContainer} rounded animate shadow`}>
                <div className="minutes">
                    {availableTime.map((time, i) => (
                        <Time
                            time={time}
                            key={i}
                            state={state}
                            setTime={setTime}
                        />
                    ))}
                </div>
                <div className="any-specify">
                    <div onClick={handleSetAny}
                         className={`${state.time && state.timeAny ? 'active' : ''} item rounded animate`}>
                        {t('You can start at any time')}
                    </div>
                    <div onClick={handleSetSpecify}
                         className={`${state.time && state.timeSpecify ? 'active' : ''} item rounded animate`}>
                        {t('Clarify the day before the cleaning')}
                    </div>
                </div>
            </div>)}
        </div>
    );
}

export default SetTime;