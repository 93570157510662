import { actionQuestionAddTypes } from 'constants/actionQuestionAddTypes';

export const setShowMainError = (dispatch, payload) =>
    dispatch({ type: actionQuestionAddTypes.SET_SHOW_MAIN_ERROR, payload });

export const setShowLoading = (dispatch, payload) =>
    dispatch({ type: actionQuestionAddTypes.SET_SHOW_LOADING, payload });

export const setStatus = (dispatch, payload) =>
    dispatch({ type: actionQuestionAddTypes.SET_STATUS, payload });

export const setQuestionType = (dispatch, payload) =>
    dispatch({ type: actionQuestionAddTypes.SET_QUESTION_TYPE, payload });

export const setQuestion = (dispatch, payload) =>
    dispatch({ type: actionQuestionAddTypes.SET_QUESTION, payload });

export const setSlug = (dispatch, payload) =>
    dispatch({ type: actionQuestionAddTypes.SET_SLUG, payload });

export const setShowErrors = (dispatch, payload) =>
    dispatch({ type: actionQuestionAddTypes.SET_SHOW_ERRORS, payload });

export const setPageTitle = (dispatch, payload) =>
    dispatch({ type: actionQuestionAddTypes.SET_PAGE_TITLE, payload });

export const setMetaDescription = (dispatch, payload) =>
    dispatch({ type: actionQuestionAddTypes.SET_META_DESCRIPTION, payload });

export const setAnswer = (dispatch, payload) =>
    dispatch({ type: actionQuestionAddTypes.SET_ANSWER, payload });

export const setFront = (dispatch, payload) =>
    dispatch({ type: actionQuestionAddTypes.SET_FRONT, payload });

export const setQuestionData = (dispatch, payload) =>
    dispatch({ type: actionQuestionAddTypes.SET_QUESTION_DATA, payload });