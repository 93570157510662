import styles from './SetLabelsPopup.module.less';
import { useTranslation } from "react-i18next";
import { useOrderContext } from 'contexts/OrderContext';
import SvgIcon from 'components/SvgIcon';
import SetLabelsPopupLabel from 'components/Order/Popup/SetLabelsPopup/SetLabelsPopupLabel';
import useAjaxCall from "utils/useAjaxRequest";
import { AjaxRoutes } from 'constants/ajaxRoutes';

const MainErrorPopup = () => {
    const {
        state,
        actions: {
            setShowLabelsPopup,
            setIsLoading,
            setShowMainErrorPopup,
            setMessages,
            setLabelsAdmin,
            setLabelsPartner,
            setLabels,
        },
    } = useOrderContext();

    const {t} = useTranslation();
    const { makeAjaxCall } = useAjaxCall();

    const HandleClosePopup = async() => {
        setShowLabelsPopup(false);
    }

    const HandleSaveLabels = async() => {
        // show loading
        setIsLoading(true);

        var ajaxResponse = await makeAjaxCall(AjaxRoutes.ORDER_LABELS_SET, {
            id: state.id,
            labelsPartner: state.labelsPartner.map((type) => ({ id: type })),
            labelsAdmin: state.labelsAdmin.map((type) => ({ id: type })),
        }, true);

        if (ajaxResponse && ajaxResponse.result) {
            // show message to manager
            setMessages(ajaxResponse.data.messages);

            // update labels data
            setLabelsAdmin(ajaxResponse.data.labelsAdmin);
            setLabelsPartner(ajaxResponse.data.labelsPartner);

            // set labels data
            setLabels(ajaxResponse.data.labels);
        }else{
            // show error and reload order
            setShowMainErrorPopup(true);
        }

        // hide popup
        setShowLabelsPopup(false);

        // set loading status
        setIsLoading(false);
    }

    return (
        <div className={styles.orderPopup}>
            <div className="order-popup-close animate" onClick={HandleClosePopup}>
                <SvgIcon id="icon-close" className="icon"/>
            </div>
            <div className="order-popup-title">{t('Add labels to order')}</div>
            <div className="order-popup-content">
                <div className="set-labels">
                    <div className="set-labels-category rounded set-labels-category-partners">
                        <div className="set-labels-title">{t('Labels available to partners')}</div>
                        <div className="set-labels-category-items">
                            {state.availableLabels.partner.map((label, i) => (
                                <SetLabelsPopupLabel
                                    label={label}
                                    key={i}
                                />
                            ))}
                        </div>
                    </div>
                    <div className="set-labels-category rounded set-labels-category-admin">
                        <div className="set-labels-title">{t('Labels available to managers')}</div>
                        <div className="set-labels-category-items">
                            {state.availableLabels.admin.map((label, i) => (
                                <SetLabelsPopupLabel
                                    label={label}
                                    key={i}
                                />
                            ))}
                        </div>
                    </div>
                </div>
                <div className="actions">
                    <div className="button blue middle rounded animate" onClick={HandleSaveLabels}>
                        <SvgIcon id="icon-save-white" className="svg-logo"/>
                        <span>{t('Save')}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MainErrorPopup;