import { actionPromocodeAddTypes } from 'constants/actionPromocodeAddTypes';

export const setShowMainError = (dispatch, payload) =>
    dispatch({ type: actionPromocodeAddTypes.SET_SHOW_MAIN_ERROR, payload });

export const clearState = (dispatch, payload) =>
    dispatch({ type: actionPromocodeAddTypes.CLEAR_STATE, payload });

export const setStatus = (dispatch, payload) =>
    dispatch({ type: actionPromocodeAddTypes.SET_STATUS, payload });

export const setType = (dispatch, payload) =>
    dispatch({ type: actionPromocodeAddTypes.SET_TYPE, payload });

export const setService = (dispatch, payload) =>
    dispatch({ type: actionPromocodeAddTypes.SET_SERVICE, payload });

export const setIsLoading = (dispatch, payload) =>
    dispatch({ type: actionPromocodeAddTypes.SET_IS_LOADING, payload });

export const setCode = (dispatch, payload) =>
    dispatch({ type: actionPromocodeAddTypes.SET_CODE, payload });

export const setNumber = (dispatch, payload) =>
    dispatch({ type: actionPromocodeAddTypes.SET_NUMBER, payload });

export const setValue = (dispatch, payload) =>
    dispatch({ type: actionPromocodeAddTypes.SET_VALUE, payload });

export const setInfinitive = (dispatch, payload) =>
    dispatch({ type: actionPromocodeAddTypes.SET_INFINITIVE, payload });

export const setCalendarDataFrom = (dispatch, payload) =>
    dispatch({ type: actionPromocodeAddTypes.SET_CALENDAR_DATA_FROM, payload });

export const setCalendarDataTo = (dispatch, payload) =>
    dispatch({ type: actionPromocodeAddTypes.SET_CALENDAR_DATA_TO, payload });

export const setDateFrom = (dispatch, payload) =>
    dispatch({ type: actionPromocodeAddTypes.SET_DATE_FROM, payload });

export const setDateTo = (dispatch, payload) =>
    dispatch({ type: actionPromocodeAddTypes.SET_DATE_TO, payload });

export const setShowErrors = (dispatch, payload) =>
    dispatch({ type: actionPromocodeAddTypes.SET_SHOW_ERRORS, payload });

export const setPromocodeData = (dispatch, payload) =>
    dispatch({ type: actionPromocodeAddTypes.SET_PROMOCODE_DATA, payload });