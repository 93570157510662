import styles from './ChoosePartnerPopupPartner.module.less';
import { useTranslation } from "react-i18next";
import useAjaxCall from "utils/useAjaxRequest";
import { AjaxRoutes } from 'constants/ajaxRoutes';
import SvgIcon from 'components/SvgIcon';
import { useOrderContext } from 'contexts/OrderContext';

const ChoosePartnerPopupPartner = ({ partner, setShowChoosePartnersPopup, serviceTypeId, ...props }) => {
    const {
        state,
        actions: {
            setIsLoading,
            setShowMainErrorPopup,
            setServiceTypes,
            setIsFullPartners,
            setPartnerCashName,
            setCleaningTime,
        },
    } = useOrderContext();

    const { makeAjaxCall } = useAjaxCall();
    const {t} = useTranslation();

    const HandlePartnerChoose = async(event) => {
        // close popup with partners
        setShowChoosePartnersPopup(false);

        // show loading
        setIsLoading(true);

        var ajaxResponse = await makeAjaxCall(AjaxRoutes.ORDER_PARTNER_SET,{
            id: state.id,
            idPartner: partner.id,
            idServiceType: serviceTypeId,
        }, true);

        if (ajaxResponse && ajaxResponse.result) {
            // TODO update only partners for one type, not all types
            // update service types
            setServiceTypes(ajaxResponse.data.serviceTypes);

            // set full partners
            setIsFullPartners(ajaxResponse.data.isFullPartners);

            // set partner cash name
            setPartnerCashName(ajaxResponse.data.partnerCashName);

            // set cleaning time
            setCleaningTime(ajaxResponse.data.cleaningTime);

        }else{
            // show error and reload order
            setShowMainErrorPopup(true);
        }

        // set loading status
        setIsLoading(false);
    }

    return (
        <div className={styles.choosePartner + " animate rounded"} onClick={HandlePartnerChoose}>
            <div className="partner-name">
                <div className="name">{partner.name}</div>
                <div className="own-vacuum">
                    <SvgIcon id="icon-vacuum" className="svg-logo"/>
                </div>
                <div className="has-debt">
                    <SvgIcon id="icon-bomb" className="svg-logo"/>
                </div>
            </div>
            <div className="partner-info">
                <div className="number-of-orders">{partner.numberOfOrders}</div>
                {partner.hasTodayOrders ? <div className="today-orders"><span className="label">{t('Today orders')}:</span><span className="value">{partner.todayOrdersTime.join(', ')}</span></div> : ''}
                {partner.hasTodayWeekends ? <div className="has-today-weekend">
                        <span className="label">{t('Weekend')}</span>
                        <span className="value">
                            {partner.weekendsData.map((weekend, i) => (
                                <span>{t('from')} {weekend.start} {t('to')} {weekend.stop}{partner.weekendsData.length - 1> i ? ',' : ''}</span>
                            ))}
                        </span>
                    </div>
                    : ''}
            </div>
        </div>
    );
}

export default ChoosePartnerPopupPartner;