import styles from './ChooseChannel.module.less';
import {useTranslation} from "react-i18next";
import { useOrderContext } from 'contexts/OrderContext';

import SvgIcon from 'components/SvgIcon';
import ChooseChannelItem from 'components/Order/ChooseChannel/ChooseChannelItem';

var availableChannels = [
    {
        value: 'website',
        label: 'Website'
    },
    {
        value: 'phone',
        label: 'Phone'
    },
    {
        value: 'facebook',
        label: 'Facebook'
    },
    {
        value: 'twitter',
        label: 'Twitter'
    },
    {
        value: 'instagram',
        label: 'Instagram'
    },
];

const ChooseItem = () => {
    const {t} = useTranslation();

    const {
        state,
        actions: { },
    } = useOrderContext();

    return (
        <div className={styles.chooseChannel}>
            <div className="label">{t('Order channel')}</div>
            <div className="choose-channel-container">
                <div className="active shadow animate rounded">
                    <span>{state.channel}</span>
                    <SvgIcon id="icon-arrow-down" className="icon animate"/>
                </div>
                <div className="available shadow rounded">
                    {availableChannels.map((channel, i) => (
                        <ChooseChannelItem
                            channel={channel}
                            key={i}
                            />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default ChooseItem;
