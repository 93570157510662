//import './SetLabelsPopupLabel.less';
import { useOrderContext } from 'contexts/OrderContext';
import {useEffect, useState} from "react";

const SetLabelsPopupLabel = ({ label, ...props }) => {
    const {
        state,
        actions: {
            setLabelsAdmin,
            setLabelsPartner,
        },
    } = useOrderContext();

    const [active, setActive] = useState(false);

    const HandleSetLabel = async() => {
        switch (label.type){
            case 'partner':
                if(active === false){
                    if(!state.labelsPartner.includes(label.id)){
                        state.labelsPartner.push(label.id);
                    }
                }else{
                    if(state.labelsPartner.includes(label.id)){
                        state.labelsPartner = state.labelsPartner.filter(e => e !== label.id);
                    }
                }

                break;
            case 'admin':
                if(active === false){
                    if(!state.labelsAdmin.includes(label.id)){
                        state.labelsAdmin.push(label.id);
                    }
                }else{
                    if(state.labelsAdmin.includes(label.id)){
                        state.labelsAdmin = state.labelsAdmin.filter(e => e !== label.id);
                    }
                }

                break;
        }

        setLabelsAdmin(state.labelsAdmin);
        setLabelsPartner(state.labelsPartner);
        setActive(!active);
    }

    useEffect(() => {
        if(state.labelsPartner.includes(label.id) || state.labelsAdmin.includes(label.id)){
            setActive(true);
        }else{
            setActive(false);
        }
    }, [active]);

    return (
        <div className={`
                button mini grey label rounded animate
                ${active ? 'active' : ''}
            `}
             style={{background: label.bgColor, color: label.fontColor, borderColor: label.bgColor}}
             onClick={HandleSetLabel}>
            <span>{label.label}</span>
        </div>
    );
}

export default SetLabelsPopupLabel;