import styles from './PlaceAutocomplete.module.less';
import moment from "moment";
import { useEffect, useRef, useState } from 'react';
import { useMapsLibrary } from '@vis.gl/react-google-maps';
import SvgIcon from 'components/SvgIcon';

const PlaceAutocomplete = ({ setAddressData, authState, setError, updatePriceTime, orderAddState, ...props }) => {
    const [placeAutocomplete, setPlaceAutocomplete] = useState(null);
    const isMobile = false;
    const inputRef = useRef(null);
    const places = useMapsLibrary('places');

    useEffect(() => {
        if (!places || !inputRef.current) return;

        const options = {
            fields: ['geometry', 'name', 'formatted_address', 'address_components'],
            //language: 'en',
        };

        setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));
    }, [places]);

    useEffect(() => {
        if (!placeAutocomplete) return;

        placeAutocomplete.addListener('place_changed', async() => {
            const place = placeAutocomplete.getPlace();
            if (!place.address_components) return;

            const parsedAddressComponents = {
                city: '',
                street: '',
                house: '',
                postcode: '',
                price: 0,
            };

            place.address_components.forEach((component) => {
                if (component.types.includes('locality')) {
                    parsedAddressComponents.city = component.long_name;
                } else if (component.types.includes('route')) {
                    parsedAddressComponents.street = component.long_name;
                } else if (component.types.includes('street_number')) {
                    parsedAddressComponents.house = component.long_name;
                } else if (component.types.includes('postal_code')) {
                    parsedAddressComponents.postcode = component.long_name;
                }
            });

            parsedAddressComponents.latitude = place.geometry.location.lat();
            parsedAddressComponents.longitude = place.geometry.location.lng();

            // validate address
            if((parsedAddressComponents.street !== undefined && parsedAddressComponents.street.length === 0)
                || (parsedAddressComponents.house.length === 0)){
                // show error
                setError({
                    address: true,
                });

                return;
            }

            const origin = {
                lat: parseFloat(authState.mapCenterLatitude),
                lng: parseFloat(authState.mapCenterLongitude),
            };

            const destination = {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
            };

            // hide error
            setError({
                address: false,
            });

            // set address data
            setAddressData(parsedAddressComponents);
        });
    }, [placeAutocomplete]);

    return (
        <div className={styles.autocompleteContainer + " rounded"}>
            <SvgIcon id="icon-address" className="svg-map" />
            <input
                ref={inputRef}
                type="text"
                name="search"
                placeholder={`Zacznij wpisywać swój adres${isMobile ? '' : 'a system Ci pomoże'}…`}
                className="search"
            />
        </div>
    );
};

export default PlaceAutocomplete;