import styles from './AddressData.module.less';
import { useTranslation } from "react-i18next";
import { APIProvider, AdvancedMarker } from '@vis.gl/react-google-maps';
import {useId, useRef, forwardRef} from "react";
import { useOrderAddContext } from 'contexts/OrderAddContext';
import { useAuthContext } from "contexts/AuthContext";
import Map from "components/OrderAdd/AddressData/Map";
import PlaceAutocomplete from "components/OrderAdd/AddressData/PlaceAutocomplete";
import AddressDetails from "components/OrderAdd/AddressData/AddressDetails";
import ErrorText from 'components/ErrorText';
import {setAddressData} from "../../../contexts/actions/orderAddActions";
import CustomerAddress from 'components/OrderAdd/AddressData/CustomerAddress';

const AddressData = forwardRef((props, ref) => {
    const { t } = useTranslation();

    const {
        orderAddState,
        actions: {
            setAddressData,
            setError,
            updatePriceTime,
        },
    } = useOrderAddContext();

    const {
        authState,
        actions: { },
    } = useAuthContext();
    
    const markerId = useId();
    const entranceId = useId();
    const apartmentNumberId = useId();
    const floorId = useId();
    const doorCodeId = useId();

    const apartmentRef = useRef(null);
    const entranceRef = useRef(null);
    const floorRef = useRef(null);
    const codeRef = useRef(null);

    const handleBlur = () => {
        if(apartmentRef.current.value){
            setAddressData({
                apartment: apartmentRef.current.value,
            });
        }

        if(entranceRef.current.value){
            setAddressData({
                entrance: entranceRef.current.value,
            });
        }

        if(floorRef.current.value){
            setAddressData({
                floor: codeRef.current.value,
            });
        }

        if(codeRef.current.value){
            setAddressData({
                code: codeRef.current.value,
            });
        }
    };

    return (
        <div ref={ref}
            className={`row ${styles.rowAddressData} ${orderAddState.errors.address ? 'showError' : ''}`} >
            <div className="row-label">
                <span>{t('Address data')}</span>
                <span className="require">*{t('Required')}</span>
            </div>
            {orderAddState.errors.address && ( <ErrorText text={t('Enter the correct address for the order')} /> )}
            <div className="row-content">
                <div className="map-place-autocomplete-container">
                    <APIProvider
                        apiKey={process.env.REACT_APP_GOOGLE_MAPS_API}
                        solutionChannel="GMP_devsite_samples_v3_rgmautocomplete">
                        <div className="left-block">
                            {orderAddState.addressPrice.getAmount() > 0 && (<div className="addressPrice rounded">
                                <div className="label">{t('Transport cost')}</div>
                                <div className="price">{orderAddState.addressPrice.toFormat()}</div>
                            </div>)}
                            <PlaceAutocomplete
                                setAddressData={setAddressData}
                                setError={setError}
                                updatePriceTime={updatePriceTime}
                                authState={authState}
                                orderAddState={orderAddState}
                            />
                            <div className="address-details">
                                <AddressDetails
                                    ref={apartmentRef}
                                    id={apartmentNumberId}
                                    type="text"
                                    label={t('Apartment number')}
                                    onBlur={handleBlur}
                                />
                                <AddressDetails
                                    ref={entranceRef}
                                    id={entranceId}
                                    type="text"
                                    label={t('Ward number')}
                                    onBlur={handleBlur}
                                />
                                <AddressDetails
                                    ref={floorRef}
                                    id={floorId}
                                    type="text"
                                    label={t('Floor')}
                                    onBlur={handleBlur}
                                />
                                <AddressDetails
                                    ref={codeRef}
                                    id={doorCodeId}
                                    type="text"
                                    label={t('Door code')}
                                    onBlur={handleBlur}
                                />
                            </div>
                            {orderAddState.customer.addresses.length > 0 && (
                                <div className="address-choose">
                                    {orderAddState.customer.addresses.map((address, i) => (
                                        <CustomerAddress
                                            setAddressData={setAddressData}
                                            key={i}
                                            address={address}
                                            activeAddress={orderAddState.address.id}
                                            setError={setError}
                                        />
                                    ))}
                                </div>
                            )}
                        </div>
                        <Map orderAddState={orderAddState}
                            authState={authState}>
                            {orderAddState.address.latitude ?
                                <AdvancedMarker
                                    key={markerId}
                                    position={{
                                    lat: orderAddState.address.latitude,
                                    lng: orderAddState.address.longitude,
                                }}
                            />
                            : ''}
                        </Map>
                    </APIProvider>
                </div>
            </div>
        </div>
    );
});

export default AddressData;