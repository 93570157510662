const isValidValue = (value) =>
    value !== undefined && value !== null && value !== '';

export const createQueryString = (params) => {
    return Object.entries(params)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
};

export const createQueryStringWithURLSearchParams = (parameters) => {
    const queryParams = new URLSearchParams();
    
    for (const key in parameters) {
        const value = parameters[key];

        if (Array.isArray(value)) {
            value.forEach((item, index) => {
                if(typeof item === 'object'){
                    Object.entries(item).forEach(([subKey, subValue]) => {
                        if (isValidValue(subValue)) {
                            queryParams.append(`${key}[${index}][${subKey}]`, subValue);
                        }
                    });
                }else{
                    queryParams.append(`${key}[${index}]`, item);
                }
            });
        } else if (typeof value === 'object' && value !== null) {
            Object.entries(value).forEach(([subKey, subValue]) => {
                if (isValidValue(subValue)) {
                    queryParams.append(`${key}[${subKey}]`, subValue);
                }
            });
        } else {
            if (isValidValue(value)) {
                queryParams.append(key, value);
            }
        }
    }

    return queryParams.toString();
};