//import styles from './ServiceType.module.less';
import {useTranslation} from "react-i18next";
import SvgIcon from 'components/SvgIcon';
import { useOrderAddContext } from 'contexts/OrderAddContext';

const Languages = ({ serviceType, ...props }) => {
    const { t } = useTranslation();

    const {
        orderAddState,
        actions: {
            setPrivateHouse,
            updatePriceTime,
        },
    } = useOrderAddContext();

    const HandleSetPrivateHouse = async() => {
        if(orderAddState.privateHouse){
            setPrivateHouse(!orderAddState.privateHouse);
        }else{
            setPrivateHouse(true);
        }

        updatePriceTime(['price', 'time']);
    };

    return (
        <div className={`row row-check row-private-house ${orderAddState.privateHouse ? 'active' : ''}`}
             onClick={HandleSetPrivateHouse}>
            <div className="square animate rounded">
                <SvgIcon id="icon-check-square-white" className="svg" />
            </div>
            <span>{t('Private house ( price and time for basic options increased by 20% )')}</span>
            <span className="price rounded">x1.2</span>
        </div>
    );
};

export default Languages;