import styles from './ComingDays.module.less';
import stylesDashboard from '../DashboardBlock.module.less';
import {memo, forwardRef, useState, useRef, useEffect} from 'react';
import {useTranslation} from "react-i18next";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import { useAuthContext } from "contexts/AuthContext";
import useAjaxCall from "utils/useAjaxRequest";
import ServiceType from 'components/Dashboard/ComingDays/ServiceType';
import Day from 'components/Dashboard/ComingDays/Day';
import Loading from 'components/Loading';
import SetDiscount from 'components/Dashboard/ComingDays/SetDiscount';
import SetCloseTime from 'components/Dashboard/ComingDays/SetCloseTime';

const ComingDays = memo(
    forwardRef(({ setShowMainError, ...props }, ref) => {
        const {
            authState,
            actions: {  },
        } = useAuthContext();

        const {t} = useTranslation();
        const {makeAjaxCall} = useAjaxCall();

        const refs = useRef({});

        const [width, setWidth] = useState(0);
        const [marginLeft, setMarginLeft] = useState(0);
        const [activeServiceType, setActiveServiceType] = useState(authState.servicesByType[0].id);
        const [activeServiceTypeKey, setActiveServiceTypeKey] = useState(0);
        const [showLoading, setShowLoading] = useState(true);
        const [showSetDiscount, setShowSetDiscount] = useState(false);
        const [showSetCloseTime, setShowSetCloseTime] = useState(false);

        const [discountDate, setDiscountDate] = useState(null);
        const [discountDateFormated, setDiscountDateFormated] = useState(null);
        const [closeTimeDate, setCloseTimeDate] = useState(null);
        const [closeTimeDateFormated, setCloseTimeDateFormated] = useState(null);

        const [discountsData, setDiscountsData] = useState(null);
        const [closedTimeData, setClosedTimeData] = useState(null);

        const [activeServiceTypeDiscounts, setActiveServiceTypeDiscounts] = useState(authState.servicesByType[0].id);
        const [activeServiceTypeClosedTime, setActiveServiceTypeClosedTime] = useState(authState.servicesByType[0].id);

        const [comingDays, setComingDays] = useState([
            {
                date: '01.12',
                today: true,
                numberOfOrders: 0,
                partners: {
                    holeDay: 0,
                    partDay: 0,
                    noOrder: 0,
                },
                discounts: {},
                closedTime: {},
            },
            {
                date: '02.12',
                today: false,
                numberOfOrders: 0,
                partners: {
                    holeDay: 0,
                    partDay: 0,
                    noOrder: 0,
                },
                discounts: {},
                closedTime: {},
            },
            {
                date: '03.12',
                today: false,
                numberOfOrders: 0,
                partners: {
                    holeDay: 0,
                    partDay: 0,
                    noOrder: 0,
                },
                discounts: {},
                closedTime: {},
            },
            {
                date: '04.12',
                today: false,
                numberOfOrders: 0,
                partners: {
                    holeDay: 0,
                    partDay: 0,
                    noOrder: 0,
                },
                discounts: {},
                closedTime: {},
            },
            {
                date: '05.12',
                today: false,
                numberOfOrders: 0,
                partners: {
                    holeDay: 0,
                    partDay: 0,
                    noOrder: 0,
                },
                discounts: {},
                closedTime: {},
            },
            {
                date: '06.12',
                today: false,
                numberOfOrders: 0,
                partners: {
                    holeDay: 0,
                    partDay: 0,
                    noOrder: 0,
                },
                discounts: {},
                closedTime: {},
            },
        ]);

        const availablePercentage = [
            {
                type: 'zero',
                items: [0],
            },
            {
                type: 'discount',
                items: [3,5,7,10,13,15,18,20,22,25,30,33,35,40,45,50,55,60,65,70,75,80,85,90,95,100],
            },
            {
                type: 'coefficient',
                items: [3,5,7,10,13,15,18,20,22,25,30,33,35,40,45,50,55,60,65,70,75,80,85,90,95,100],
            }
        ];

        const availableTime = [
            '06:00',
            '07:00',
            '08:00',
            '09:00',
            '10:00',
            '11:00',
            '12:00',
            '13:00',
            '14:00',
            '15:00',
            '16:00',
            '17:00',
            '18:00',
            '19:00',
            '20:00',
        ];

        useEffect(() => {
            if(refs.current[activeServiceTypeKey]){
                // set width
                setWidth(refs.current[activeServiceTypeKey].offsetWidth);

                // calculate margin left
                var slideObject = document.getElementById('swiper-slide-' + activeServiceTypeKey);
                var elementOffsetLeft = slideObject.getBoundingClientRect().left;

                var container = document.getElementById('swiper-types')
                const containerOffsetLeft = container.getBoundingClientRect().left;

                const relativeOffsetLeft = elementOffsetLeft - containerOffsetLeft;

                setMarginLeft(relativeOffsetLeft);
            }
        }, [activeServiceTypeKey]);

        const getDataFromAjax = async () => {
            var ajaxResponse = await makeAjaxCall('/dashboard/comingDays', {}, true);

            if (ajaxResponse && ajaxResponse.result) {
                setComingDays(ajaxResponse.data);
            }else{
                setShowMainError(true);
            }

            setShowLoading(false);
        };

        useEffect(() => {
            // get data from ajax
            getDataFromAjax();
        }, []);

        const setDiscount = async(type, value) => {
            setShowMainError(false);
            setShowLoading(true);

            var ajaxResponse = await makeAjaxCall('/dashboard/date/discount/set', {
                date: discountDateFormated,
                type: type,
                value: value,
                serviceType: activeServiceTypeDiscounts,
            }, true);

            if (ajaxResponse && ajaxResponse.result) {
                updateDateDiscounts(ajaxResponse.data);
            }else{
                setShowMainError(true);
                setShowSetDiscount(false);
            }

            setShowLoading(false);
        }

        const updateDateDiscounts = (data) => {
            setComingDays((prevComingDays) => {
                let tmpDiscountData = {};

                const updatedDays = prevComingDays.map((day) => {
                    if (day.date === data.date) {
                        const updatedDiscounts = { ...day.discounts };

                        if (data.value === 0) {
                            delete updatedDiscounts[data.idServiceType];
                        } else {
                            updatedDiscounts[data.idServiceType] = {
                                type: data.type,
                                value: data.value,
                            };
                        }

                        tmpDiscountData = updatedDiscounts;

                        return {
                            ...day,
                            discounts: updatedDiscounts,
                        };
                    }
                    return day;
                });

                setDiscountsData(tmpDiscountData);
                return updatedDays;
            });
        }

        const setClosedTime = async(time) => {
            setShowLoading(true);
            setShowMainError(false);

            var ajaxResponse = await makeAjaxCall('/dashboard/time/closed/set', {
                date: closeTimeDateFormated,
                time: time,
                serviceType: activeServiceTypeClosedTime,
            }, true);

            if (ajaxResponse && ajaxResponse.result) {
                updateClosedTime(ajaxResponse.data);
            }else{
                setShowMainError(true);
                setShowSetCloseTime(false);
            }

            setShowLoading(false);
        }

        const updateClosedTime = (data) => {
            setClosedTimeData((prevClosedTimeData) => {
                const updatedClosedTimeData = { ...prevClosedTimeData };

                if (data.remove === true) {
                    updatedClosedTimeData[data.idServiceType] = updatedClosedTimeData[data.idServiceType]?.filter(
                        (time) => time !== data.time
                    );
                } else {
                    const currentTimes = updatedClosedTimeData[data.idServiceType] || [];
                    if (!currentTimes.includes(data.time)) {
                        updatedClosedTimeData[data.idServiceType] = [...currentTimes, data.time];
                    }
                }

                return updatedClosedTimeData;
            });

            setComingDays((prevComingDays) =>
                prevComingDays.map((day) => {
                    if (day.date === data.date) {
                        const updatedClosedTime = { ...day.closedTime };
                        const timesForServiceType = updatedClosedTime[data.idServiceType] || [];

                        if (!timesForServiceType.includes(data.time)) {
                            updatedClosedTime[data.idServiceType] = [...timesForServiceType, data.time];
                        } else {
                            updatedClosedTime[data.idServiceType] = timesForServiceType.filter((time) => time !== data.time);
                        }

                        if (updatedClosedTime[data.idServiceType]?.length === 0) {
                            delete updatedClosedTime[data.idServiceType];
                        }

                        return {
                            ...day,
                            closedTime: updatedClosedTime,
                        };
                    }
                    return day;
                })
            );
        }

        return (
            <>
                {showSetCloseTime && (<SetCloseTime
                    setShowSetCloseTime={setShowSetCloseTime}
                    closeTimeDate={closeTimeDate}
                    availableTime={availableTime}
                    servicesByType={authState.servicesByType}
                    setClosedTime={setClosedTime}
                    closedTimeData={closedTimeData}
                    activeServiceTypeClosedTime={activeServiceTypeClosedTime}
                    setActiveServiceTypeClosedTime={setActiveServiceTypeClosedTime}
                /> )}
                {showSetDiscount && ( <SetDiscount
                    discountDate={discountDate}
                    setShowSetDiscount={setShowSetDiscount}
                    availablePercentage={availablePercentage}
                    servicesByType={authState.servicesByType}
                    setDiscount={setDiscount}
                    discountsData={discountsData}
                    activeServiceTypeDiscounts={activeServiceTypeDiscounts}
                    setActiveServiceTypeDiscounts={setActiveServiceTypeDiscounts}
                /> )}
                <div className={`${stylesDashboard.dashboardBlock} ${styles.dashboardBlockComingDays}`}>
                    <div className="dashboard-block-title">{t('Coming days')}</div>
                    <div className="dashboard-block-content comming-days-content">
                        {showLoading && <Loading />}
                        <Swiper
                            id="swiper-types"
                            key={'swiper-types'}
                            className="service-types"
                            modules={[Navigation, Pagination, Scrollbar, A11y]}
                            spaceBetween={5} // Расстояние между слайдами
                            slidesPerView="auto" // Количество видимых слайдов
                            //navigation // Включить навигацию (стрелки)
                            //pagination={{ clickable: true }} // Включить пагинацию
                            //scrollbar={{ draggable: true }} // Включить скроллбар
                            //onSwiper={(swiper) => console.log(swiper)} // Коллбек на инициализацию
                            //onSlideChange={() => console.log('Slide changed')} // Коллбек на смену слайда
                        >
                            {authState.servicesByType.map((serviceType, i) => (
                                <SwiperSlide
                                    id={'swiper-slide-' + i}
                                    key={i}>
                                    <ServiceType
                                        ref={(el) => (refs.current[i] = el)}
                                        key={i}
                                        index={i}
                                        serviceType={serviceType}
                                        activeServiceType={activeServiceType}
                                        setActiveServiceType={setActiveServiceType}
                                        setActiveServiceTypeKey={setActiveServiceTypeKey}
                                    />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        <div className="line rounded">
                            <div className="active rounded animate" style={{width: width, marginLeft: marginLeft }}></div>
                        </div>
                        <div className="days">
                            <Swiper
                                key="swiper-days"
                                id="swiper-days"
                                modules={[Navigation, Pagination, Scrollbar, A11y]}
                                spaceBetween={10}
                                slidesPerView="auto">
                                {comingDays.map((day, i) => (
                                    <SwiperSlide
                                        key={'slide-' + i}>
                                        <Day
                                            key={i}
                                            day={day}
                                            setCloseTimeDateFormated={setCloseTimeDateFormated}
                                            setShowSetDiscount={setShowSetDiscount}
                                            setDiscountDate={setDiscountDate}
                                            setDiscountDateFormated={setDiscountDateFormated}
                                            setShowSetCloseTime={setShowSetCloseTime}
                                            setCloseTimeDate={setCloseTimeDate}
                                            setDiscountsData={setDiscountsData}
                                            setClosedTimeData={setClosedTimeData}
                                        />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </>
        );
    })
)

export default ComingDays;