import styles from './SetPaymentMethod.module.less';
import { useTranslation } from "react-i18next";
import { useOrderAddContext } from 'contexts/OrderAddContext';
import { useAuthContext } from 'contexts/AuthContext';
import Method from 'components/OrderAdd/SetPaymentMethod/Method';

const SetPaymentMethod = () => {
    const {
        orderAddState,
        actions: { },
    } = useOrderAddContext();

    const {
        authState,
        actions: { },
    } = useAuthContext();

    const { t } = useTranslation();

    return (
        <div className={styles.paymentMethod}>
            <div className={styles.paymentStatus}>
                <div className="row-label">{t('Payment method')}</div>
                <div className="row-content">
                    {authState.paymentMethods.map((method, i) => (
                        <Method
                            paymentMethod={method}
                            count={i}
                            key={i}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default SetPaymentMethod;