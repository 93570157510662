import styles from './ChoosePartnerCash.module.less';
import {useTranslation} from "react-i18next";
import { useOrderContext } from 'contexts/OrderContext';

import SvgIcon from 'components/SvgIcon';
import ChoosePartnerCashItem from 'components/Order/ChoosePartnerCash/ChoosePartnerCashItem';
import {memo, useEffect, useState} from "react";

const ChoosePartnerCash = memo(() => {
    const {t} = useTranslation();

    const {
        state,
        actions: { },
    } = useOrderContext();

    const [partnersCash, setPartnersCash] = useState([]);
    
    useEffect(() => {
        var partnersData = [];
        for(var i = 0; i < state.serviceTypes.length; i++){
            for(var k = 0; k < state.serviceTypes[i].partners.length; k++){
                partnersData.push({
                    id: state.serviceTypes[i].partners[k].id,
                    name: state.serviceTypes[i].partners[k].name,
                });
            }
        }

        setPartnersCash(partnersData);
    }, [state.serviceTypes]);

    return (
        <div className={styles.chooseItem + " channel"}>
            <div className="label">{t('Indicate which of the partners has the cash')}</div>
            <div className="choose-item-container">
                <div className="active shadow animate rounded">
                    <span>{state.partnerCashName}</span>
                    <SvgIcon id="icon-arrow-down" className="icon animate"/>
                </div>
                <div className="available shadow rounded">
                    {partnersCash.map((partner, i) => (
                        <ChoosePartnerCashItem
                            partner={partner}
                            key={i}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
});

export default ChoosePartnerCash;