export const actionQuestionAddTypes = {
    SET_SHOW_MAIN_ERROR: 'SET_SHOW_MAIN_ERROR',
    SET_SHOW_LOADING: 'SET_SHOW_LOADING',
    SET_STATUS: 'SET_STATUS',
    SET_QUESTION_TYPE: 'SET_QUESTION_TYPE',
    SET_QUESTION: 'SET_QUESTION',
    SET_SLUG: 'SET_SLUG',
    SET_SHOW_ERRORS: 'SET_SHOW_ERRORS',
    SET_PAGE_TITLE: 'SET_PAGE_TITLE',
    SET_META_DESCRIPTION: 'SET_META_DESCRIPTION',
    SET_ANSWER: 'SET_ANSWER',
    SET_FRONT: 'SET_FRONT',
    SET_QUESTION_DATA: 'SET_QUESTION_DATA',
};