import styles from './ChoosePartnerPopup.module.less';
import { useTranslation } from "react-i18next";
import {useState, useId, useEffect} from 'react';

import SvgIcon from 'components/SvgIcon';
import Input from 'components/Input';
import ChoosePartnerPopupPartner from "components/Order/ServiceType/ChoosePartnerPopup/ChoosePartnerPopupPartner";

import { useOrderContext } from 'contexts/OrderContext';

const ChoosePartnerPopup = ({ setShowChoosePartnersPopup, choosePartners, serviceTypeId, setPartners, ...props }) => {
    const {
        state,
        actions: { setIsLoading },
    } = useOrderContext();

    const {t} = useTranslation();

    const filterFormId = useId();
    const [filterInput, setFilterInput] = useState(props?.value ?? '');

    const HandleFilterInput = async(event) => {
        setFilterInput(event.target.value);
        console.log(event.target.value);
    }

    const HandleClosePopup = async(event) => {
        setShowChoosePartnersPopup(false);
    }

    return (
        <div className={styles.orderPopup}>
            <div className="order-popup-close animate" onClick={HandleClosePopup}>
                <SvgIcon id="icon-close" className="icon"/>
            </div>
            <div className="order-popup-title">{t('Choose partner')}</div>
            <div className="order-popup-content">
                <div className="filter-form">
                    <Input
                        id={filterFormId}
                        type="text"
                        cssStyleInput="rounded"
                        value={filterInput}
                        onInput={HandleFilterInput}
                    />
                    <SvgIcon id="icon-search" className="icon"/>
                </div>
                <div className="choose-partners">
                    {choosePartners.map((partner, i) => (
                        <ChoosePartnerPopupPartner
                            partner={partner}
                            setShowChoosePartnersPopup={(value) => setShowChoosePartnersPopup(value)}
                            setPartners={(value) => setPartners(value)}
                            serviceTypeId={serviceTypeId}
                            key={i}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default ChoosePartnerPopup;