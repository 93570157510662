import styles from './Messages.module.less';
import {useTranslation} from "react-i18next";
import { useOrderContext } from 'contexts/OrderContext';

import Message from 'components/Order/Messages/Message';

const Messages = () => {
    const {t} = useTranslation();

    const {
        state,
        actions: { },
    } = useOrderContext();

    return (
        <div className={styles.messages}>
            {state.messages.map((message, i) => (
                <Message
                    message={message}
                    key={i}
                />
            ))}
        </div>
    );
}

export default Messages;