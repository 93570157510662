import styles from './CustomerPaymentData.module.less';
import { useTranslation } from "react-i18next";
import { useOrderAddContext } from 'contexts/OrderAddContext';
import Card from 'components/OrderAdd/CustomerPaymentData/Card';

const CustomerPaymentData = ({ cards, ...props }) => {
    const { t } = useTranslation();

    const {
        orderAddState,
        actions: {
            setActiveCard,
        },
    } = useOrderAddContext();

    return (
        <div className={`row ${styles.rowCustomerPaymentData}`}>
            <div className="row-label">
                <span>{t('Customer\'s payment data')}</span>
            </div>
            <div className="row-content">
                {cards.map((card, i) => (
                    <Card
                        key={i}
                        card={card}
                        setActiveCard={setActiveCard}
                        activeCard={orderAddState.activeCard}
                    />
                ))}
            </div>
        </div>
    );
};

export default CustomerPaymentData;