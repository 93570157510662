import styles from '../LegalEntityInput/LegalEntityInput.module.less';
import { memo, forwardRef, useState, useEffect } from 'react';
import ErrorText from 'components/ErrorText';
import MaskedInput from 'react-text-mask';

const LegalEntityInput = memo(
    forwardRef(({ label, className, authState, error, errorText, ...props }, ref) => {

        const [regex, setRegex] = useState([/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]);

        useEffect(() => {
            if(authState.postCodeMask){
                // Преобразование строки в массив регулярных выражений
                const initialRegexArray = authState.postCodeMask.split(/,\s*/).map((part) => {
                    if (part.startsWith("[") && part.endsWith("]")) {
                        // Удаляем квадратные скобки и создаем RegExp
                        const pattern = part.slice(1, part.length - 1);
                        return new RegExp(`[${pattern}]`);
                    } else {
                        // Возвращаем строку без кавычек
                        return part.replace(/"/g, "");
                    }
                });

                setRegex(initialRegexArray);
            }else{
                setRegex([/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/,/[0-9]/]);
            }

            console.log(regex);

        }, [authState.postCodeMask]);

        return (
            <div className={`${styles.item} ${styles[className]} ${error ? styles.showError : ''}`}>
                <label htmlFor={props.id} className="label">
                    {label}
                </label>
                {error && ( <ErrorText text={errorText} /> )}
                <div className="input-wrapper">
                    <MaskedInput
                        className="rounded animate"
                        mask={regex}
                        value={props.value}
                        ref={ref}
                        {...props}
                    />
                </div>
            </div>
        );
    })
);

export default LegalEntityInput;