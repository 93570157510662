import styles from './Labels.module.less';
import { useOrderContext } from 'contexts/OrderContext';
import SvgIcon from 'components/SvgIcon';
import Label from 'components/Order/Labels/Label';
import useAjaxCall from "utils/useAjaxRequest";
import { AjaxRoutes } from 'constants/ajaxRoutes';

const ChooseItem = ({ ...props }) => {
    const {
        state,
        actions: {
            setShowLabelsPopup,
            setIsLoading,
            setAvailableLabels,
            setShowMainErrorPopup,
        },
    } = useOrderContext();
    const { makeAjaxCall } = useAjaxCall();

    const HandleAddLabel = async() => {
        // show loading
        setIsLoading(true);

        var ajaxResponse = await makeAjaxCall(AjaxRoutes.LABELS_LIST_AVAILABLE,{
            page: 1,
            perPage: -1,
        }, true);

        if (ajaxResponse && ajaxResponse.result) {
            // set available labels
            setAvailableLabels(ajaxResponse.data);

            // show popup
            setShowLabelsPopup(true);
        }else{
            // show error and reload order
            setShowMainErrorPopup(true);
        }

        // set loading status
        setIsLoading(false);
    }

    return (
        <div className={styles.orderLabels}>
            {state.labels.map((label, i) => (
                <Label
                    label={label}
                    key={i}
                />
            ))}
            <div className="label animate shadow rounded add" onClick={HandleAddLabel}>
                <SvgIcon id="icon-add" className="icon"/>
            </div>
        </div>
    );
}

export default ChooseItem;
