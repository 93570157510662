import { actionTypes } from 'constants/actionTypes';

export var initialState = {
    id: 0,
    date: null,
    time: null,
    description: null,
    address: null,
    latitude: null,
    longitude: null,
    cleaningTime: 0,
    numberOfEmployees: 0,
    serviceTypesLabels: [],
    serviceTypes: [],
    partnersChooseByCustomer: false,
    price: 0,
    priceFull: 0,
    channel: null,
    isLoading: true,
    isFullPartners: false,
    showMainErrorPopup: false,
    showSetLabelsPopup: false,
    availableLabels: [],
    labels: [],
    labelsPartner: [],
    labelsAdmin: [],
    messages: [],
    partnerCashName: false,
    periodicity: 0,
    discount: false,
    discountType: false,
    discountValue: false,
    paymentMethodHash: false,
    paymentMethodLabel: false,
    paymentStatus: false,
    customer: [],
    status: 0,
    timeFormated: [],
    dateYear: null,
    dateMonth: null,
};

export const orderReducer = (state, action) => {
    switch (action.type) {
        case actionTypes.SET_CHANNEL:
            return { ...state, channel: action.payload };
        case actionTypes.SET_IS_LOADING:
            return { ...state, isLoading: action.payload };
        case actionTypes.SET_ORDER_DATA:
            return {
                id: action.payload.id,
                labelId: action.payload.labelId,
                date: action.payload.date,
                time: action.payload.time,
                channel: action.payload.channel,
                description: action.payload.description,
                numberOfPartners: action.payload.numberOfPartners,
                address: action.payload.address,
                serviceTypesLabels: action.payload.serviceTypesLabels,
                latitude: action.payload.latitude,
                longitude: action.payload.longitude,
                partnersChooseByCustomer: action.payload.partnersChooseByCustomer,
                serviceTypes: action.payload.serviceTypes,
                isFullPartners: action.payload.isFullPartners,
                isUpdated: action.payload.isUpdated,
                price: action.payload.price,
                priceFull: action.payload.priceFull,
                labelsPartner: action.payload.labelsPartner,
                labelsAdmin: action.payload.labelsAdmin,
                messages: [],
                labels: action.payload.labels,
                partnerCashName: action.payload.partnerCashName,
                cleaningTime: action.payload.cleaningTime,
                periodicity: action.payload.periodicity,
                discount: action.payload.discount,
                discountValue: action.payload.discountValue,
                discountType: action.payload.discountType,
                paymentMethodId: action.payload.paymentMethodId,
                paymentMethodLabel: action.payload.paymentMethodLabel,
                paymentStatus: action.payload.paymentStatus,
                customer: action.payload.customer,
                status: action.payload.status,
                timeFormated: action.payload.timeFormated,
                dateMonth: action.payload.dateMonth,
                dateYear: action.payload.dateYear,
                idServiceType: action.payload.idServiceType,
                dateFormated: action.payload.dateFormated,
                timeAny: action.payload.timeAny,
                timeSpecify: action.payload.timeSpecify,
                needVatInvoice: action.payload.needVatInvoice,
                addressPrice: action.payload.addressPrice,
            }
        case actionTypes.SET_PARTNER_CASH_NAME:
            return { ...state, partnerCashName: action.payload };
        case actionTypes.SET_IS_FULL_PARTNERS:
            return { ...state, isFullPartners: action.payload };
        case actionTypes.SET_SHOW_MAIN_ERROR_POPUP:
            return { ...state, showMainErrorPopup: action.payload };
        case actionTypes.SET_PARTNERS_BY_SERVICE_TYPE:
            for(var i = 0; i < state.serviceTypes.length; i++){
                if(state.serviceTypes[i].id === action.payload.serviceTypeId){
                    state.serviceTypes[i].partners = action.payload.partners;
                }
            }

            return { ...state };
        case actionTypes.SET_SERVICE_TYPES:
            return { ...state, serviceTypes: action.payload };
        case actionTypes.SET_NUMBER_OF_PARTNERS:
            return { ...state, numberOfPartners: action.payload };
        case actionTypes.SET_IS_UPDATED:
            return { ...state, isUpdated: action.payload };
        case actionTypes.SET_SHOW_LABELS_POPUP:
            return { ...state, showSetLabelsPopup: action.payload };
        case actionTypes.SET_AVAILABLE_LABELS:
            return { ...state, availableLabels: action.payload };
        case actionTypes.SET_LABELS_ADMIN:
            return { ...state, labelsAdmin: action.payload };
        case actionTypes.SET_LABELS_PARTNER:
            return { ...state, labelsPartner: action.payload };
        case actionTypes.SET_LABELS:
            return { ...state, labels: action.payload };
        case actionTypes.SET_MESSAGES:
            return { ...state, messages: action.payload };
        case actionTypes.SET_PARTNERS_CHOOSE_BY_CUSTOMER:
            return { ...state, partnersChooseByCustomer: action.payload }
        case actionTypes.SET_CLEANING_TIME:
            return { ...state, cleaningTime: action.payload }
        default:
            return state;
    }
};
