import styles from './Status.module.less';

const Status = ({ status, setPaymentStatus, active, ...props }) => {
    const handleSetStatus = async() => {
        setPaymentStatus(status.value);
    };

    return (
        <div className={`${styles.status} ${active ? styles.statusActive : '' } animate rounded `}
             onClick={handleSetStatus}>
            <span>{status.label}</span>
        </div>
    );
};

export default Status;