//import styles from './Status.module.less';
import { useOrderAddContext } from 'contexts/OrderAddContext';

const Status = ({ status, ...props }) => {
    const {
        orderAddState,
        actions: { setStatus },
    } = useOrderAddContext();

    const HandleSetStatus = async() => {
        setStatus(status.value);
    };

    return (
        <div
            onClick={HandleSetStatus}
            className={` ${status.value === orderAddState.status ? 'active' : ''} item animate rounded `}>
            {status.label}
        </div>
    );
};

export default Status;