import styles from './Date.module.less';
import { useTranslation } from "react-i18next";

const Date = ({ date, activeDate, setDate, setShowMainErrorPopup, ...props }) => {
    const { t } = useTranslation();

    const handleSetDate = async() => {
        console.log(date);

        setDate(date.date);
        setShowMainErrorPopup(false);
    };

    return (
        <div onClick={handleSetDate}
            className={`
            ${styles.date} 
            ${date.past ? styles.datePast : ''} 
            ${date.today ? styles.dateToday : ''} 
            ${date.nextMonth ? styles.dateNextMonth : ''} 
            ${activeDate === date.date ? styles.dateActive : ''}
            animate rounded
        `} >
            {date.today ? <span className="today-label">{t('Today')}</span> : ''}
            <span>{date.day}</span>
        </div>
    );
};

export default Date;