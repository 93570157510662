import { createContext, useContext, useMemo, useReducer, useEffect } from 'react';
import { initialState, serviceAddReducer } from './reducers/ServiceAddReducer';
import useServiceAddActions from 'hooks/useServiceAddActions';

const serviceAddContext = createContext({
    state: initialState,
    actions: {},
});

const useServiceAddContext = () => {
    const context = useContext(serviceAddContext);

    if (!context) {
        throw new Error(
            'serviceAddContext must be used within a ServiceAddProvider.'
        );
    }

    return context;
};

const ServiceAddProvider = ({ children }) => {
    const [serviceAddState, dispatch] = useReducer(serviceAddReducer, initialState);
    const actions = useServiceAddActions(dispatch);
    const value = useMemo(() => ({ serviceAddState, actions }), [serviceAddState, actions]);
    console.log('🚀 ~ ServiceAddProvider ~ value:', value);

    return (
        <serviceAddContext.Provider value={value}>
            {children}
        </serviceAddContext.Provider>
    );
};

export { useServiceAddContext, ServiceAddProvider };