import styles from './Sidebar.module.less';
import {Link, useLocation} from "react-router-dom";
import SvgIcon from 'components/SvgIcon';
import {Routes} from 'constants/routes';
import {useTranslation} from "react-i18next";
import {useState,useEffect} from 'react';
import { useAuthContext } from 'contexts/AuthContext';
import Location from 'components/Sidebar/Location';
import Loading from 'components/Loading';

const Sidebar = (props) => {
    const { t } = useTranslation();

    const {
        authState,
        actions: {
            setLocationData,
        },
    } = useAuthContext();

    const location = useLocation();
    const [menuActive, setMenuActive] = useState(location.pathname);
    const [showLocations, setShowLocations] = useState(false);
    const [showLoading, setShowLoading] = useState(false);

    useEffect(() => {
        var pathName = location.pathname.split('/');
        setMenuActive(pathName[1]);
    }, [location]);

    const handleLocationClick = () => {
        setShowLocations(!showLocations);
    }

    return (
        <>
            {authState.isLogged && ( <div className={styles.sidebar}>
                <div className="hello">
                    <div className="avatar">
                        <SvgIcon id="icon-customer-white" className="svg" />
                    </div>
                    <div className="text">{t('Hello')}, <span>{authState.name}!</span></div>
                </div>

                <div className={`chooseLocation rounded animate ${authState.availableLocations.length === 0 ? 'inactive' : ''}`}>
                    {showLoading && ( <Loading fixedLoader={true} /> )}
                    <div className="active-location" onClick={handleLocationClick}>
                        <SvgIcon id="icon-location" className="svg" />
                        <span>{authState.locationLabel}</span>
                        {authState.availableLocations.length > 0 && ( <SvgIcon id="icon-change" className="svg-change" /> )}
                    </div>
                    {showLocations && authState.availableLocations.length > 0 && ( <div className="available-locations shadow rounded">
                        {authState.availableLocations.map((location, i) => (
                            <Location
                                key={i}
                                location={location}
                                setLocationData={setLocationData}
                                setShowLoading={setShowLoading}
                            />
                        ))}
                    </div> )}
                </div>
                <div className="top-actions">
                    <Link to={Routes.ORDERS_ADD} className="button rounded animate middle blue full-width button-add-new-order">
                        <SvgIcon id="icon-add-white" className="svg" />
                        <span>{t('Add new order')}</span>
                    </Link>
                </div>
                <menu className="main-menu">
                    <li className="dashboard">
                        <Link to={Routes.DASHBOARD}
                              className={`top-link rounded ${menuActive === 'dashboard' ? 'active' : ''}`}>
                            <SvgIcon id="icon-dashboard" className="svg"/>
                            <span>{t('Main dashboard')}</span>
                        </Link>
                    </li>
                    <li className="orders">
                        <Link to={Routes.ORDERS}
                              className={`top-link rounded ${menuActive === 'orders' ? 'active' : ''}`}>
                            <SvgIcon id="icon-cart" className="svg"/>
                            <span>{t('Orders')}</span>
                        </Link>
                    </li>
                    <li className="calendar">
                        <Link to={Routes.CALENDAR}
                              className={`top-link rounded ${menuActive === 'calendar' ? 'active' : ''}`}>
                            <SvgIcon id="icon-calendar" className="svg"/>
                            <span>{t('Calendar')}</span>
                        </Link>
                    </li>
                    <li className="partners">
                        <Link to={Routes.PARTNERS}
                              className={`top-link rounded ${menuActive === 'partners' ? 'active' : ''}`}>
                            <SvgIcon id="icon-partner" className="svg"/>
                            <span>{t('Partners')}</span>
                        </Link>
                    </li>
                    <li className="customers">
                        <Link to={Routes.CUSTOMERS}
                              className={`top-link rounded ${menuActive === 'customers' ? 'active' : ''}`}>
                            <SvgIcon id="icon-partner" className="svg"/>
                            <span>{t('Customers')}</span>
                        </Link>
                    </li>
                    <li className="reviews">
                        <Link to={Routes.REVIEWS}
                              className={`top-link rounded ${menuActive === 'reviews' ? 'active' : ''}`}>
                            <SvgIcon id="icon-review" className="svg"/>
                            <span>{t('Reviews')}</span>
                        </Link>
                    </li>
                    <li className="promocodes">
                        <Link to={Routes.PROMOCODES}
                              className={`top-link rounded ${menuActive === 'promocodes' ? "active" : ''}`}>
                            <SvgIcon id="icon-discount" className="svg"/>
                            <span>{t('Promocodes')}</span>
                        </Link>
                    </li>
                    <li className="warehouse">
                        <Link to={Routes.WAREHOUSE}
                              className={`top-link rounded ${menuActive === 'warehouse' ? 'active' : ''}`}>
                            <SvgIcon id="icon-box" className="svg"/>
                            <span>{t('Warehouse')}</span>
                        </Link>
                    </li>
                    <li className="messages">
                        <Link to={Routes.MESSAGES}
                              className={`top-link rounded ${menuActive === 'messages' ? 'active' : ''}`}>
                            <SvgIcon id="icon-message" className="svg"/>
                            <span>{t('Messages')}</span>
                        </Link>
                    </li>
                    <li className="certificates">
                        <Link to={Routes.CERTIFICATES}
                              className={`top-link rounded ${menuActive === 'certificates' ? 'active' : ''}`}>
                            <SvgIcon id="icon-gift" className="svg"/>
                            <span>{t('Certificates')}</span>
                        </Link>
                    </li>
                </menu>
                <menu className="sub-menu">
                    <li className="analytics">
                        <Link to={Routes.ANALYTICS}
                              className={`top-link rounded ${menuActive === 'analytics' ? 'active' : ''}`}>
                            <SvgIcon id="icon-plot" className="svg"/>
                            <span>{t('Analytics')}</span>
                        </Link>
                    </li>
                    <li className="services">
                        <Link to={Routes.SERVICES} className={`top-link rounded ${menuActive === 'services' ? 'active' : '' }`}>
                            <SvgIcon id="icon-price" className="svg" />
                            <span>{t('Services')}</span>
                        </Link>
                    </li>
                    <li className="tutorials">
                        <Link to={Routes.TUTORIALS} className={`top-link rounded ${menuActive === 'tutorials' ? 'active' : '' }`}>
                            <SvgIcon id="icon-learning" className="svg" />
                            <span>{t('Tutorials')}</span>
                        </Link>
                    </li>
                    <li className="documents">
                        <Link to={Routes.DOCUMENTS} className={`top-link rounded ${menuActive === 'documents' ? 'active' : '' }`}>
                            <SvgIcon id="icon-document" className="svg" />
                            <span>{t('Documents')}</span>
                        </Link>
                    </li>
                    <li className="questions">
                        <Link to={Routes.QUESTIONS} className={`top-link rounded ${menuActive === 'questions' ? 'active' : '' }`}>
                            <SvgIcon id="icon-question" className="svg" />
                            <span>{t('Q&A')}</span>
                        </Link>
                    </li>
                    <li className="seo">
                        <Link to={Routes.SEO} className={`top-link rounded ${menuActive === 'seo' ? 'active' : '' }`}>
                            <SvgIcon id="icon-target" className="svg" />
                            <span>{t('SEO')}</span>
                        </Link>
                    </li>
                </menu>
            </div> )}
        </>
    );
};

export default Sidebar;