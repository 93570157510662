export const setSessionToken = (key, token) => {
    return localStorage.setItem(key, token);
};

export const getSessionToken = (key) => {
    return localStorage.getItem(key);
};

export const validateLogin = () => {
    return localStorage.getItem('userLogged') === "true"
        && localStorage.getItem('userToken') !== undefined;
};