//import styles from './ServiceType.module.less';
import {useTranslation} from "react-i18next";
import SvgIcon from 'components/SvgIcon';

const PriceBasedOnServices = ({ setCalculatePrice, updatePriceTime, value, ...props }) => {
    const { t } = useTranslation();

    const handleSetPrivateHouse = async() => {
        setCalculatePrice(!value);
        updatePriceTime(['price', 'time']);
    };

    return (
        <div className={`row row-check calculatePrice ${value ? 'active' : ''}`}
             onClick={handleSetPrivateHouse}>
            <div className="square animate rounded">
                <SvgIcon id="icon-check-square-white" className="svg" />
            </div>
            <span>{t('Calculate price based on selected services')}</span>
        </div>
    );
};

export default PriceBasedOnServices;