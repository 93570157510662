import styles from './SetPromocode.module.less';
import {useTranslation} from "react-i18next";
import {useId, useState} from "react";
import { useOrderAddContext } from 'contexts/OrderAddContext';
import useAjaxCall from "utils/useAjaxRequest";
import Loading from 'components/Loading';
import Promocode from 'components/OrderAdd/SetPromocode/Promocode';
import ActivePromocode from 'components/OrderAdd/SetPromocode/ActivePromocode';

const SetPromocode = ({ serviceType, ...props }) => {
    const {
        orderAddState,
        actions: {
            setPromocodes,
            setShowMainError,
            setPromocode,
        },
    } = useOrderAddContext();

    const { t } = useTranslation();
    const { makeAjaxCall } = useAjaxCall();
    const inputSearchId = useId();
    const [inputSearch, setInputSearch] = useState(props?.value ?? "");
    const [loading, setLoading] = useState(false);

    const handleSearchInputChange = async(event) => {
        setInputSearch(event.target.value);
        
        if(event.target.value.length >= 3){
            setLoading(true);

            var ajaxResponse = await makeAjaxCall('/promocodes/search', {
                search: event.target.value,
            }, true);

            if (ajaxResponse && ajaxResponse.result) {
                setPromocodes(ajaxResponse.data);
            }else{
                setShowMainError(false);
            }

            setLoading(false);
        }
    }

    return (
        <div className={"row " + styles.rowSetPromocode}>
            <div className="row-label">{t('Promocode')}</div>
            <div className="row-content">
                <div className="search-promocode">
                    {loading ? <Loading /> : ''}

                    <input
                        className="rounded"
                        id={inputSearchId}
                        onChange={handleSearchInputChange}
                        placeholder={t('Start typing in the promo code to search...')}
                    />

                    {orderAddState.promocode && ( <ActivePromocode /> )}
                </div>
                <div className="available-promocodes">
                    {orderAddState.promocodes.map((promocode, i) => (
                        <Promocode
                            promocode={promocode}
                            key={i}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default SetPromocode;