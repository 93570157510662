export const actionTypes = {
    SET_ORDER_DATA: 'SET_ORDER_DATA',
    SET_CHANNEL: 'SET_CHANNEL',
    SET_IS_LOADING: 'SET_IS_LOADING',
    SET_IS_FULL_PARTNERS: 'SET_IS_FULL_PARTNERS',
    SET_SHOW_MAIN_ERROR_POPUP: 'SET_SHOW_MAIN_ERROR_POPUP',
    SET_PARTNERS_BY_SERVICE_TYPE: 'SET_PARTNERS_BY_SERVICE_TYPE',
    SET_SERVICE_TYPES: 'SET_SERVICE_TYPES',
    SET_IS_UPDATED: 'SET_IS_UPDATED',
    SET_SHOW_LABELS_POPUP: 'SET_SHOW_LABELS_POPUP',
    SET_AVAILABLE_LABELS: 'SET_AVAILABLE_LABELS',
    SET_LABELS_ADMIN: 'SET_LABELS_ADMIN',
    SET_LABELS_PARTNER: 'SET_LABELS_PARTNER',
    SET_MESSAGES: 'SET_MESSAGES',
    SET_LABELS: 'SET_LABELS',
    SET_PARTNER_CASH_NAME: 'SET_PARTNER_CASH_NAME',
    SET_PARTNERS_CHOOSE_BY_CUSTOMER: 'SET_PARTNERS_CHOOSE_BY_CUSTOMER',
    SET_CLEANING_TIME: 'SET_CLEANING_TIME',
    SET_NUMBER_OF_PARTNERS: 'SET_NUMBER_OF_PARTNERS',
};