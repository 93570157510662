import styles from './Map.module.less';
import { Map } from '@vis.gl/react-google-maps';
import {useEffect, useId, useState} from 'react';

const CustomMap = ({ authState, orderAddState, children }) => {

    var mapId = useId();

    return (
        <div className={styles.mapContainer + " rounded"}>
            <Map
                mapId={mapId}
                center={{
                    lat: orderAddState.address.latitude ? orderAddState.address.latitude : parseFloat(authState.mapCenterLatitude),
                    lng: orderAddState.address.longitude ? orderAddState.address.longitude : parseFloat(authState.mapCenterLongitude),
                }}
                defaultZoom={parseInt(authState.mapZoom)}
                zoom={orderAddState.address.longitude ? 15 : parseInt(authState.mapZoom)}
                gestureHandling={'greedy'}
                disableDefaultUI={true}>
                {children}
            </Map>
        </div>
    );
};

export default CustomMap;