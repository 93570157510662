import styles from './Promocode.module.less';
import { useOrderAddContext } from 'contexts/OrderAddContext';

const Promocode = ({ promocode, ...props}) => {

    const {
        orderAddState,
        actions: {
            setPromocode,
            updatePriceTime,
        },
    } = useOrderAddContext();

    const handleSetPromocode = () => {
        setPromocode(promocode);
        updatePriceTime(['price', 'time']);
    };

    return (
        <div className={styles.promocode + " rounded animate"} onClick={handleSetPromocode}>
            <span>{promocode.code}</span>
            <span className="value rounded">-{promocode.value}{promocode.type === 'percent' ? '%' : ''}</span>
        </div>
    );
};

export default Promocode;