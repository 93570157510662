import styles from './AdditionalInfo.module.less';
import { useTranslation } from "react-i18next";
import { useId } from 'react';
import { useOrderAddContext } from 'contexts/OrderAddContext';
import SvgIcon from "components/SvgIcon";

const AdditionalInfo = () => {
    const { t } = useTranslation();

    var textareaId = useId();

    const {
        orderAddState,
        actions: {
            setAdditionalInfo,
        },
    } = useOrderAddContext();

    const handleInfoChange = (event) => {
        setAdditionalInfo(event.target.value);
    };

    return (
        <div className={`row ${styles.rowAdditionalInfo}`} >
            <div className="row-label">
                <span>{t('Additional order information')}</span>
            </div>
            <div className="row-content">
                <textarea
                    className="rounded"
                    id={textareaId}
                    onBlur={handleInfoChange}
                />
            </div>
        </div>
    );
};

export default AdditionalInfo;