import styles from './ServiceType.module.less';
import { memo, forwardRef } from 'react';

const ServiceType = memo(
    forwardRef(({ serviceType, activeServiceType, setActiveServiceType, setActiveServiceTypeKey, index, ...props }, ref) => {

        const handleServiceTypeClick = (event) => {
            setActiveServiceType(serviceType.id);
            setActiveServiceTypeKey(index);
        }

        return (
            <div
                ref={ref}
                onClick={handleServiceTypeClick}
                className={`button grey mini rounded animate button-service-type ${activeServiceType === serviceType.id ? 'active' : ''}`}>
                <img alt={serviceType.label ? serviceType.label : ''} src={serviceType.icon}/>
                <span>{serviceType.label}</span>
            </div>
        );
    })
)

export default ServiceType;