import styles from './Loading.module.less';

const Loading = ({ fixed, fixedLoader, ...props }) => {
    return (
        <div className={`${styles.ajaxLoader} 
        ${fixed ? styles.ajaxLoaderFixedSpinner : '' } 
        ${fixedLoader ? styles.ajaxLoaderFixed : ''}`}>
            <div className="spinner">
                <div className="bounce1"></div>
                <div className="bounce2"></div>
                <div className="bounce3"></div>
            </div>
        </div>
    );
};

export default Loading;