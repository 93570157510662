import { memo, forwardRef } from 'react';
import SvgIcon from 'components/SvgIcon';

const ServiceType = memo(
    forwardRef(({ serviceType, activeServiceType, hasDiscount, setActiveServiceType, ...props }, ref) => {

        const handleServiceTypeClick = () => {
            setActiveServiceType(serviceType.id);
        }

        return (
            <div ref={ref}
                onClick={handleServiceTypeClick}
                className={`button animate rounded ${activeServiceType === serviceType.id ? 'active' : ''} ${hasDiscount ? 'hasDiscount' : ''}`}>
                <img alt={serviceType.label ? serviceType.label : ''} src={serviceType.icon}/>
                <span>{serviceType.label}</span>
                {hasDiscount && (
                    <SvgIcon id="icon-discount-red" className="discountIcon" />
                )}
            </div>
        );
    })
)

export default ServiceType;