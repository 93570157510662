import styles from './Service.module.less';
import Dinero from 'dinero.js';
import { useOrderAddContext } from 'contexts/OrderAddContext';
import { useAuthContext } from 'contexts/AuthContext';
import {useTranslation} from "react-i18next";
import {useState, useEffect} from 'react';

const Service = ({ service, idServiceType, ...props }) => {
    const {
        orderAddState,
        actions: {
            setService,
            updatePriceTime,
            setError,
        },
    } = useOrderAddContext();

    const {
        authState,
        actions: {},
    } = useAuthContext();

    const {t} = useTranslation();

    const [value, setValue] = useState(0);
    const [active, setActive] = useState(false);
    const [price, setPrice] = useState(new Dinero({
        amount: service.price,
        currency: authState.currencyCode,
        precision: authState.currencyPrecision,
    }));

    useEffect(() => {
        let existingService = orderAddState.services.find((serviceInArray) => serviceInArray.id === service.id);
        if(existingService){
            setActive(true);
            setValue(existingService.value);
        }
    }, []);

    const handleInputBlur = (event) => {
        var inputValue = event.target.value;

        if(inputValue === ''){
            setActive(false);
            setValue(0);
        }
    }

    const handleInputChange = (event) => {
        var inputValue = event.target.value;

        if(inputValue === ''){
            setActive(false);
            setValue('');

            setService({
                id: service.id,
                value: 0,
            });

            updatePriceTime(['price', 'time']);
        }else{
            if (/^\d*$/.test(inputValue)) {
                if(inputValue > 0){
                    setActive(true);
                    setValue(event.target.value);
                    setService({
                        id: service.id,
                        value: inputValue,
                        idServiceType: idServiceType,
                        price: service.price,
                        time: service.time,
                        base: service.base,
                    });

                    updatePriceTime(['price', 'time']);
                    setError({
                        services: false,
                    });
                }else{
                    setActive(false);
                    setValue(0);
                }
            }
        }
    }

    const handleServiceChoose = (event) => {
        if(event.target.classList.contains('plus')
            || event.target.classList.contains('minus')
            || event.target.closest('.int')
        ){
            return;
        }

        if(active === true){
            setActive(false);
            setValue(0);

            setService({
                id: service.id,
                value: 0,
            });

            updatePriceTime(['price', 'time']);
        }else{
            setActive(true);

            if(value === 0){
                setValue(1);
            }

            setService({
                id: service.id,
                value: 1,
                idServiceType: idServiceType,
                price: service.price,
                time: service.time,
                base: service.base,
            });

            updatePriceTime(['price', 'time']);
            setError({
                services: false,
            });
        }
    };

    const handlePlusClick = () => {
        if(!active){
            setActive(true);
        }

        var newValue = value + 1;

        setValue(newValue);
        setService({
            id: service.id,
            value: newValue,
            idServiceType: idServiceType,
            price: service.price,
            time: service.time,
            base: service.base,
        });

        updatePriceTime(['price', 'time']);
        setError({
            services: false,
        });
    };

    const handleMinusClick = () => {
        var newValue = value - 1;

        console.log(newValue);

        setValue(newValue);
        if(newValue < 0){
            return;
        }

        if(newValue === 0){
            setActive(false);
        }

        setService({
            id: service.id,
            value: newValue,
            idServiceType: idServiceType,
            price: service.price,
            time: service.time,
            base: service.base,
        });

        updatePriceTime(['price', 'time']);
        setError({
            services: false,
        });
    };

    return (
        <div className={`${styles.service} animate ${active ? styles.serviceActive : ''}`}
             onClick={handleServiceChoose}>
            <img src={service.icon} alt={service.name} />
            <div className="title">
                {service.name}
                <span>( {t(service.unit)} )</span>
            </div>
            <div className="price rounded">{price.toFormat('$0.00')}</div>
            { service.max > 1 && (<div className="value rounded">
                <div className="minus animate" onClick={handleMinusClick}>-</div>
                <div className="int">
                    <input
                        type="int"
                        min="0"
                        max={service.max}
                        value={value}
                        onChange={handleInputChange}
                        onBlur={handleInputBlur}
                    />
                </div>
                <div className="plus animate" onClick={handlePlusClick}>+</div>
            </div>)}
        </div>
    );
};

export default Service;