import SvgIcon from 'components/SvgIcon';

const ServiceType = ({ serviceType, activeServiceType, hasClosedTime, setActiveServiceType, ...props}) => {

    const handleServiceTypeClick = () => {
        setActiveServiceType(serviceType.id);
    }

    return (
        <div onClick={handleServiceTypeClick}
             className={`button animate rounded ${activeServiceType === serviceType.id ? 'active' : ''} ${hasClosedTime ? 'hasClosedTime' : ''}`}>
            <img alt={serviceType.label ? serviceType.label : ''} src={serviceType.icon}/>
            <span>{serviceType.label}</span>
            {hasClosedTime && (
                <SvgIcon id="icon-forbidden" className="closedTimeIcon"/>
            )}
        </div>
    );
}

export default ServiceType;