import styles from './ServiceType.module.less';
import {useEffect, useState} from 'react';
import SvgIcon from 'components/SvgIcon';
import Service from 'components/OrderAdd/ServiceType/Service';
import { useOrderAddContext } from 'contexts/OrderAddContext';

const ServiceType = ({ serviceType, ...props }) => {
    const [showServices, setShowServices] = useState(false);
    const [serviceTypeActive, setServiceTypeActive] = useState(false);

    const HandleShowServices = async() => {
        setShowServices(!showServices);
    };

    const {
        orderAddState,
        actions: {
            setService,
            isActiveService,
        },
    } = useOrderAddContext();

    useEffect(() => {
        let existingService = orderAddState.services.find((serviceInArray) => serviceInArray.idServiceType === serviceType.id);
        setShowServices(existingService !== undefined);
    }, [orderAddState.id]);

    useEffect(() => {
        let existingService = orderAddState.services.find((serviceInArray) => serviceInArray.idServiceType === serviceType.id);
        setServiceTypeActive(existingService !== undefined);
    }, [orderAddState.services]);

    return (
        <div className={styles.serviceTypesItem + " rounded"}>
            <div className={`service-types-item-header rounded animate
                    ${showServices ? 'open' : ''}
                    ${serviceTypeActive ? 'active' : ''} `}
                 onClick={HandleShowServices}>
                <div className="label">
                    <img src={serviceType.icon} alt={serviceType.label ? serviceType.label : ''} />
                    <span>{serviceType.label}</span>
                </div>
                <div className="label-arrow">
                    <SvgIcon id="icon-arrow-down" className="svg animate" />
                </div>
            </div>

            {showServices ? <div className="service-types-item-content">
                {serviceType.services.map((service, i) => (
                    <Service
                        service={service}
                        idServiceType={serviceType.id}
                        key={i}
                    />
                ))}
            </div>: ''}
        </div>
    );
};

export default ServiceType;