import { actionAuthTypes } from 'constants/actionAuthTypes';

export const initialState = {
    id: localStorage.getItem('authId'),
    name: localStorage.getItem('authName'),
    token: localStorage.getItem('authToken'),
    email: localStorage.getItem('authEmail'),
    isLogged: localStorage.getItem('authIsLogged'),
    language: localStorage.getItem('authLanguage'),
    location: localStorage.getItem('authLocation'),
    locationLabel: localStorage.getItem('authLocationLabel'),
    paymentMethods: JSON.parse(localStorage.getItem('paymentMethods')),
    mapCenterLatitude: localStorage.getItem('mapCenterLatitude'),
    mapCenterLongitude: localStorage.getItem('mapCenterLongitude'),
    mapZoom: localStorage.getItem('mapZoom'),
    baseLanguage: localStorage.getItem('baseLanguage'),
    availableLanguages: JSON.parse(localStorage.getItem('availableLanguages')),
    smsCountriesCustomer: JSON.parse(localStorage.getItem('smsCountriesCustomer')),
    smsBaseCountryCustomer: localStorage.getItem('smsBaseCountryCustomer'),
    currencyCode: localStorage.getItem('currencyCode'),
    currencyPrecision: parseInt(localStorage.getItem('currencyPrecision')),
    onceMonthDiscount: parseInt(localStorage.getItem('onceMonthDiscount')),
    twiceMonthDiscount: parseInt(localStorage.getItem('twiceMonthDiscount')),
    onceWeekDiscount: parseInt(localStorage.getItem('onceWeekDiscount')),
    privateHouseCoefficient: parseFloat(localStorage.getItem('privateHouseCoefficient')),
    servicesByType: JSON.parse(localStorage.getItem('servicesByType')),
    postCodeMask: localStorage.getItem('postCodeMask'),
    availableLocations: JSON.parse(localStorage.getItem('availableLocations')),
    vatPercentage: localStorage.getItem('vatPercentage'),
    baseLocationName: localStorage.getItem('baseLocationName'),
    pricePerMinute: parseInt(localStorage.getItem('pricePerMinute')),
    polygons: JSON.parse(localStorage.getItem('polygons')),
};

export const authReducer = (state, action) => {
    switch (action.type) {
        case actionAuthTypes.SET_AUTH_DATA:
            localStorage.setItem("authId", action.payload.id);
            localStorage.setItem("authName", action.payload.name);
            localStorage.setItem("authToken", action.payload.token);
            localStorage.setItem("authEmail", action.payload.email);
            localStorage.setItem("authLanguage", action.payload.language);
            localStorage.setItem("authLocation", action.payload.location);
            localStorage.setItem("authLocationLabel", action.payload.locationLabel);
            localStorage.setItem("authIsLogged", action.payload.isLogged);
            localStorage.setItem("paymentMethods", JSON.stringify(action.payload.paymentMethods));
            localStorage.setItem("mapCenterLatitude", action.payload.mapCenterLatitude);
            localStorage.setItem("mapCenterLongitude", action.payload.mapCenterLongitude);
            localStorage.setItem("mapZoom", action.payload.mapZoom);
            localStorage.setItem("baseLanguage", action.payload.baseLanguage);
            localStorage.setItem("availableLanguages", JSON.stringify(action.payload.availableLanguages));
            localStorage.setItem("smsCountriesCustomer", JSON.stringify(action.payload.smsCountriesCustomer));
            localStorage.setItem("smsBaseCountryCustomer", action.payload.smsBaseCountryCustomer);
            localStorage.setItem("currencyCode", action.payload.currencyCode);
            localStorage.setItem("currencyPrecision", action.payload.currencyPrecision);
            localStorage.setItem("onceMonthDiscount", action.payload.onceMonthDiscount);
            localStorage.setItem("twiceMonthDiscount", action.payload.twiceMonthDiscount);
            localStorage.setItem("onceWeekDiscount", action.payload.onceWeekDiscount);
            localStorage.setItem("privateHouseCoefficient", action.payload.privateHouseCoefficient);
            localStorage.setItem("servicesByType", JSON.stringify(action.payload.servicesByType));
            localStorage.setItem("postCodeMask", action.payload.postCodeMask);
            localStorage.setItem("availableLocations", JSON.stringify(action.payload.availableLocations));
            localStorage.setItem("vatPercentage", action.payload.vatPercentage);
            localStorage.setItem("baseLocationName", action.payload.baseLocationName);
            localStorage.setItem("pricePerMinute", action.payload.pricePerMinute);
            localStorage.setItem("polygons", JSON.stringify(action.payload.polygons));
            
            return {
                id: action.payload.id,
                name: action.payload.name,
                token: action.payload.token,
                email: action.payload.email,
                isLogged: action.payload.isLogged,
                language: action.payload.language,
                location: action.payload.location,
                locationLabel: action.payload.locationLabel,
                paymentMethods: action.payload.paymentMethods,
                mapCenterLatitude: action.payload.mapCenterLatitude,
                mapCenterLongitude: action.payload.mapCenterLongitude,
                mapZoom: action.payload.mapZoom,
                baseLanguage: action.payload.baseLanguage,
                availableLanguages: action.payload.availableLanguages,
                smsCountriesCustomer: action.payload.smsCountriesCustomer,
                smsBaseCountryCustomer: action.payload.smsBaseCountryCustomer,
                currencyCode: action.payload.currencyCode,
                currencyPrecision: parseInt(action.payload.currencyPrecision),
                onceMonthDiscount: parseInt(action.payload.onceMonthDiscount),
                twiceMonthDiscount: parseInt(action.payload.twiceMonthDiscount),
                onceWeekDiscount: parseInt(action.payload.onceWeekDiscount),
                privateHouseCoefficient: parseFloat(action.payload.privateHouseCoefficient),
                servicesByType: action.payload.servicesByType,
                postcodeMask: action.payload.postCodeMask,
                availableLocations: action.payload.availableLocations,
                vatPercentage: action.payload.vatPercentage,
                baseLocationName: action.payload.baseLocationName,
                pricePerMinute: action.payload.pricePerMinute,
                polygons: action.payload.polygons,
            }
        case actionAuthTypes.SET_LOCATION_DATA:
            localStorage.setItem("authToken", action.payload.token);
            localStorage.setItem("authLocation", action.payload.location);
            localStorage.setItem("authLocationLabel", action.payload.locationLabel);
            localStorage.setItem("paymentMethods", JSON.stringify(action.payload.paymentMethods));
            localStorage.setItem("mapCenterLatitude", action.payload.mapCenterLatitude);
            localStorage.setItem("mapCenterLongitude", action.payload.mapCenterLongitude);
            localStorage.setItem("mapZoom", action.payload.mapZoom);
            localStorage.setItem("baseLanguage", action.payload.baseLanguage);
            localStorage.setItem("availableLanguages", JSON.stringify(action.payload.availableLanguages));
            localStorage.setItem("smsCountriesCustomer", JSON.stringify(action.payload.smsCountriesCustomer));
            localStorage.setItem("smsBaseCountryCustomer", action.payload.smsBaseCountryCustomer);
            localStorage.setItem("currencyCode", action.payload.currencyCode);
            localStorage.setItem("currencyPrecision", action.payload.currencyPrecision);
            localStorage.setItem("onceMonthDiscount", action.payload.onceMonthDiscount);
            localStorage.setItem("twiceMonthDiscount", action.payload.twiceMonthDiscount);
            localStorage.setItem("onceWeekDiscount", action.payload.onceWeekDiscount);
            localStorage.setItem("privateHouseCoefficient", action.payload.privateHouseCoefficient);
            localStorage.setItem("servicesByType", JSON.stringify(action.payload.servicesByType));
            localStorage.setItem("postCodeMask", action.payload.postCodeMask);
            localStorage.setItem("availableLocations", JSON.stringify(action.payload.availableLocations));
            localStorage.setItem("vatPercentage", action.payload.vatPercentage);
            localStorage.setItem("baseLocationName", action.payload.baseLocationName);
            localStorage.setItem("pricePerMinute", action.payload.pricePerMinute);
            localStorage.setItem("polygons", JSON.stringify(action.payload.polygons));

            //{id: 'f71612ee3a479d391cdb84553a8dc69a', label: 'Варшава'}
            //{id: 'e07e5ab5f2aeac829bfe0d302ed1c2c7', label: 'Краков'}
            //{id: '819381f58468515e60b9706fb75b0a01', label: 'Нью-Йорк'}

            return {
                ...state,
                token: action.payload.token,
                location: action.payload.location,
                locationLabel: action.payload.locationLabel,
                paymentMethods: action.payload.paymentMethods,
                mapCenterLatitude: action.payload.mapCenterLatitude,
                mapCenterLongitude: action.payload.mapCenterLongitude,
                mapZoom: action.payload.mapZoom,
                baseLanguage: action.payload.baseLanguage,
                availableLanguages: action.payload.availableLanguages,
                smsCountriesCustomer: action.payload.smsCountriesCustomer,
                smsBaseCountryCustomer: action.payload.smsBaseCountryCustomer,
                currencyCode: action.payload.currencyCode,
                currencyPrecision: parseInt(action.payload.currencyPrecision),
                onceMonthDiscount: parseInt(action.payload.onceMonthDiscount),
                twiceMonthDiscount: parseInt(action.payload.twiceMonthDiscount),
                onceWeekDiscount: parseInt(action.payload.onceWeekDiscount),
                privateHouseCoefficient: parseFloat(action.payload.privateHouseCoefficient),
                servicesByType: action.payload.servicesByType,
                postcodeMask: action.payload.postCodeMask,
                availableLocations: action.payload.availableLocations,
                vatPercentage: action.payload.vatPercentage,
                baseLocationName: action.payload.baseLocationName,
                pricePerMinute: action.payload.pricePerMinute,
                polygons: action.payload.polygons,
            }
        case actionAuthTypes.SET_SERVICES_BY_TYPE:
            localStorage.setItem("servicesByType", JSON.stringify(action.payload));

            return { ...state, servicesByType: action.payload };
        case actionAuthTypes.CLEAR_AUTH_DATA:
            localStorage.clear();

            return {
                id: false,
                name: false,
                token: false,
                email: false,
                isLogged: false,
                language: false,
                location: false,
                paymentMethods: false,
            }
        default:
            return state;
    }
};
