import styles from './Method.module.less';
import { useOrderAddContext } from 'contexts/OrderAddContext';

const Method = ({ paymentMethod, count, ...props }) => {
    const {
        orderAddState,
        actions: { setPaymentMethod },
    } = useOrderAddContext();

    const HandleSetMethod = async() => {
        setPaymentMethod(paymentMethod.id);
    };

    return (
        <div className={`${styles.method} ${orderAddState.paymentMethod === paymentMethod.id ? styles.methodActive : '' } animate rounded`}
             onClick={HandleSetMethod}>
            <img src={paymentMethod.icon} alt={paymentMethod.label} />
            <span>{paymentMethod.label}</span>
        </div>
    );
};

export default Method;