import styles from './DateDiscount.module.less';
import { useTranslation } from "react-i18next";
import { useState } from 'react';
import { useAuthContext } from 'contexts/AuthContext';
import ServiceType from 'components/OrderAdd/DateDiscount/ServiceType';
import Percent from 'components/OrderAdd/DateDiscount/Percent';

var discountsAvailable = [
    0, 3, 5, 7, 10, 13, 15, 18, 20, 22, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100,
];

const DateDiscount = () => {
    const {
        authState,
        actions: {  },
    } = useAuthContext();

    const { t } = useTranslation();

    return (
        <div className={"row " + styles.rowDateDiscount}>
            <div className="row-label">{t('Date discount')}</div>
            <div className="row-content">
                <div className="row-info">*{t('The discount is credited only for one type of service')}</div>
                <div className="row-sub-label">{t('Select the type of service for which to charge a discount')}</div>
                <div className="service-types">
                    {authState.servicesByType.map((serviceType, i) => (
                        <ServiceType
                            serviceType={serviceType}
                            key={i}
                        />
                    ))}
                </div>
                <div className="row-sub-label">{t('Select the size of the discount')}</div>
                <div className="percentage">
                    {discountsAvailable.map((percent, i) => (
                        <Percent
                            percent={percent}
                            key={i}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default DateDiscount;