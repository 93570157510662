import styles from './SetDate.module.less';
import { useTranslation } from "react-i18next";
import { forwardRef } from "react";
import { useOrderAddContext } from 'contexts/OrderAddContext';
import CalendarData from 'components/OrderAdd/SetDate/CalendarData';
import ErrorText from 'components/ErrorText';

const SetDate = forwardRef(({authState, ...props}, ref) => {
    const {
        orderAddState,
        actions: { },
    } = useOrderAddContext();

    const { t } = useTranslation();

    return (
        <div ref={ref}
             className={`${styles.chooseDate} row row-date ${orderAddState.errors.date ? 'showError' : ''}`} >
            <div className="row-label">
                <span>{t('Choose date')}</span>
                <span className="require">*{t('Required')}</span>
            </div>
            {orderAddState.errors.date && ( <ErrorText text={t('Select the date of the order')} /> )}
            <div className="row-content">
                {orderAddState.calendarData ? <CalendarData authState={authState} /> : ''}
            </div>
        </div>
    );
});

export default SetDate;