export const actionOrderAddTypes = {
    SET_SERVICES: 'SET_SERVICES',
    SET_AVAILABLE_SERVICES: 'SET_AVAILABLE_SERVICES',
    SET_LANGUAGE: 'SET_LANGUAGE',
    SET_STATUS: 'SET_STATUS',
    SET_PRIVATE_HOUSE: 'SET_PRIVATE_HOUSE',
    SET_LANGUAGES: 'SET_LANGUAGES',
    SET_TIME_HOUR: 'SET_TIME_HOUR',
    SET_TIME_MINUTE: 'SET_TIME_MINUTE',
    SET_TIME_ANY: 'SET_TIME_ANY',
    SET_TIME_SPECIFY: 'SET_TIME_SPECIFY',
    SET_CALENDAR_DATA: 'SET_CALENDAR_DATA',
    SET_DATE: 'SET_DATE',
    SET_DATE_DISCOUNT_TYPE: 'SET_DATE_DISCOUNT_TYPE',
    SET_DATE_DISCOUNT_VALUE: 'SET_DATE_DISCOUNT_VALUE',
    SET_DATE_TOKEN: 'SET_DATE_TOKEN',
    SET_PROMOCODES: 'SET_PROMOCODES',
    SET_SHOW_MAIN_ERROR: 'SET_SHOW_MAIN_ERROR',
    SET_PERIODICITY: 'SET_PERIODICITY',
    SET_PAYMENT_METHOD: 'SET_PAYMENT_METHOD',
    SET_PAYMENT_STATUS: 'SET_PAYMENT_STATUS',
    SET_CUSTOMER_DATA: 'SET_CUSTOMER_DATA',
    SET_AVAILABLE_CUSTOMERS: 'SET_AVAILABLE_CUSTOMERS',
    SET_LOCATION: 'SET_LOCATION',
    SET_SERVICE: 'SET_SERVICE',
    SET_CALCULATE_PRICE: 'SET_CALCULATE_PRICE',
    SET_PROMOCODE: 'SET_PROMOCODE',
    SET_PROMOCODE_SERVICE_TYPE: 'SET_PROMOCODE_SERVICE_TYPE',
    SET_PRICE: 'SET_PRICE',
    SET_FULL_PRICE: 'SET_FULL_PRICE',
    UPDATE_PRICE_TIME: 'UPDATE_PRICE_TIME',
    SET_IS_LOADING: 'SET_IS_LOADING',
    SET_ERROR: 'SET_ERROR',
    SET_ADDRESS_DATA: 'SET_ADDRESS_DATA',
    CLEAR_STATE: 'CLEAR_STATE',
    SET_ADDITIONAL_INFO: 'SET_ADDITIONAL_INFO',
    SET_USE_CUSTOMER_DATA_FOR_INVOICE: 'SET_USE_CUSTOMER_DATA_FOR_INVOICE',
    SET_NEED_VAT_INVOICE: 'SET_NEED_VAT_INVOICE',
    SET_ACTIVE_CARD: 'SET_ACTIVE_CARD',
    SET_ORDER_DATA: 'SET_ORDER_DATA',
    SET_INVOICE_DATA: 'SET_INVOICE_DATA',
    SET_CUSTOMER_DATA_FOR_INVOICE: 'SET_CUSTOMER_DATA_FOR_INVOICE',
};