import styles from './ServiceType.module.less';
import {useTranslation} from "react-i18next";
import {useId, useState} from "react";
import { useOrderAddContext } from 'contexts/OrderAddContext';

const ServiceType = ({ serviceType, ...props }) => {
    const {
        orderAddState,
        actions: {
            setPromocodeServiceType,
            updatePriceTime,
        },
    } = useOrderAddContext();

    const { t } = useTranslation();

    const handleSetPromocodeServiceType = () => {
        setPromocodeServiceType(serviceType.id);
        updatePriceTime(['price', 'time']);
    }

    return (
        <div onClick={handleSetPromocodeServiceType}
            className={`${styles.serviceType} animate rounded ${orderAddState.promocodeServiceType === serviceType.id ? styles.serviceTypeActive : ''}`}>
            <img src={serviceType.icon} alt={serviceType.label} />
            <span>{serviceType.label}</span>
        </div>
    );
};

export default ServiceType;