//import styles from './CustomerData.module.less';
import { useTranslation } from "react-i18next";
import { useOrderAddContext } from 'contexts/OrderAddContext';
import {useEffect, useId, useState} from "react";
import * as events from "events";

const Name = ({ ...props }) => {
    const {
        orderAddState,
        actions: {
            setCustomerData,
        },
    } = useOrderAddContext();

    const { t } = useTranslation();

    const inputNameId = useId();

    const HandleNameChange = (event) => {
        setCustomerData({
            name: event.target.value,
        });
    }

    return (
        <div className="item name">
            <label htmlFor={inputNameId}>{t('Name')}</label>
            <input
                id={inputNameId}
                className="rounded"
                onChange={HandleNameChange}
                value={orderAddState.customer.name}
            />
        </div>
    );
};

export default Name;