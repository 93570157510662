import styles from './SetDate.module.less';
import SvgIcon from 'components/SvgIcon';
import {useState} from "react";
import CalendarData from 'components/Order/SetDate/CalendarData';
import Loading from 'components/Loading';

const SetDate = ({ state, makeAjaxCall, setOrderData, setShowMainErrorPopup, setIsLoading, ...props }) => {
    const [showContainer, setShowContainer] = useState(false);
    const [month, setMonth] = useState(state.dateMonth);
    const [year, setYear] = useState(state.dateYear);
    const [calendarData, setCalendarData] = useState(null);

    const handleSetDateClick = async() => {
        setIsLoading(true);

        if(!showContainer){
            // get calendar data
            var ajaxResponse = await makeAjaxCall('/order/edit/calendar/month', {
                type: state.idServiceType,
                month: month,
                year: year,
            }, true);

            if (ajaxResponse && ajaxResponse.result) {
                setCalendarData(ajaxResponse.data);
            }else{
                setShowMainErrorPopup(true);
            }
        }

        setShowContainer(!showContainer);
        setIsLoading(false);
    }

    const setDate = async(date) => {
        setIsLoading(true);

        // get calendar data
        var ajaxResponse = await makeAjaxCall('/order/date/set', {
            id: state.id,
            date: date,
        }, true);

        if (ajaxResponse && ajaxResponse.result) {
            setOrderData(ajaxResponse.data);
        }else{
            setShowMainErrorPopup(true);
        }

        setShowContainer(!showContainer);
        setIsLoading(false);
    }

    return (
        <div className={styles.setDate}>
            <div className="button middle date shadow rounded animate" onClick={handleSetDateClick}>
                <SvgIcon id="icon-date" className="icon"/>
                <span>{state.date}</span>
            </div>
            {showContainer && <div className={`${styles.setDateContainer} rounded animate shadow`}>
                {calendarData && (
                    <CalendarData
                        activeDate={state.dateFormated}
                        data={calendarData}
                        setIsLoading={setIsLoading}
                        setCalendarData={setCalendarData}
                        setShowMainErrorPopup={setShowMainErrorPopup}
                        type={state.idServiceType}
                        makeAjaxCall={makeAjaxCall}
                        setDate={setDate}
                    />
                )}
            </div>}
        </div>
    );
}

export default SetDate;