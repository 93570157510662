import styles from './MainError.module.less';
import SvgIcon from "components/SvgIcon";
import {useTranslation} from "react-i18next";
import {forwardRef, memo} from "react";

const MainError = memo(
    forwardRef(({ label, inputClass, required, containerClass, hideContent, errorText, showError, setShowError, maxLength, ...props }, ref) => {

        const {t} = useTranslation();

        const HandleRefresh = async() => {
            window.location.reload(false);
        };

        return (
            <div ref={ref}
                 className={styles.mainError + " rounded"}>
                <SvgIcon id="icon-error" className="svg-error" />
                <span>{t('A problem occurred while loading the data')}</span>
                <div onClick={HandleRefresh} className="reload animate rounded">{t('Refresh the page')}</div>
            </div>
        );
    })
)

export default MainError;