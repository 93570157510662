import styles from './OrderId.module.less';
import {useTranslation} from "react-i18next";
import { useState } from 'react';
import SvgIcon from 'components/SvgIcon';

const OrderLabelId = ({ id, className, ...props }) => {
    const {t} = useTranslation();
    const [isCopied, setIsCopied] = useState(false);

    const HandleCopyId = () => {
        navigator.clipboard.writeText(id);
        setIsCopied(true);
    }
    
    return (
        <div className={`${styles.id} animate ${className ? className : ''}`} onClick={HandleCopyId}>
            <div className="label">{t('Order ID')}:</div>
            <div className="value">{id}</div>
            {isCopied ? <SvgIcon id="icon-check-white" className="icon"/> : <SvgIcon id="icon-copy-white" className="icon"/>}
        </div>
    );
}

export default OrderLabelId;