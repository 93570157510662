import styles from './ChoosePartner.module.less';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useOrderContext } from 'contexts/OrderContext';
import { getLink } from 'utils/links';
import useAjaxCall from "utils/useAjaxRequest";
import SvgIcon from 'components/SvgIcon';
import { AjaxRoutes } from 'constants/ajaxRoutes';

const ChoosePartner = () => {
    const {
        state,
        actions: {
            setIsLoading,
            setShowMainErrorPopup,
            setServiceTypes,
            setIsFullPartners,
            setPartnersChooseByCustomer,
        },
    } = useOrderContext();

    const {t} = useTranslation();
    const { makeAjaxCall } = useAjaxCall();

    const HandleRemovePartner = async() => {
        // show loading
        setIsLoading(true);

        var ajaxResponse = await makeAjaxCall(AjaxRoutes.ORDER_PARTNER_CHOOSE_REMOVE,{
            id: state.id,
            idPartner: state.partnersChooseByCustomer.id,
            serviceTypeId: state.partnersChooseByCustomer.serviceTypeId,
        }, true);

        if (ajaxResponse && ajaxResponse.result) {
            // TODO update only partners for one type, not all types
            // update service types
            setServiceTypes(ajaxResponse.data.serviceTypes);

            // set full partners
            setIsFullPartners(ajaxResponse.data.isFullPartners);
            
            // remove partners choose
            setPartnersChooseByCustomer(false);
        }else{
            // show error and reload order
            setShowMainErrorPopup(true);
        }

        // set loading status
        setIsLoading(false);
    }

    return (
        <div className={styles.customerChoosePartner + " rounded"}>
            <div className={styles.customerChoosePartnerLabel}>{t('The client has chosen a partner')}</div>
            <Link to={getLink('partner.details', { id: state.partnersChooseByCustomer.id, })} className={styles.customerChoosePartnerName}>
                {state.partnersChooseByCustomer.name}
            </Link>
            <div className="removePartner animate" onClick={HandleRemovePartner}>
                <SvgIcon id="icon-remove-white" className="svg-logo"/>
            </div>
        </div>
    );
}

export default ChoosePartner;