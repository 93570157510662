import { useNavigate } from "react-router-dom";
import { useAuthContext } from "contexts/AuthContext";
import { getSessionToken } from "utils/session";
import { createQueryStringWithURLSearchParams } from 'utils/createQueryString';
import { Routes } from 'constants/routes';

export default function useAjaxCall() {
    const {
        authState,
        actions: { clearAuthData },
    } = useAuthContext();

    const navigate = useNavigate();

    const makeAjaxCall = async(url, params, admin = false) => {
        if(admin === true){
            if(authState.isLogged === false){
                clearAuthData();
                navigate(Routes.LOGIN);
            }

            params = Object.assign(params, {
                token: getSessionToken('authToken'),
                location: getSessionToken('authLocation'),
                language: getSessionToken('authLanguage'),
            });
        }

        try{
            const response = await fetch(
                process.env.REACT_APP_API_URL + url,
                {
                    method: 'POST',
                    headers: {
                        Authorization: process.env.REACT_APP_API_KEY,
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: createQueryStringWithURLSearchParams(params),
                },
            );

            // parse data
            const data = await response.json();

            if(data.result === false
                && data.logout === true
                && admin === true
                && authState.isLogged){
                clearAuthData();
                navigate(Routes.LOGIN);
            }

            return data;
        }catch (e) {
            console.log(e);
        }
    }

    return { makeAjaxCall };
}