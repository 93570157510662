import styles from './BillingData.module.less';
import { useTranslation } from "react-i18next";
import {useId, useRef} from "react";
import { useOrderAddContext } from 'contexts/OrderAddContext';
import SvgIcon from "components/SvgIcon";
import LegalEntityInput from "components/OrderAdd/BillingData/LegalEntityInput";
import PostCodeInput from "components/OrderAdd/BillingData/PostCodeInput";
import LegalEntity from 'components/OrderAdd/BillingData/LegalEntity';

const BillingData = ({ authState, companyNameRef, taxNumberRef, companyAddressRef, cityRef, postCodeRef, customerDataRef, ...props }) => {
    const { t } = useTranslation();

    const {
        orderAddState,
        actions: {
            setUseCustomerDataForInvoice,
            setNeedVatInvoice,
            updatePriceTime,
            setInvoiceData,
            setError,
            setCustomerDataForInvoice,
        },
    } = useOrderAddContext();

    const companyNameId = useId();
    const taxNumberId = useId();
    const addressId = useId();
    const cityId = useId();
    const postCodeId = useId();
    const customerDataId = useId();

    const handleCustomerDataForInvoice = () => {
        setUseCustomerDataForInvoice(!orderAddState.useCustomerDataForInvoice);
    }

    const handleNeedVatInvoice = () => {
        setNeedVatInvoice(!orderAddState.needVatInvoice);
        updatePriceTime(['price']);
    }

    const handleChangeCustomerDataForInvoice = (event) => {
        setCustomerDataForInvoice(event.target.value);
    }

    const handleBlur = (event) => {
        console.log(event.target.value);

        if(companyNameRef.current.value){
            setInvoiceData({
                companyName: companyNameRef.current.value,
            });
        }

        if(taxNumberRef.current.value){
            setInvoiceData({
                taxNumber: taxNumberRef.current.value,
            });
        }

        if(companyAddressRef.current.value){
            setInvoiceData({
                address: companyAddressRef.current.value,
            });
        }

        if(cityRef.current.value){
            setInvoiceData({
                city: cityRef.current.value,
            });
        }
    }

    const handleChange = (event) => {
        if(companyNameRef.current.value){
            setError({
                vatInvoiceCompanyName: false,
            });
        }

        if(taxNumberRef.current.value){
            setError({
                vatInvoiceTaxNumber: false,
            });
        }

        if(companyAddressRef.current.value){
            setError({
                vatInvoiceAddress: false,
            });
        }

        if(cityRef.current.value){
            setError({
                vatInvoiceCity: false,
            });
        }
    }

    const handlePostcodeChange = (event) => {
        if(event.target.value){
            setInvoiceData({
                postcode: event.target.value,
            });

            if(!orderAddState.errors.vatInvoicePostcode){
                setError({
                    vatInvoicePostcode: false,
                });
            }
        }
    }

    return (
        <div className={`row ${styles.rowBillingData}`} >
            <div className="row-label">
                <span>{t('Billing data after ordering')}</span>
            </div>
            <div className="row-content">
                <div className="row-customer-data-for-invoice">
                    <div className={`row-check another-person ${orderAddState.useCustomerDataForInvoice && ( 'active') }`}
                         onClick={handleCustomerDataForInvoice}>
                        <div className="square animate rounded">
                            <SvgIcon id="icon-check-square-white" className="svg"/>
                        </div>
                        <span>{t('Use customer data from the order for invoicing')}</span>
                    </div>
                    {!orderAddState.useCustomerDataForInvoice && (
                        <div className="row-content-additional-data">
                            <label htmlFor={customerDataId} className="label">{t('Enter the customer data to be displayed on the account ( name, address, contact details )')}</label>
                            <textarea
                                className="rounded animate"
                                id={customerDataId}
                                onBlur={handleChangeCustomerDataForInvoice}
                            />
                        </div>
                    )}
                </div>
                <div className="row-legal-entity-data-for-invoice">
                    <div className={`row-check another-person ${orderAddState.needVatInvoice && ( 'active') }`}
                         onClick={handleNeedVatInvoice}>
                        <div className="square animate rounded">
                            <SvgIcon id="icon-check-square-white" className="svg"/>
                        </div>
                        <span>{t('Need a VAT invoice for a legal entity')}</span>
                    </div>
                    { orderAddState.needVatInvoice && (
                        <>
                            {orderAddState.customer.legalEntities.length > 0 && (
                                <div className="chooseLegalEntity">
                                    <div className="chooseLegalEntity-label">{t('Saved legal entities of this user')}</div>
                                    <div className="chooseLegalEntity-list">
                                        {orderAddState.customer.legalEntities.map((legalEntity, i) => (
                                            <LegalEntity
                                                legalEntity={legalEntity}
                                                key={i}
                                                setError={setError}
                                                setInvoiceData={setInvoiceData}
                                                active={legalEntity.id === orderAddState.invoice.id}
                                            />
                                        ))}
                                    </div>
                                </div>
                            )}
                            <div className="row-content-additional-data">
                                <LegalEntityInput
                                    ref={companyNameRef}
                                    id={companyNameId}
                                    type="text"
                                    className="legalEntityName"
                                    label={t('Company name')}
                                    //onBlur={handleBlur}
                                    value={orderAddState.invoice.companyName}
                                    onChange={handleChange}
                                    error={orderAddState.errors.vatInvoiceCompanyName}
                                    errorText={t('The field can\'t be empty')}
                                />
                                <LegalEntityInput
                                    ref={taxNumberRef}
                                    id={taxNumberId}
                                    type="text"
                                    className="taxNumber"
                                    label={t('Taxpayer number')}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={orderAddState.invoice.taxNumber}
                                    error={orderAddState.errors.vatInvoiceTaxNumber}
                                    errorText={t('The field can\'t be empty')}
                                />
                                <LegalEntityInput
                                    ref={companyAddressRef}
                                    id={addressId}
                                    type="text"
                                    className="address"
                                    label={t('Address')}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={orderAddState.invoice.address}
                                    error={orderAddState.errors.vatInvoiceAddress}
                                    errorText={t('The field can\'t be empty')}
                                />
                                <LegalEntityInput
                                    ref={cityRef}
                                    id={cityId}
                                    type="text"
                                    className="city"
                                    label={t('City')}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={orderAddState.invoice.city}
                                    error={orderAddState.errors.vatInvoiceCity}
                                    errorText={t('The field can\'t be empty')}
                                />
                                <PostCodeInput
                                    ref={postCodeRef}
                                    id={postCodeId}
                                    type="text"
                                    className="postCode"
                                    authState={authState}
                                    label={t('Postal code')}
                                    onChange={handlePostcodeChange}
                                    value={orderAddState.invoice.postcode}
                                    error={orderAddState.errors.vatInvoicePostcode}
                                    errorText={t('The field can\'t be empty')}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default BillingData;