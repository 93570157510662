import { actionQuestionAddTypes } from 'constants/actionQuestionAddTypes';

export const initialState = {
    id: null,
    showMainError: false,
    showLoading: true,
    status: 1,
    front: 0,
    errors: [],
    question: [],
    slug: [],
    metaDescription: [],
    pageTitle: [],
    answer: [],
    idQuestionType: null,
};

export const questionAddReducer = (state, action) => {
    switch (action.type) {
        case actionQuestionAddTypes.SET_SHOW_MAIN_ERROR:
            return { ...state, showMainError: action.payload };
        case actionQuestionAddTypes.SET_STATUS:
            return { ...state, status: action.payload };
        case actionQuestionAddTypes.SET_QUESTION_TYPE:
            return { ...state, idQuestionType: action.payload };
        case actionQuestionAddTypes.SET_SHOW_LOADING:
            return { ...state, showLoading: action.payload };
        case actionQuestionAddTypes.SET_FRONT:
            return { ...state, front: action.payload };
        case actionQuestionAddTypes.SET_QUESTION_DATA:
            return {
                ...state,
                id: action.payload.id,
                front: action.payload.front,
                status: action.payload.status,
                question: action.payload.questionByLanguage,
                metaDescription: action.payload.metaDescriptionByLanguage,
                pageTitle: action.payload.pageTitleByLanguage,
                answer: action.payload.answerByLanguage,
                idQuestionType: action.payload.idQuestionType,
                slug: action.payload.slugByLanguage,
            };
        case actionQuestionAddTypes.SET_SHOW_ERRORS:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    [action.payload.language]: {
                        ...state.errors[action.payload.language],
                        [action.payload.type]: action.payload.value,
                    },
                },
            };
        case actionQuestionAddTypes.SET_QUESTION:
            return {
                ...state,
                question: {
                    ...state.question,
                    [action.payload.language]: action.payload.value,
                },
            };
        case actionQuestionAddTypes.SET_ANSWER:
            return {
                ...state,
                answer: {
                    ...state.answer,
                    [action.payload.language]: action.payload.value,
                },
            };
        case actionQuestionAddTypes.SET_PAGE_TITLE:
            return {
                ...state,
                pageTitle: {
                    ...state.pageTitle,
                    [action.payload.language]: action.payload.value,
                },
            };
        case actionQuestionAddTypes.SET_META_DESCRIPTION:
            return {
                ...state,
                metaDescription: {
                    ...state.metaDescription,
                    [action.payload.language]: action.payload.value,
                },
            };
        case actionQuestionAddTypes.SET_SLUG:
            return {
                ...state,
                slug: {
                    ...state.slug,
                    [action.payload.language]: action.payload.value,
                },
            };
    }
};