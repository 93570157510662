import styles from './Input.module.less';
import { memo, forwardRef } from 'react';
import {useTranslation} from "react-i18next";
import ErrorText from 'components/ErrorText';

const Input = memo(
    forwardRef(({ label, inputClass, required, containerClass, hideContent, errorText, showError, setShowError, maxLength, infoText, ...props }, ref) => {
        const {t} = useTranslation();

        return (
            <div className={`${containerClass ? containerClass : ''} row-input-container row-input-container-${props.id} ${showError ? 'showError' : '' }`}>
                {label && (
                    <label htmlFor={props.id} className="row-label">
                        <span className="name">{label}</span>
                        {required && ( <span className="required">*{t('Required')}</span> )}
                    </label>
                )}
                {showError && ( <ErrorText text={errorText} /> )}
                {infoText && ( <div className="row-info">{infoText}</div> )}
                {!hideContent && (
                    <div className="row-content">
                        <input
                            ref={ref}
                            maxLength={maxLength || "255"}
                            className={`${styles.input} rounded animate ${inputClass ? inputClass : ''}`}
                            {...props}
                        />
                    </div>
                )}
            </div>
        );
    })
)

export default Input;