const Time = ({ time, toggleClosedTime, setClosedTime, active, ...props }) => {
    const handleTimeClick = () => {
        setClosedTime(time + ':00');
    }

    return (
        <div onClick={handleTimeClick}
            className={`button animate rounded ${active ? 'active' : ''}`}>
            <span>{time}</span>
        </div>
    );
}

export default Time;