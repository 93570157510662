export const AjaxRoutes = {
    ORDER_GET_ADMIN: '/order/get/admin',
    ORDER_LABELS_SET: '/order/labels/set',
    ORDER_CHANNEL_SET: '/order/channel/set',
    ORDER_LABEL_REMOVE: '/order/label/remove',
    ORDER_PARTNER_CHOOSE_REMOVE: '/order/partner/choose/remove',
    ORDER_TYPE_PARTNERS_NUMBER_SET: '/order/type/partners/number/set',
    ORDER_PARTNERS_AVAILABLE: '/order/partners/available',
    ORDER_PARTNER_SET: '/order/partner/set',
    ORDER_TYPE_PARTNER_PERCENT_SET: '/order/type/partner/percent/set',
    ORDER_PARTNER_REMOVE: '/order/partner/remove',
    ORDER_PARTNER_CASH: '/order/partner/cash',
    LABELS_LIST_AVAILABLE: '/labels/list/available',
    SET_CHOOSE_BY_CUSTOMER: '/order/chooseByCustomer/set',
};