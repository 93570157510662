import styles from './Date.module.less';
import { memo, forwardRef, useEffect } from 'react';
import SvgIcon from 'components/SvgIcon';
import {useTranslation} from "react-i18next";

const Day = memo(
    forwardRef(({ day, activeServiceType, setDiscountsData, setClosedTimeData, setDiscountDate, setCloseTimeDateFormated, setDiscountDateFormated, setShowSetDiscount, setShowSetCloseTime, setCloseTimeDate, ...props }, ref) => {
        const {t} = useTranslation();

        const handleSetDiscountClick = () => {
            setShowSetDiscount(true);
            setDiscountDate(day.dateFormat);
            setDiscountDateFormated(day.date);
            setDiscountsData(day.discounts);
        }

        const handleCloseTime = () => {
            setShowSetCloseTime(true);
            setCloseTimeDate(day.dateFormat);
            setCloseTimeDateFormated(day.date);
            setClosedTimeData(day.closedTime);
        }

        return (
            <div className="day rounded">
                <div className="day-header">
                    <div className="day-header-right">
                        <div className="date">{day.dateFormat}</div>
                        {day.today && ( <div className="today">{t('Today')}</div> )}
                    </div>
                    <div onClick={handleSetDiscountClick}
                        className={`set-discount animate ${Object.keys(day.discounts).length > 0 ? 'active' : ''}`}>
                        <SvgIcon id="icon-discount" />
                    </div>
                </div>
                <div className="date-content">
                    <div className="amount-of-orders">
                        <div className="label">{t('Number of orders')}</div>
                        <div className="number">{day.numberOfOrders}</div>
                    </div>
                    <div className="partners">
                        <div className="partners-header">
                            <SvgIcon id="icon-partner" className="svg"/>
                            <div className="label">{t('Partners')}</div>
                        </div>
                        <div className="partners-items">
                            <div className="partner-item">
                                <div className="label">{t('hole day')}</div>
                                <div className="number">{day.partners.holeDay}</div>
                            </div>
                            <div className="partner-item">
                                <div className="label">{t('part of the day')}</div>
                                <div className="number">{day.partners.partDay}</div>
                            </div>
                            <div className="partner-item partner-item-need">
                                <div className="label">{t('no order')}</div>
                                <div className="number rounded">{day.partners.noOrder}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="block-time">
                    <div onClick={handleCloseTime}
                        className={`button dark-grey blue-hover mini rounded animate rouned ${Object.keys(day.closedTime).length > 0 ? 'active' : ''}`}>
                        <SvgIcon id="icon-close" className="animate"/>
                        <span>{t('Close time')}</span>
                    </div>
                </div>
            </div>
        );
    })
)

export default Day;