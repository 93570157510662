//import styles from './PaymentMethod.module.less';

const PaymentMethod = ({ method, active, setPaymentMethod, ...props }) => {
    const handleSetPaymentMethod = () => {
        if(!active){
            setPaymentMethod(method.id);
        }
    }

    return (
        <div onClick={handleSetPaymentMethod}
            className={`method animate ${active ? 'active' : ''}`}>
            {method.label}
        </div>
    );
}

export default PaymentMethod;