import styles from './Message.module.less';
import { useTranslation } from "react-i18next";
import SvgIcon from 'components/SvgIcon';
import { useOrderContext } from 'contexts/OrderContext';
import { useEffect } from "react";

const Message = ({ message, ...props }) => {
    const {t} = useTranslation();

    const {
        state,
        actions: { setMessages },
    } = useOrderContext();

    useEffect(() => {
        setTimeout(() => {
            setMessages([]);
        }, 4000);
    }, [state.messages]);

    const HandleCloseMessage = (event) => {
        setMessages([]);
    }

    return (
        <div className={`
                rounded
                ${styles.message}
                ${message.type === 'success' ? styles.messageSuccess : ''}
            `}
        >
            <div className={styles.messageClose + ' animate'} onClick={HandleCloseMessage}>
                <SvgIcon id="icon-remove" className="icon"/>
            </div>
            <SvgIcon id={message.icon} className="icon"/>
            <span>{message.text}</span>
        </div>
    );
}

export default Message;