import { createContext, useContext, useMemo, useReducer, useEffect } from 'react';
import { initialState, serviceTypeAddReducer } from './reducers/ServiceTypeAddReducer';
import useServiceTypeAddActions from 'hooks/useServiceTypeAddActions';

const ServiceTypeAddContext = createContext({
    state: initialState,
    actions: {},
});

const useServiceTypeAddContext = () => {
    const context = useContext(ServiceTypeAddContext);

    if (!context) {
        throw new Error(
            'ServiceTypeAddContext must be used within a ServiceTypeAddProvider.'
        );
    }

    return context;
};

const ServiceTypeAddProvider = ({ children }) => {
    const [serviceTypeAddState, dispatch] = useReducer(serviceTypeAddReducer, initialState);
    const actions = useServiceTypeAddActions(dispatch);
    const value = useMemo(() => ({ serviceTypeAddState, actions }), [serviceTypeAddState, actions]);
    console.log('🚀 ~ ServiceTypeAddProvider ~ value:', value);

    return (
        <ServiceTypeAddContext.Provider value={value}>
            {children}
        </ServiceTypeAddContext.Provider>
    );
};

export { useServiceTypeAddContext, ServiceTypeAddProvider };