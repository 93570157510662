import {useTranslation} from "react-i18next";
import {useEffect, useState} from 'react';
import SvgIcon from 'components/SvgIcon';
import { useOrderContext } from 'contexts/OrderContext';

const Discount = () => {
    const {t} = useTranslation();
    const [label, setLabel] = useState('');
    const [value, setValue] = useState('');
    const [percent, setPercent] = useState(true);

    const {
        state,
        actions: { },
    } = useOrderContext();

    useEffect(() => {
        setPercent(true);
        
        switch (state.discountType){
            case 'periodicityDiscount':
                setLabel(t('Frequency discount'));
                break;
            case 'dayCoefficient':
                setLabel(t('Daily coefficient'));
                break;
            case 'promocodeAmountDiscount':
                setLabel(t('Fixed amount promo code'));
                setPercent(false);
                break;
            case 'promocodePercentDiscount':
                setLabel(t('Promo code for a percentage'));
                break;
            case 'dateDiscount':
                setLabel(t('Date discount'));
                break;
            case 'pointsDiscount':
                setLabel(t('Internal points'));
                setPercent(false);
                break;
        }

        setValue(state.discountValue);
    }, []);
    
    return (
        <div className="price-item discount">
            <div className="label">{label}</div>
            <div className="value">-{value} {percent ? '%' : ''}</div>
        </div>
    );
}

export default Discount;