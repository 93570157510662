import { actionServiceTypeAddTypes } from 'constants/actionServiceTypeAddTypes';

export const initialState = {
    id: null,
    label: {},
    slug: {},
    description: {},
    status: 1,
    minPrice: 0,
    basePercent: 50,
    isLoading: false,
    errors: [],
    showMainError: false,
};

export const serviceTypeAddReducer = (state, action) => {
    switch (action.type) {
        case actionServiceTypeAddTypes.SET_LABEL:
            return {
                ...state,
                label: {
                    ...state.label,
                    [action.payload.language]: action.payload.value,
                },
            };
        case actionServiceTypeAddTypes.SET_SLUG:
            return {
                ...state,
                slug: {
                    ...state.slug,
                    [action.payload.language]: action.payload.value,
                },
            };
        case actionServiceTypeAddTypes.SET_DESCRIPTION:
            return {
                ...state,
                description: {
                    ...state.description,
                    [action.payload.language]: action.payload.value,
                },
            };
        case actionServiceTypeAddTypes.SET_IS_LOADING:
            return { ...state, isLoading: action.payload };
        case actionServiceTypeAddTypes.SET_MIN_PRICE:
            return { ...state, minPrice: action.payload };
        case actionServiceTypeAddTypes.SET_BASE_PERCENT:
            return { ...state, basePercent: action.payload };
        case actionServiceTypeAddTypes.SET_STATUS:
            return { ...state, status: action.payload };
        case actionServiceTypeAddTypes.SET_SHOW_MAIN_ERROR:
            return { ...state, showMainError: action.payload };
        case actionServiceTypeAddTypes.SET_SHOW_ERRORS:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    [action.payload.language]: {
                        ...state.errors[action.payload.language],
                        [action.payload.type]: action.payload.value,
                    },
                },
            };
        case actionServiceTypeAddTypes.SET_SERVICE_TYPE_DATA:
            return {
                ...state,
                id: action.payload.id,
                status: action.payload.status,
                minPrice: action.payload.minPrice,
                basePercent: action.payload.basePercent,
                label: action.payload.labelByLanguage,
                slug: action.payload.slugByLanguage,
                description: action.payload.descriptionByLanguage,
            };
    }
};