//import styles from './CustomerData.module.less';
import { useTranslation } from "react-i18next";
import { useOrderAddContext } from 'contexts/OrderAddContext';
import {useId, useState} from "react";

const Name = ({ ...props }) => {
    const {
        orderAddState,
        actions: { },
    } = useOrderAddContext();

    const { t } = useTranslation();

    const inputEmailId = useId();
    const [inputEmail, setInputEmail] = useState(props?.value ?? "");

    const HandleEmailChange = () => {

    }

    return (
        <div className="item email">
            <label htmlFor={inputEmailId}>{t('Name')}</label>
            <input
                id={inputEmailId}
                className="rounded"
                onChange={HandleEmailChange}
            />
        </div>
    );
};

export default Name;