import styles from './CalendarData.module.less';
import { useTranslation } from "react-i18next";
import {useState} from 'react';
import SvgIcon from 'components/SvgIcon';
import Date from 'components/Order/SetDate/CalendarData/Date';
import Month from 'components/Order/SetDate/CalendarData/Month';

const CalendarData = ({ data, state, setIsLoading, setDate, type, setShowMainErrorPopup, setCalendarData, activeDate, makeAjaxCall, ...props }) => {
    const { t } = useTranslation();

    return (
        <>
            <div className='calendar-header'>
                <Month
                    navigateClass="prev"
                    label={data.prev.label}
                    year={data.prev.year}
                    month={data.prev.month}
                    setIsLoading={setIsLoading}
                    setCalendarData={setCalendarData}
                    setShowMainErrorPopup={setShowMainErrorPopup}
                    type={type}
                    makeAjaxCall={makeAjaxCall}
                />
                <div className="current-month">
                    <span>{data.current.label} {data.current.year}</span>
                </div>
                <Month
                    navigateClass="next"
                    label={data.next.label}
                    year={data.next.year}
                    month={data.next.month}
                    setIsLoading={setIsLoading}
                    setCalendarData={setCalendarData}
                    setShowMainErrorPopup={setShowMainErrorPopup}
                    type={type}
                    makeAjaxCall={makeAjaxCall}
                />
            </div>
            <div className="calendar-days">
                <div className="date">{t('Mon')}</div>
                <div className="date">{t('Tue')}</div>
                <div className="date">{t('Wed')}</div>
                <div className="date">{t('Thur')}</div>
                <div className="date">{t('Fri')}</div>
                <div className="date">{t('Sat')}</div>
                <div className="date">{t('Sun')}</div>
                {data.days.map((date, i) => (
                    <Date
                        date={date}
                        activeDate={activeDate}
                        setDate={setDate}
                        setShowMainErrorPopup={setShowMainErrorPopup}
                        key={i}
                    />
                ))}
            </div>
            <div className="next-month-name">
                <div className="next-month-name-triangle"></div>
                <div className="next-month-name-text">{t("It's already")} {data.next.label}</div>
            </div>
        </>
    );
};

export default CalendarData;