import styles from './PaymentStatus.module.less';
import {useTranslation} from "react-i18next";
import {useEffect, useState} from 'react';
import Status from 'components/Order/PaymentStatus/Status';

const PaymentStatus = ({ state, setIsLoading, setShowMainErrorPopup, makeAjaxCall, setOrderData, ...props }) => {
    const {t} = useTranslation();

    const setPaymentStatus = async(status) => {
        // set show loading
        setIsLoading(true);

        var ajaxResponse = await makeAjaxCall('/order/payment/status', {
            id: state.id,
            status: status,
        }, true);

        if (ajaxResponse && ajaxResponse.result) {
            // change payment method
            setOrderData(ajaxResponse.data);
        }else{
            // show main error
            setShowMainErrorPopup(true);
        }

        // set loading
        setIsLoading(false);
    }

    return (
        <div className={styles.paymentStatus}>
            <Status
                label={t('Paid')}
                active={state.paymentStatus === 1}
                value={1}
                setPaymentStatus={setPaymentStatus}
                className={"green"}
            />
            <Status
                label={t('Unpaid')}
                active={state.paymentStatus === 0}
                value={0}
                setPaymentStatus={setPaymentStatus}
                className={"red"}
            />
        </div>
    );
}

export default PaymentStatus;